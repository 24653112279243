import {
  Component,
  OnDestroy,
  Input,
  Renderer2,
  ChangeDetectionStrategy
} from '@angular/core';
import { GeocodingResult } from '@google/maps';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { RootStoreState } from '@app/root-store';
import { AcademyStoreSelectors } from '@app/root-store/academy-store';
import { ClassSearch } from '@global/models/class-search';
import { SetSearch } from '@app/root-store/academy-store/actions';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ApiService, ApiOptions } from '@app/core/services/api.service';
import { LayoutService } from '@app/core/layout/layout.service';
import { PlatformService } from '@app/core/services/platform.service';

@Component({
  selector: 'app-class-search',
  templateUrl: './class-search.component.html',
  styleUrls: ['./class-search.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.simple]': 'simple'
  }
})
export class ClassSearchComponent implements OnDestroy {
  @Input() simple: boolean;
  public query = '';
  search: ClassSearch;
  searching = false;
  constructor(
    private _router: Router,
    private _store: Store<RootStoreState.State>,
    private _api: ApiService,
    private layout: LayoutService,
    private _ps: PlatformService
  ) {
    this._store
      .pipe(
        select(AcademyStoreSelectors.selectClassSearch),
        takeUntil(componentDestroyed(this))
      )
      .subscribe(search => {
        if (search) {
          this.search = search;
          this.query = search.query;
        }
      });
  }

  getGeoLocation() {
    this.searching = true;
    const options: ApiOptions = new ApiOptions({
      endpoint: 'google-maps/geocoding',
      query: { address: this.query }
    });
    this._api
      .find(options)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(resp => {
        this.searching = false;
        this.handleHttpResultSelected(resp[0]);
        this.findClasses();
      });
  }

  public handleHttpResultSelected(result: GeocodingResult) {
    if (!result || (result && !result.geometry)) {
      return;
    }
    this.search = {
      query: this.query,
      lat: result.geometry.location.lat,
      lng: result.geometry.location.lng,
      town: null,
      postcode: null
    };
    this.query = this.search.query;
    for (const component of result.address_components) {
      const addressType = component.types[0];
      if (addressType === 'postal_code') {
        this.search.postcode = component.long_name;
      }
      if (addressType === 'postal_town') {
        this.search.town = component.long_name;
      }
    }
  }

  findClasses() {
    this._store.dispatch(new SetSearch(this.search));
    this._router.navigate(['academy', 'find-a-class'], {
      queryParams: {
        lat: this.search.lat,
        lng: this.search.lng
      }
    });
  }

  ngOnDestroy() { }
}
