<span
  class="intro"
  [innerHTML]="control.introText"
  *ngIf="control.introText"
></span>
<label [for]="control.id" class="field-label" *ngIf="control.label">
  {{ control.label }}
  <span class="asterisk" *ngIf="isRequired()">&#32;*</span>
</label>
<div class="field-wrapper" #fieldClick>
  <ng-content></ng-content>
</div>
<p class="hint" *ngIf="control.hintText">{{ control.hintText }}</p>
<a
  class="help-link"
  [routerLink]="['', { outlets: { popup: [control.helpLink.url] } }]"
  [queryParams]="control.helpLink.query"
  *ngIf="control.helpLink"
  >{{ control.helpLink.title }}</a
>
