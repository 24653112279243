import { Action } from '@ngrx/store';
import { ClassSearch } from '@global/models/class-search';

export enum ActionTypes {
  SET_SEARCH = '[Class Search] Set Search'
}

export class SetSearch implements Action {
  readonly type = ActionTypes.SET_SEARCH;
  constructor(public payload: ClassSearch) {}
}

export type Actions = SetSearch;
