import {
  Component,
  OnInit,
  Renderer2,
  HostListener,
  ElementRef,
  OnDestroy,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { mainMenu } from '@app/core/main-menu';
import { Observable, fromEvent } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  tap,
  takeUntil,
  throttleTime,
  map,
  pairwise,
  distinctUntilChanged,
  share,
  filter
} from 'rxjs/operators';
import {
  StateStoreSelectors,
  StateStoreActions
} from '@app/root-store/state-store';
import { RootStoreState } from '@app/root-store';
import { PlatformService } from '@app/core/services/platform.service';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { Router, NavigationEnd } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { LayoutService } from '../layout.service';
import { appAnimations } from '@app/animations';
import { CartStoreSelectors } from '@app/root-store/cart-store';

export enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}

export enum Direction {
  Up = 'Up',
  Down = 'Down'
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  animations: [
    appAnimations,
    trigger('toggle', [
      state(
        VisibilityState.Hidden,
        style({ opacity: 0, transform: 'translateY(-100%)' })
      ),
      state(
        VisibilityState.Visible,
        style({ opacity: 1, transform: 'translateY(0)' })
      ),
      transition('hidden=>visible', animate('500ms ease-in-out')),
      transition('visible=>hidden', animate('100ms ease-in-out'))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavComponent implements OnInit, OnDestroy {
  menus = mainMenu;
  menuOpen$: Observable<boolean>;
  home = false;
  headerInView = true;
  scrollingUp = true;
  private isVisible = true;
  init = false;
  @ViewChild('menuWrapper') menuWrapper: ElementRef;
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    if (
      this.menuWrapper &&
      this._layout.top - this.menuWrapper.nativeElement.clientHeight <= 0
    ) {
      this.headerInView = false;
    } else {
      this.headerInView = true;
    }
  }
  get toggle(): VisibilityState {
    if (this.headerInView) {
      return VisibilityState.Visible;
    }
    return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;
  }
  constructor(
    private _store: Store<RootStoreState.State>,
    private _ps: PlatformService,
    private _renderer: Renderer2,
    private _el: ElementRef,
    private _router: Router,
    private _layout: LayoutService,
    private _cdr: ChangeDetectorRef
  ) {
    this.menuOpen$ = this._store.pipe(
      select(StateStoreSelectors.selectMenuOpen),
      tap(open => {
        if (this._ps.isBrowser) {
          if (open) {
            this._renderer.addClass(
              this._ps.getNativeDocument().body,
              'modal-open'
            );
          } else if (this.init) {
            this._renderer.removeClass(
              this._ps.getNativeDocument().body,
              'modal-open'
            );
          }
          this.init = true;
        }
      }),
      share()
    );
    this._store
      .pipe(select(CartStoreSelectors.selectCartQty))
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((qty: number) => {
        this.menus.map(m => {
          if (m.id === 'basket') {
            m.badge.title = qty;
          }
          return m;
        });
        this._cdr.markForCheck();
      });
    this._router.events
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          const isHome = event.url === '/' ? true : false;
          if (isHome !== this.home) {
            this.home = isHome;
          }
        }
      });
  }

  ngOnInit() {
    const scroll$ = fromEvent(window, 'scroll').pipe(
      throttleTime(5),
      map(() => this._ps.getNativeWindow().pageYOffset),
      pairwise(),
      map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
      distinctUntilChanged(),
      share()
    );

    const scrollUp$ = scroll$.pipe(
      filter(direction => direction === Direction.Up),
      takeUntil(componentDestroyed(this))
    );

    const scrollDown = scroll$.pipe(
      filter(direction => direction === Direction.Down),
      takeUntil(componentDestroyed(this))
    );

    scrollUp$.subscribe(() => (this.isVisible = true));
    scrollDown.subscribe(() => (this.isVisible = false));
  }

  toggleMenu() {
    this._store.dispatch(new StateStoreActions.MenuToggle());
  }

  ngOnDestroy() {}
}
