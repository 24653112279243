import { Action } from '@ngrx/store';
import { User } from '@global/models/user';

export enum ActionTypes {
  SETTING_LOAD = '[Setting] Setting Load',
  SETTING_SET = '[Setting] Setting Set',
  SETTING_FAILURE = '[Auth] Setting Failure'
}

export class SettingLoad implements Action {
  readonly type = ActionTypes.SETTING_LOAD;
  constructor(public query?: any) {}
}

export class SettingSet implements Action {
  readonly type = ActionTypes.SETTING_SET;
  constructor(public payload: any) {}
}

export class SettingFailure implements Action {
  readonly type = ActionTypes.SETTING_FAILURE;
  constructor(public payload: any) {}
}

export type Actions = SettingLoad | SettingSet | SettingFailure;
