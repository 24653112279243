<app-modal #modal>
  <div class="subscription">
    <app-title-bar [center]="true" [barStyle]="{'padding-bottom': '25px', 'padding-top': '20px'}">
      <h2 class="text-center">Early Subscription</h2>
    </app-title-bar>
    <img class="icon" src="/assets/svg/star.svg">
    <p>BOOK ONTO ONE OF OUR CLASSES NOW TO GAIN ACCESS TO OUR FOUR EXCLUSIVE TASTER DAYS.</p>
    <p>The taster sessions are on 9th November, 30th November, 4th December and 13th December.<br>Spaces are limited, so
      get booking!</p>
  </div>
  <div class="subscription-footer">
    <p>YOU WILL RECEIVE AN ACCESS CODE FOR THE TASTER DAY ONCE YOUR BOOKING IS
      CONFIRMED.</p>
  </div>
</app-modal>
