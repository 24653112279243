import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ICustomControl } from '@global/models/fields';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { Student } from '@global/models/student';
import { FormService } from '@app/core/services/form.service';
import { Store, select } from '@ngrx/store';
import { RootStoreState } from '@app/root-store';
import {
  CartStoreSelectors,
  CartStoreActions,
} from '@app/root-store/cart-store';
import { ApiService, ApiOptions } from '@app/core/services/api.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { uniqBy } from 'lodash';

@Component({
  selector: 'app-british-cycling',
  templateUrl: './british-cycling.component.html',
  styleUrls: ['./british-cycling.component.scss'],
})
export class BritishCyclingComponent
  implements OnInit, AfterViewInit, OnDestroy {
  form: FormGroup;
  controls: ICustomControl[];
  controlsArray: any[] = [];
  loading: boolean;
  @ViewChild('modal') modal: ModalComponent;
  constructor(
    private _fb: FormBuilder,
    private _formService: FormService,
    private _store: Store<RootStoreState.State>,
    private _api: ApiService
  ) {
    this.form = this._fb.group({
      students: this._fb.array([]),
    });
  }

  ngOnInit() {
    this.controls = [
      {
        id: 'orderid',
        label: 'orderid',
        controlType: 'hidden',
      },
      {
        id: 'bc_consent_given',
        label:
          // eslint-disable-next-line max-len
          'By booking this you are giving us consent to share the following details with British Cycling: Your Full Name, Date of Birth, British Cycling Licence Number and British Cycling Club.',
        controlType: 'checkbox',
        validators: ['requiredTrue'],
      },
    ];
    this._formService.addControls(this.controls, this.form);
    this._store
      .pipe(select(CartStoreSelectors.selectCartBritishCycling))
      .subscribe((resp) => {
        if (resp && resp.length) {
          const students = [];
          resp.forEach((i) =>
            i.lesson_booking.students.forEach((s) => students.push(s))
          );
          uniqBy(students, '_id').forEach((s) => this.addStudent(s));
        }
      });
  }

  ngAfterViewInit() {
    if (this.modal.state && this.modal.state.order) {
      this.form.get('orderid').setValue(this.modal.state.order.orderid);
    }
  }

  private getStudentGroup(controls: ICustomControl[]) {
    return this._fb.group(this._formService.configureControls(controls));
  }

  addStudent(student: Student) {
    const control = this.form.controls['students'] as FormArray;
    const formControls: ICustomControl[] = [
      {
        id: '_id',
        label: 'ID',
        controlType: 'hidden',
        value: student && student._id ? student._id : null,
      },
      {
        id: 'name',
        label: 'Name',
        controlType: 'hidden',
        value: student && student.name ? student.name : null,
      },
      {
        id: 'bc_membership_number',
        label: 'British Cycling Membership Number',
        controlType: 'text',
        value:
          student && student.bc_membership_number
            ? student.bc_membership_number
            : null,
        validators: ['required'],
      },
      {
        id: 'bc_cycling_club',
        label: 'British Cycling Club',
        controlType: 'text',
        value:
          student && student.bc_cycling_club ? student.bc_cycling_club : null,
        validators: ['required'],
      },
    ];
    const group = this.getStudentGroup(formControls);
    control.push(group);
    this.controlsArray.push(formControls);
  }

  save() {
    this.loading = true;
    const options: ApiOptions = new ApiOptions({
      endpoint: 'student/british-cycling',
    });
    this._api
      .create(options, this.form.value)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (resp) => {
          this.loading = false;
          if (resp) {
            this._store.dispatch(new CartStoreActions.LoadOrder(resp));
            this.modal.closeModal({});
          }
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  ngOnDestroy() {}
}
