import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOGIN:
    case ActionTypes.SIGNUP:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.LOGOUT:
      return {
        ...state,
        loading: false,
        user: null,
        isAuthenticated: false
      };
    case ActionTypes.LOGIN_FAILURE:
    case ActionTypes.SIGNUP_FAILURE:
      return {
        ...state,
        loading: false
      };
    case ActionTypes.SET_URL:
      return {
        ...state,
        redirectUrl: action.payload
      };
    case ActionTypes.SET_USER:
      state = {
        ...state,
        user: action.payload,
        isAuthenticated: action.payload ? true : false,
        loading: false
      };
      return state;
    default: {
      return state;
    }
  }
}
