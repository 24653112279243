import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Product } from '@global/models/product';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { sliderConfig } from '@app/shared/product-slider-config';
@Component({
  selector: 'app-product-carousel',
  templateUrl: './product-carousel.component.html',
  styleUrls: ['./product-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCarouselComponent implements OnChanges {
  @Input() products: Product[];
  @Input() sliderConfig: SwiperConfigInterface = sliderConfig;
  @ViewChild(SwiperDirective) directiveRef?: SwiperDirective;
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.products && !changes.products.firstChange) {
      this.directiveRef.update();
    }
  }
}
