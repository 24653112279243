<div class="checkbox">
  <input
    appInput
    [formControl]="formControl"
    [formlyAttributes]="field"
    type="checkbox"
  />
  <label [attr.for]="id"
    ><span class="checkbox-label"> {{ to.label }}</span>
  </label>
</div>
