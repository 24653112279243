import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appElementHeight]',
  exportAs: 'appElementHeight'
})
export class ElementHeightDirective {
  constructor(private _el: ElementRef) {}

  get height() {
    return this._el.nativeElement.clientHeight;
  }

  calcHeight(value: number) {
    return this.height / value;
  }

  calcPercent(value: number) {
    return this.height * value;
  }
}
