import {
  Component,
  AfterContentInit,
  ContentChildren,
  QueryList,
  OnDestroy
} from '@angular/core';
import { AccordianItemComponent } from './accordian-item.component';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-accordian',
  templateUrl: './accordian.component.html'
})
export class AccordianComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(AccordianItemComponent) groups: QueryList<
    AccordianItemComponent
  >;
  constructor() {}

  ngAfterContentInit() {
    this.listen();
    this.groups.changes
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(() => {
        this.listen();
      });
  }

  listen() {
    this.groups.toArray().forEach(t => {
      // when title bar is clicked
      // (toggle is an @output event of Group)
      t.toggle.pipe(takeUntil(componentDestroyed(this))).subscribe(() => {
        // Open the group
        if (!t.opened) {
          this.openGroup(t);
        } else {
          t.opened = false;
        }
      });
    });
  }

  openGroup(group: any) {
    // close other groups
    this.groups.toArray().forEach(t => (t.opened = false));
    // open current group
    group.opened = true;
  }

  ngOnDestroy() {}
}
