import { Action } from '@ngrx/store';
import { Order } from '@global/models/order';
import { LineItem } from '@global/models/line-item';

export interface CartResponse {
  item: LineItem;
  order: Order;
  token: string;
}

export enum ActionTypes {
  ADD_BOOKING = '[Cart] Add Booking',
  UPDATE_BOOKING = '[Cart] Update Booking',
  ADD_ONE_TO_ONE_BOOKING = '[Cart] Add One To One Booking',
  UPDATE_ONE_TO_ONE_BOOKING = '[Cart] Update One To One Booking',
  ADD_MEMBERSHIP = '[Cart] Add Membership',
  UPDATE_MEMBERSHIP = '[Cart] Update Membership',
  ADD_ITEM = '[Cart] Add Item',
  ADD_TO_CART = '[Cart] Add To Cart',
  UPDATE_ITEM = '[Cart] Update Item',
  UPDATE_QTY = '[Cart] Update Qty',
  UPDATE_CART = '[Cart] Update Cart',
  REMOVE_ITEM = '[Cart] Remove Item',
  REMOVE_FROM_CART = '[Cart] Remove From Cart',
  LOAD_CART = '[Cart] Load Cart',
  COMPLETE_CART = '[Cart] Complete Cart',
  CLEAR_CART = '[Cart] Clear Cart',
  LOAD_ORDER = '[Cart] Load Order',
  SET_COUNTRIES = '[Cart] Set Countries',
  ERROR_CART = '[Cart] Error Cart',
}

export class ErrorCart implements Action {
  readonly type = ActionTypes.ERROR_CART;
  constructor(public payload: { error: string }) { }
}

export class SetCountries implements Action {
  readonly type = ActionTypes.SET_COUNTRIES;
  constructor(public payload: any[]) { }
}

export class AddBooking implements Action {
  readonly type = ActionTypes.ADD_BOOKING;
  constructor(public payload: any) { }
}

export class UpdateBooking implements Action {
  readonly type = ActionTypes.UPDATE_BOOKING;
  constructor(public id: string, public payload: any) { }
}

export class AddOneToOneBooking implements Action {
  readonly type = ActionTypes.ADD_ONE_TO_ONE_BOOKING;
  constructor(public payload: any) { }
}

export class UpdateOneToOneBooking implements Action {
  readonly type = ActionTypes.UPDATE_ONE_TO_ONE_BOOKING;
  constructor(public id: string, public payload: any) { }
}

export class AddMembership implements Action {
  readonly type = ActionTypes.ADD_MEMBERSHIP;
  constructor(public payload: any) { }
}

export class UpdateMembership implements Action {
  readonly type = ActionTypes.UPDATE_MEMBERSHIP;
  constructor(public id: string, public payload: any) { }
}

export class AddItem implements Action {
  readonly type = ActionTypes.ADD_ITEM;
  constructor(public payload: any) { }
}

export class AddToCart implements Action {
  readonly type = ActionTypes.ADD_TO_CART;
  constructor(public payload: Order) { }
}

export class UpdateItem implements Action {
  readonly type = ActionTypes.UPDATE_ITEM;
  constructor(public id: string, public payload: any) { }
}
export class UpdateQty implements Action {
  readonly type = ActionTypes.UPDATE_QTY;
  constructor(public id: string, public payload: number) { }
}

export class UpdateCart implements Action {
  readonly type = ActionTypes.UPDATE_CART;
  constructor(public payload: Order) { }
}

export class RemoveItem implements Action {
  readonly type = ActionTypes.REMOVE_ITEM;
  constructor(public payload: LineItem) { }
}

export class RemoveFromCart implements Action {
  readonly type = ActionTypes.REMOVE_FROM_CART;
  constructor(public payload: Order) { }
}

export class LoadCart implements Action {
  readonly type = ActionTypes.LOAD_CART;
  constructor() { }
}

export class CartCompleted implements Action {
  readonly type = ActionTypes.COMPLETE_CART;
  constructor() { }
}

export class ClearCart implements Action {
  readonly type = ActionTypes.CLEAR_CART;
}

export class LoadOrder implements Action {
  readonly type = ActionTypes.LOAD_ORDER;
  constructor(public payload: Order) { }
}

export type Actions =
  | SetCountries
  | AddBooking
  | UpdateBooking
  | AddMembership
  | UpdateMembership
  | AddItem
  | AddToCart
  | UpdateItem
  | UpdateQty
  | UpdateCart
  | RemoveItem
  | RemoveFromCart
  | LoadCart
  | CartCompleted
  | ClearCart
  | LoadOrder
  | ErrorCart;
