<app-header-content>
  <h1 class="text-center white"><span class="large">Recruitment</span></h1>
</app-header-content>
<div class="page">
  <div class="container">
    <app-title-bar [center]="true">
      <h2 class="text-center">Become a Clancy Briggs coach</h2>
    </app-title-bar>
  </div>
  <div class="form-block">
    <div class="container">
      <app-title-bar [center]="true">
        <h2 class="text-center h1 large">We are only as good as our team</h2>
      </app-title-bar>
      <p class="text-center">Are you a qualified coach? Do you share our vision for how we move the next generation of
        kids? Let us know and we'll get in touch to chat more about whether you could be one of our Clancy Briggs
        coaches?</p>
      <app-contact-form [location]="true" type="recruitment" [center]="true"></app-contact-form>
    </div>
  </div>
</div>
