import { trigger, state, style, transition, animate, group, query } from '@angular/animations';
import { Component } from '@angular/core';

@Component({
  selector: 'app-image-slide-up-text, [appImageSlideUpText]',
  templateUrl: './image-slide-up-text.component.html',
  styleUrls: ['./image-slide-up-text.component.scss'],
  animations: [
    trigger('shiftUp', [
      state(
        'trigger',
        style({
          'margin-top': '-{{pixelsEnd}}px',
        }),
        { params: { pixelsStart: '0', pixelsEnd: '0' } }
      ),
      state(
        '*',
        style({
          'margin-top': '-{{pixelsStart}}px',
        }),
        { params: { pixelsStart: '0', pixelsEnd: '0' } }
      ),
      transition('* => trigger', animate('0.4s 0.1s ease-in')),
      transition('trigger => *', animate('0.6s 0.2s ease-out')),
      transition('* => trigger', [
        group([
          query('.trigger-text', [
            style({
              opacity: 0.3,
            }),
            animate(
              '0.8s 0.2s ease-in-out',
              style({
                opacity: 1,
              })
            ),
          ]),
        ]),
      ]),
    ]),
  ],
})
export class ImageSlideUpTextComponent {
  state: string;
}
