import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ICustomControl } from '@global/models/fields';
import { FormService } from '@app/core/services/form.service';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@app/core/services/api.service';
import { ApiOptions } from '../../services/api.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-mailchimp',
  templateUrl: './mailchimp.component.html',
  styleUrls: ['./mailchimp.component.scss'],
})
export class MailchimpComponent implements OnInit, OnDestroy {
  mailchimpForm: FormGroup;
  controls: ICustomControl[];
  loading$ = new Subject<boolean>();
  success = false;
  constructor(
    private _formService: FormService,
    private _fb: FormBuilder,
    private _api: ApiService
  ) {
    this.mailchimpForm = this._fb.group({});
  }

  ngOnInit() {
    this.controls = [
      {
        id: 'full_name',
        label: 'Full Name',
        controlType: 'text',
        validators: ['required'],
        half_width: true,
      },
      {
        id: 'email',
        label: 'Email',
        controlType: 'text',
        type: 'email',
        validators: ['required', 'email'],
        half_width: true,
      },
    ];
    this._formService.addControls(this.controls, this.mailchimpForm);
  }

  submit(values: any) {
    this.loading$.next(true);
    const options: ApiOptions = new ApiOptions({
      endpoint: 'mailchimp/subscribe',
    });
    this._api
      .create(options, {
        email: values.email,
        merge_fields: { FULLNAME: values.full_name },
      })
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(() => {
        this.mailchimpForm.reset();
        this.success = true;

        setTimeout(() => {
          this.success = false;
        }, 5000);

        this.loading$.next(false);
      });
  }

  ngOnDestroy() { }
}
