import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JWTInterceptor } from '@app/core/services/jwt-interceptor';
import { environment } from '@env/environment';
import { NgxStripeModule } from 'ngx-stripe';
import { Angulartics2Module } from 'angulartics2';
import { CookieModule, CookieService } from '@gorniv/ngx-universal';
import { RootStoreModule } from './root-store/root-store.module';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import {
  BreadcrumbsModule,
  BreadcrumbsConfig,
  Breadcrumb,
} from '@exalif/ngx-breadcrumbs';
import { NgxCaptchaModule } from 'ngx-captcha';
import localeGB from '@angular/common/locales/en-GB';
import { PagesModule } from './pages/pages.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
registerLocaleData(localeGB);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RootStoreModule,
    CoreModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-full-width',
      preventDuplicates: true,
      maxOpened: 2,
    }),
    NgxStripeModule.forRoot(environment.stripe_key),
    Angulartics2Module.forRoot({
      developerMode: false,
    }),
    CookieModule.forRoot({
      domain: environment.cookie,
      path: '/',
      secure: true,
    }),
    AppRoutingModule,
    BreadcrumbsModule.forRoot(),
    NgxCaptchaModule,
    PagesModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(),
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'en-GB' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(breadcrumbsConfig: BreadcrumbsConfig) {
    breadcrumbsConfig.postProcess = (breadcrumbs): Breadcrumb[] => {
      // Ensure that the first breadcrumb always points to home
      let processedBreadcrumbs = breadcrumbs;

      if (breadcrumbs.length && breadcrumbs[0].text !== 'Home') {
        processedBreadcrumbs = [
          {
            text: 'Home',
            path: '',
          },
        ].concat(breadcrumbs);
      }

      return processedBreadcrumbs;
    };
  }
}
