import {
  Component,
  ViewChild,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { Timeslot } from '@global/models/timeslot';
import { Lesson } from '@global/models/lesson';
import { Store, select } from '@ngrx/store';
import { RootStoreState } from '@app/root-store';
import { User } from '@global/models/user';
import { AuthStoreSelectors } from '@app/root-store/auth-store';
import { tap, takeUntil } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ICustomControl } from '@global/models/fields';
import { FormService } from '@app/core/services/form.service';
import { ApiService, ApiOptions } from '@app/core/services/api.service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-waiting-list',
  templateUrl: './waiting-list.component.html',
  styleUrls: ['./waiting-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaitingListComponent implements AfterViewInit, OnDestroy {
  @ViewChild(ModalComponent) modal: ModalComponent;
  lesson: Lesson;
  timeslot: Timeslot;
  location: string;
  waitingForm: FormGroup;
  controls: ICustomControl[];
  saving = false;
  constructor(
    private _cdr: ChangeDetectorRef,
    private _store: Store<RootStoreState.State>,
    private _formService: FormService,
    private _api: ApiService,
    private _fb: FormBuilder,
    private _toastr: ToastrService
  ) {
    this.waitingForm = this._fb.group({});
  }

  ngAfterViewInit() {
    if (this.modal.state && this.modal.state.timeslot) {
      this.timeslot = this.modal.state.timeslot;
    }
    if (this.modal.state && this.modal.state.lesson) {
      this.lesson = this.modal.state.lesson;
    }
    if (this.modal.state && this.modal.state.location) {
      this.location = this.modal.state.location;
    }
    this._store
      .pipe(
        select(AuthStoreSelectors.selectUser),
        tap((user: User) => {
          if (user) {
            this.createForm(user);
          }
        }),
        takeUntil(componentDestroyed(this))
      )
      .subscribe();
    this._cdr.detectChanges();
  }

  createForm(user: User) {
    this.controls = [
      {
        id: 'student_name',
        label: 'Child\'s name',
        controlType: 'text',
        type: 'text',
        validators: ['required'],
        value: null,
      },
      {
        id: 'email',
        label: 'Email',
        controlType: 'text',
        type: 'email',
        validators: ['required', 'email'],
        value: user ? user.email : null,
      },
      {
        id: 'phone',
        label: 'Phone',
        controlType: 'text',
        type: 'tel',
        validators: ['required'],
        value: user && user.phone ? user.phone : null,
      },
    ];
    this._formService.addControls(this.controls, this.waitingForm);
    this._cdr.detectChanges();
  }

  addToList(values: any) {
    this.saving = true;
    if (this.timeslot) {
      values.timeslot = this.timeslot._id;
    }
    if (this.lesson) {
      values.lesson = this.lesson._id;
    }
    const options: ApiOptions = new ApiOptions({
      endpoint: 'waiting-list',
    });
    this._api
      .create(options, values)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (resp) => {
          this.saving = false;
          this._toastr.success(
            'You will be notified when a space becomes available',
            'Added to waiting list',
            {
              timeOut: 5000,
            }
          );
          this.modal.closeModal({});
        },
        (err) => {
          this.saving = false;
        }
      );
  }

  ngOnDestroy() { }
}
