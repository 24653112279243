<div class="intro" [innerHTML]="to.introText" *ngIf="to.introText"></div>
<div class="multi-checkbox">
  <div
    class="checkbox"
    *ngFor="
      let option of to.options | formlySelectOptions: field | async;
      let i = index
    "
  >
    <input
      appInput
      [id]="id + '_' + i"
      [value]="option.value"
      [checked]="isChecked(option)"
      [formlyAttributes]="field"
      (change)="onChange(option.value, $event.target.checked)"
      type="checkbox"
    />
    <label [for]="id + '_' + i"
      ><span class="checkbox-label"> {{ option.label }}</span>
    </label>
  </div>
</div>
