<app-modal #modal>
  <div class="reset">
    <app-title-bar [center]="true" [barStyle]="{'padding-bottom': '25px', 'padding-top': '20px'}">
      <h2 class="text-center">Accelerate</h2>
    </app-title-bar>
    <form [formGroup]="form" appFirstInvalid>
      <app-form-group class="w-100-p" [form]="form" [controls]="controls"></app-form-group>
      <button appButton [loading]="loading" type="submit" class="btn btn-light-blue center" [disabled]="!form.valid"
        (click)="save()">Submit</button>
    </form>
  </div>
</app-modal>
