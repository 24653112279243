import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from '@app/core/services/loading.service';
import { PlatformService } from '@app/core/services/platform.service';
import { Observable, throwError, of, concat } from 'rxjs';
import { catchError, retryWhen, delay, take, mergeMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from '@gorniv/ngx-universal';
import { environment } from '@env/environment';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
  constructor(
    private _cookieService: CookieService,
    private _toastr: ToastrService,
    private _loading: LoadingService,
    private _injector: Injector,
    private _ps: PlatformService
  ) {}

  public get router(): Router {
    return this._injector.get(Router);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const matches = req.url.match(
      /^https?\:\/\/(?!(?:www\.)?(?:cloudinary\.com))([^\/:?#]+)(?:[\/:?#]|$)/i
    );
    const domain = matches && matches[1];
    if (
      domain !== 'cdn.clancybriggs.co.uk' &&
      domain !== 'image.clancybriggs.co.uk' &&
      domain !== 'api.instagram.com'
    ) {
      req = req.clone({
        withCredentials: true,
      });

      if (req.headers.has(InterceptorSkipHeader)) {
        const headers = req.headers.delete(InterceptorSkipHeader);
        return next.handle(req.clone({ headers }));
      }

      const token: string = this._cookieService.get(environment.cookie_key);

      if (token) {
        req = req.clone({
          setHeaders: { Authorization: token },
        });
      }

      const cart_token = this._cookieService.get('cart-token');
      if (cart_token) {
        req = req.clone({
          setHeaders: { 'Cart-Token': cart_token },
        });
      }
    }
    return next.handle(req).pipe(
      retryWhen((err) => {
        return err.pipe(
          mergeMap((error: any) => {
            if (error.status === 0) {
              return of(error).pipe(delay(1000));
            }
            return throwError(error);
          }),
          take(5),
          (o) => concat(o, throwError('Request failed after 5 retries.'))
        );
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse && this._ps.isBrowser) {
          let showMessage = true;

          if (err.status === 0) {
            showMessage = false;
          }

          if (err.status === 404) {
            this.router.navigate(['/404']);
            showMessage = false;
          }
          if (err.status === 403 || err.status === 405) {
            this.router.navigate(['/404']);
            showMessage = false;
          }

          if (err.status === 401) {
            if (this.router.url.includes('(popup:login)')) {
              showMessage = true;
            } else {
              showMessage = false;
            }
          }

          const error = err.error;

          if (
            err.status === 400 &&
            error.data &&
            error.data.reason &&
            error.data.reason === 'cart_expired'
          ) {
            showMessage = false;
          }

          if (showMessage) {
            if (err.status === 402) {
              this._toastr.error(
                error ? error.message : err.message,
                'Payment Failed'
              );
            } else {
              this._toastr.error(
                error && error.message ? error.message : err.message
              );
            }
          }
        }
        this._loading.setLoading(false);

        return throwError(err);
      })
    );
  }
}
