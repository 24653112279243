<div *ngFor="let field of field.fieldGroup; let i = index">
  <formly-field class="col" [field]="field"></formly-field>
  <div>
    <button class="btn btn-red" type="button" (click)="remove(i)">
      Remove
    </button>
  </div>
</div>
<div style="margin: 30px 0;">
  <button class="btn" type="button" (click)="add()">
    {{ to.addText }}
  </button>
</div>
