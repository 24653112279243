import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.MENU_TOGGLE:
      state = {
        ...state,
        menuOpen: !state.menuOpen
      };
      return state;
    case ActionTypes.MENU_OPEN:
      state = {
        ...state,
        menuOpen: action.open
      };
      return state;
    default: {
      return state;
    }
  }
}
