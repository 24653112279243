import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-filter-expand',
  templateUrl: './filter-expand.component.html',
  styleUrls: ['./filter-expand.component.scss'],
  exportAs: 'filter',
})
export class FilterExpandComponent {
  searchInput: string;
  @Input() title: string;
  @Input() hideTitle: string;
  @Input() hideFilter: string;
  @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
  opened = false;
  constructor() {}

  showFilter() {
    this.opened = !this.opened;
  }

  searchFilter() {
    this.searchChange.emit(this.searchInput);
  }
}
