import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { RootStoreState } from '@app/root-store';
import { Store, select } from '@ngrx/store';
import {
  AuthStoreSelectors,
  AuthStoreActions,
} from '@app/root-store/auth-store';
import { switchMap, take, map } from 'rxjs/operators';
import { ApiService, ApiOptions } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  isAuthed$: Observable<boolean>;
  constructor(
    private _store: Store<RootStoreState.State>,
    private _api: ApiService,
    private _router: Router
  ) {
    this.isAuthed$ = this._store.pipe(
      select(AuthStoreSelectors.selectIsAuthed)
    );
  }

  checkLogin(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const options: ApiOptions = new ApiOptions({
      endpoint: 'me',
      loader: false,
      skip: false,
    });
    return this._api.find(options).pipe(
      map((authed) => {
        if (!authed && next.data && next.data.returnUrl) {
          this._router.navigate([next.data.returnUrl]);
        } else if (!authed) {
          this._router.navigate(['/home']).then(() => {
            this._router.navigate(['', { outlets: { popup: ['login'] } }], {
              queryParamsHandling: 'merge',
            });
          });

          this._store.dispatch(new AuthStoreActions.SetUser(authed));
          this._store.dispatch(
            new AuthStoreActions.SetUrl(
              next.data && next.data.redirectUrl
                ? next.data.redirectUrl
                : state.url
            )
          );
        }
        return authed ? true : false;
      }),
      take(1)
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkLogin(next, state);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkLogin(next, state);
  }
}
