<ng-container *ngIf="simple; else fullSearch">
  <div class="simple-search">
    <div class="search-box">
      <input type="text" [value]="query" [(ngModel)]="query" placeholder="Enter Postcode" appSearchMode>
    </div>
    <button class="btn btn-red btn-icon" (click)="getGeoLocation()" [disabled]="!query" appButton
      [loading]="searching"><svg class="icon">
        <use xlink:href="/assets/img/sprite.svg?v1.7#search-icon"></use>
      </svg></button>
  </div>
</ng-container>
<ng-template #fullSearch>
  <div class="full-search">
    <div class="container">
      <div class="search-wrapper">
        <h4>Find a class</h4>
        <div class="search-form">
          <div class="search-box">
            <input type="text" [value]="query" [(ngModel)]="query" placeholder="Enter Postcode">
          </div>
          <button class="btn btn-dark-blue btn-icon" (click)="getGeoLocation()" [disabled]="!query" appButton
            [loading]="searching"><svg class="icon">
              <use xlink:href="/assets/img/sprite.svg?v1.7#search-icon"></use>
            </svg></button>
        </div>
      </div>
    </div>
  </div>
</ng-template>