import { Component, OnInit } from '@angular/core';
import { LayoutService } from '@app/core/layout/layout.service';

@Component({
  selector: 'app-franchise',
  templateUrl: './franchise.component.html',
  styleUrls: ['./franchise.component.scss']
})
export class FranchiseComponent implements OnInit {
  constructor(private _layoutService: LayoutService) {}

  ngOnInit() {
    this._layoutService.backgroundImage$.next(
      'img/page/CBCA_FranchiseHeader_1920x900.jpg'
    );
    this._layoutService.headerClass$.next('hero');
  }
}
