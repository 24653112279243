<ng-container *ngFor="let step of steps; let i = index; let last = last">
  <div [id]="_getStepLabelId(i)" class="step-header" (click)="onClick(i)">
    <div class="step-count">{{ i + 1 }}</div>
    <h4>
      <ng-container [ngTemplateOutlet]="step.stepLabel.template"></ng-container>
    </h4>
  </div>
  <div class="step" [class.last]="last">
    <div class="step-progress"></div>
    <div
      class="step-content"
      [style.display]="selectedIndex === i ? 'block' : 'none'"
    >
      <ng-container [ngTemplateOutlet]="step.content"></ng-container>
    </div>
  </div>
</ng-container>
