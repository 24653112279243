import { Action } from '@ngrx/store';
import { User } from '@global/models/user';

export enum ActionTypes {
  LOGIN = '[Auth] Login',
  LOGOUT = '[Auth] Logout',
  LOGIN_SUCCESS = '[Auth] Login Success',
  LOGIN_FAILURE = '[Auth] Login Failure',
  SIGNUP = '[Auth] Signup',
  SIGNUP_SUCCESS = '[Auth] Signup Success',
  SIGNUP_FAILURE = '[Auth] Signup Failure',
  LOAD_USER = '[Auth] Load User',
  SET_USER = '[Auth] Set User',
  SET_URL = '[Auth] Set Url',
  USER_FAILURE = '[Auth] User Failure'
}

export class LogIn implements Action {
  readonly type = ActionTypes.LOGIN;
  constructor(public payload: any) {}
}

export class LogOut implements Action {
  readonly type = ActionTypes.LOGOUT;
  constructor() {}
}

export class LogInSuccess implements Action {
  readonly type = ActionTypes.LOGIN_SUCCESS;
  constructor(public payload: any) {}
}

export class LogInFailure implements Action {
  readonly type = ActionTypes.LOGIN_FAILURE;
  constructor(public payload: any) {}
}

export class SignUp implements Action {
  readonly type = ActionTypes.SIGNUP;
  constructor(public payload: any) {}
}

export class SignUpSuccess implements Action {
  readonly type = ActionTypes.SIGNUP_SUCCESS;
  constructor(public payload: any) {}
}

export class SignUpFailure implements Action {
  readonly type = ActionTypes.SIGNUP_FAILURE;
  constructor(public payload: any) {}
}

export class LoadUser implements Action {
  readonly type = ActionTypes.LOAD_USER;
  constructor() {}
}

export class SetUser implements Action {
  readonly type = ActionTypes.SET_USER;
  constructor(public payload: User) {}
}

export class SetUrl implements Action {
  readonly type = ActionTypes.SET_URL;
  constructor(public payload: string) {}
}

export class UserFailure implements Action {
  readonly type = ActionTypes.USER_FAILURE;
  constructor(public payload: any) {}
}

export type Actions =
  | LogIn
  | LogOut
  | LogInSuccess
  | LogInFailure
  | SignUp
  | SignUpSuccess
  | SignUpFailure
  | LoadUser
  | SetUser
  | SetUrl
  | UserFailure;
