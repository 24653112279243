<span class="trigger" #slideUpTrigger></span>
<ng-content select="[image]"></ng-content>
<div class="text-box" appInView [trigger]="slideUpTrigger" (appear)="state = 'trigger'" appElementHeight
  #elHeight="appElementHeight" [@shiftUp]="{
      value: state,
      params: {
        pixelsStart: elHeight.calcHeight(9),
        pixelsEnd: elHeight.calcHeight(4)
      }
    }" [style.margin-top.px]="-elHeight.calcHeight(9)">
  <ng-content select="[text]"></ng-content>
</div>