import { Directive, HostListener } from '@angular/core';
import { CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import { PlatformService } from '@app/core/services/platform.service';

@Directive({
  selector: '[appStepperScroll]',
})
export class StepperScrollDirective {
  constructor(private stepper: CdkStepper, private ps: PlatformService) {}

  @HostListener('selectionChange', ['$event'])
  selectionChanged(selection: StepperSelectionEvent) {
    const document = this.ps.getNativeDocument();
    const window = this.ps.getNativeWindow();
    const yOffset = -250;
    const stepId = this.stepper._getStepLabelId(selection.selectedIndex - 1);
    const stepElement = document.getElementById(stepId);
    if (stepElement) {
      setTimeout(() => {
        const y =
          stepElement.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;
        window.scrollTo({ top: y });
      }, 250);
    }
  }
}
