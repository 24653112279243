import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Page } from '@global/models/page';
import { LayoutService } from '@app/core/layout/layout.service';
import { ActivatedRoute, Data } from '@angular/router';

@Component({
  selector: 'app-page-view',
  templateUrl: './page-view.component.html',
  styleUrls: ['./page-view.component.scss'],
})
export class PageViewComponent implements OnInit {
  page$: Observable<Page>;
  constructor(
    private _layoutService: LayoutService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this._layoutService.backgroundVideo$.next(null);
    this._layoutService.backgroundImage$.next(null);
    this._layoutService.headerClass$.next('slim');
    this.page$ = this._route.data.pipe(map((data: Data) => data.page));
  }
}
