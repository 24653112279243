import { Directive, Input, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Directive({
  selector: 'input[appInput], textarea[appInput], select[appInput]',
  exportAs: 'appInput',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(blur)': '_focusChanged(false)',
    '(focus)': '_focusChanged(true)'
  }
})
export class InputDirective {
  focused = false;
  readonly stateChanges: Subject<void> = new Subject<void>();
  /** Whether the element is readonly. */
  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: boolean) {
    this._readonly = coerceBooleanProperty(value);
  }
  private _readonly = false;
  constructor(private el: ElementRef) {}

  focus() {
    this.el.nativeElement.focus();
  }

  _focusChanged(isFocused: boolean) {
    if (isFocused !== this.focused && (!this.readonly || !isFocused)) {
      this.focused = isFocused;
      this.stateChanges.next();
    }
  }
}
