import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

export const sliderConfig: SwiperConfigInterface = {
  slidesPerView: 1,
  spaceBetween: 0,
  loop: true,
  navigation: true,
  preventClicks: false,
  preventClicksPropagation: false,
  watchOverflow: true,
  slideNextClass: '.swiper-button-next',
  slidePrevClass: '.swiper-button-prev',
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 1,
    },
    // when window width is >= 640px
    768: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    // when window width is >= 800px
    852: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    // when window width is >= 800px
    1200: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
  },
};
