import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService, ApiOptions } from '@app/core/services/api.service';
import { Observable, EMPTY } from 'rxjs';
import { Page } from '@global/models/page';
import { switchMap, tap } from 'rxjs/operators';
import { SeoService } from '@app/core/services/seo.service';

@Component({
  selector: 'app-page-popup',
  templateUrl: './page-popup.component.html',
  styleUrls: ['./page-popup.component.scss'],
})
export class PagePopupComponent implements OnDestroy {
  page$: Observable<Page>;
  constructor(
    private _route: ActivatedRoute,
    private _api: ApiService,
    private _seo: SeoService
  ) {
    const apiOptions: ApiOptions = new ApiOptions({
      endpoint: 'page',
    });
    this.page$ = this._route.params.pipe(
      switchMap((params) => {
        if (params && params['slug']) {
          return this._api.findOne(params['slug'], apiOptions);
        } else {
          return EMPTY;
        }
      }),
      tap((data: Page) => {
        if (data) {
          this._seo.setMetaAndLinks(data.title, data.seo);
        }
      })
    );
  }

  ngOnDestroy() {}
}
