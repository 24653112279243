import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { StripeService, Elements, Element as StripeElement } from 'ngx-stripe';
import { FormGroup } from '@angular/forms';
import { environment } from '@env/environment';

@Component({
  selector: 'app-payment-stripe',
  templateUrl: './payment-stripe.component.html',
  styleUrls: ['./payment-stripe.component.scss'],
  exportAs: 'stripe'
})
export class PaymentStripeComponent implements OnInit, OnDestroy {
  @Input() key: string;
  @Input() form: FormGroup;
  @ViewChild('card', { static: true }) cardRef: ElementRef;
  isFocused = false;
  elements: Elements;
  card: StripeElement;
  constructor(private _stripeService: StripeService) {}

  ngOnInit() {
    this._stripeService.setKey(this.key ? this.key : environment.stripe_key);
    this._stripeService
      .elements()
      .pipe(tap(resp => (this.elements = resp)))
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(resp => {
        this.setUpCard();
      });
  }

  closeKeyboard() {
    this.card.focus();
    setTimeout(() => {
      this.card.blur();
    }, 0);
  }

  setUpCard() {
    const elementStyles = {
      base: {
        fontSize: '18px',
        iconColor: '#000000',
        color: '#000000',
        lineHeight: 1
      },
      invalid: {
        color: '#a6192e',
        ':focus': {
          color: '#a6192e'
        },
        iconColor: '#a6192e'
      },
      complete: {
        color: '#19a449',
        ':focus': {
          color: '#19a449'
        },
        iconColor: '#19a449'
      }
    };
    if (!this.card) {
      this.card = this.elements.create('card', {
        hidePostalCode: true,
        iconStyle: 'solid',
        style: elementStyles
      });
      this.card.mount(this.cardRef.nativeElement);
      this.card.on('change', this.onChange.bind(this));
    }
  }

  onChange(event) {
    if (event.complete === false) {
      this.form.controls['complete'].setValue(false);
    } else if (event.complete) {
      this.form.controls['complete'].setValue(true);
    }
  }

  ngOnDestroy() {
    this.closeKeyboard();
    this.card.unmount();
  }
}
