<div class="radio">
  <div class="intro" [innerHTML]="to.introText" *ngIf="to.introText"></div>
  <ng-container
    *ngFor="
      let option of to.options | formlySelectOptions: field | async;
      let i = index
    "
  >
    <div class="radio__wrapper">
      <input
        appInput
        type="radio"
        [id]="id + '_' + i"
        [name]="field.name || id"
        [attr.value]="option.value"
        [value]="option.value"
        [formControl]="formControl"
        [formlyAttributes]="field"
      />
      <label [for]="id + '_' + i">
        {{ option.label }}
      </label>
    </div>
  </ng-container>
</div>
