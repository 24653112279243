import { Injectable } from '@angular/core';

import { Observable, of as observableOf } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
  map,
  mergeMap,
  catchError,
  startWith,
  concatMap,
  tap,
} from 'rxjs/operators';
import * as featureActions from './actions';
import { environment } from '@env/environment';
import { ApiService, ApiOptions } from '@app/core/services/api.service';
import { User } from '@global/models/user';
import { CookieService } from '@gorniv/ngx-universal';
import { Router } from '@angular/router';
import { RootStoreState } from '..';
import { CartStoreActions } from '../cart-store';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthEffects {
  private endpoint = 'authentication';
  private host: string = environment.host;

  get url() {
    return `${this.host}/${this.endpoint}`;
  }

  
  logIn$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.LogIn>(featureActions.ActionTypes.LOGIN),
    map((action) => action.payload),
    mergeMap((data) => {
      data.strategy = 'local';
      const options: ApiOptions = new ApiOptions({
        endpoint: this.endpoint,
      });
      return this.api.create(options, data).pipe(
        map((resp: any) => new featureActions.LogInSuccess(resp)),
        tap((resp) => {
          if (resp.payload.accessToken) {
            this.loggedIn(resp.payload.accessToken);
          }
          if (resp.payload.cart_token) {
            this.restoreCart(resp.payload.cart_token);
          }
        }),
        catchError((error) =>
          observableOf(new featureActions.LogInFailure({ error }))
        )
      );
    })
  ));

  
  getUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.LogInSuccess>(
      featureActions.ActionTypes.LOGIN_SUCCESS
    ),
    startWith(new featureActions.LoadUser()),
    concatMap(() => {
      const options: ApiOptions = new ApiOptions({
        endpoint: 'me',
        loader: false,
        skip: false,
      });
      return this.api.find(options).pipe(
        map((user: User) => new featureActions.SetUser(user)),
        catchError((error) =>
          observableOf(new featureActions.UserFailure({ error }))
        )
      );
    })
  ));

  
  signUp$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.SignUp>(featureActions.ActionTypes.SIGNUP),
    map((action) => action.payload),
    mergeMap((data) => {
      const logInData = {
        strategy: 'local',
        email: data.email,
        password: data.password,
      };
      const options: ApiOptions = new ApiOptions({
        endpoint: 'users',
      });
      return this.api.create(options, data).pipe(
        map((resp: any) => new featureActions.LogIn(logInData)),
        catchError((error) =>
          observableOf(new featureActions.SignUpFailure({ error }))
        )
      );
    })
  ));

  
  logOut$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.LogOut>(featureActions.ActionTypes.LOGOUT),
    map((action) => action),
    tap(() => {
      this.deleteToken();
    })
  ), {
    dispatch: false,
  });

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private _cookieService: CookieService,
    private router: Router,
    private _store: Store<RootStoreState.State>,
    private toast: ToastrService
  ) {}

  private loggedIn(token: string) {
    this._cookieService.put(environment.cookie_key, token, {
      expires: new Date(Date.now() + 365 * 86400000),
    });
  }

  private restoreCart(token: string) {
    this._cookieService.put('cart-token', token, {
      expires: new Date(Date.now() + 365 * 86400000),
    });
    this._store.dispatch(new CartStoreActions.LoadCart());
    this.toast.success('Your saved basket has been restored.', 'Cart updated');
  }

  private deleteToken() {
    this._cookieService.remove(environment.cookie_key);
    this._cookieService.remove('cart-token');
    this.router.navigate(['/']);
  }
}
