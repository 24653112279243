<div class="video-wrapper">
  <video width="100%" poster="{{options.poster}}" preload="none" [attr.controls]="showControls ? true: null"
    [attr.loop]="loop ? true: null" #videoPlayer>
    <source src="{{options.mp4}}" type="video/mp4" />
  </video>
  <button class="play-button" appDebounceClick (debounceClick)="playVideo()" *ngIf="!play">
    <svg class="icon">
      <use xlink:href="/assets/img/sprite.svg?v1.7#video-play"></use>
    </svg>
  </button>
</div>