<app-modal
  #modal
  closeText="Accept & Close"
  (modalClose)="modalClose()"
  slug="cookie-law"
>
  <div class="cookie-law">
    <app-title-bar
      [center]="true"
      [barStyle]="{ 'padding-bottom': '25px', 'padding-top': '20px' }"
    >
      <h2 class="text-center">This site uses cookies</h2>
    </app-title-bar>
    <p class="text-center">
      By viewing this website you agree to our cookies policy. For more
      information please follow this
      <a [routerLink]="['/terms-of-use']" (click)="viewPrivacy()">link</a>.
    </p>
  </div>
</app-modal>
