import { Component, OnInit } from '@angular/core';
import { LayoutService } from '@app/core/layout/layout.service';

@Component({
  selector: 'app-recruitment',
  templateUrl: './recruitment.component.html',
  styleUrls: ['./recruitment.component.scss']
})
export class RecruitmentComponent implements OnInit {
  constructor(private _layoutService: LayoutService) {}

  ngOnInit() {
    this._layoutService.backgroundImage$.next(
      'img/page/CBCA_RecruitmentHeader_1920x900.jpg'
    );
    this._layoutService.headerClass$.next('hero');
  }
}
