import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

function updateQty(state: State) {
  let cartQty = 0;
  if (state.order.items && state.order.items.length) {
    cartQty = state.order.items.reduce((sum, option) => {
      return (sum += option.qty), sum;
    }, 0);
  }

  return { ...state, cartQty };
}

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_CART:
      return {
        ...state,
      };
    case ActionTypes.ADD_ITEM:
    case ActionTypes.UPDATE_ITEM:
    case ActionTypes.REMOVE_ITEM:
    case ActionTypes.ADD_BOOKING:
    case ActionTypes.UPDATE_BOOKING:
    case ActionTypes.ADD_MEMBERSHIP:
    case ActionTypes.UPDATE_MEMBERSHIP:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.UPDATE_QTY:
      const items = state.order.items.map((i) => {
        if (i._id === action.id) {
          i.update_qty = action.payload;
        }
        return i;
      });
      return {
        ...state,
        order: {
          ...state.order,
          items,
        },
      };
    case ActionTypes.LOAD_ORDER:
      let orderData = action.payload;
      if (action.payload.items && action.payload.items.length) {
        orderData = JSON.parse(JSON.stringify(action.payload));
        orderData.items.map((i: any) => (i.update_qty = null));
      }
      state = {
        ...state,
        order: orderData,
      };
      return updateQty(state);
    case ActionTypes.ADD_TO_CART:
    case ActionTypes.UPDATE_CART:
    case ActionTypes.REMOVE_FROM_CART:
      state = {
        ...state,
        order: action.payload,
        loading: false,
      };
      return updateQty(state);

    case ActionTypes.CLEAR_CART:
      return {
        ...state,
        cartQty: 0,
        order: null,
      };
    case ActionTypes.ERROR_CART: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case ActionTypes.SET_COUNTRIES: {
      return {
        ...state,
        countries: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
