import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService, ApiOptions } from '@app/core/services/api.service';
import { Seo } from '@global/models/seo';
import { SeoService } from './seo.service';
import { tap } from 'rxjs/operators';

interface MetaData {
  title: string;
  seo: Seo;
}

@Injectable({
  providedIn: 'root',
})
export class SeoResolver implements Resolve<MetaData> {
  constructor(private _api: ApiService, private _seo: SeoService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<MetaData> | Promise<MetaData> | MetaData {
    if (route.data.slug) {
      const apiOptions: ApiOptions = new ApiOptions({
        endpoint: 'page',
      });
      return this._api.findOne(route.data.slug, apiOptions).pipe(
        tap((data: MetaData) => {
          this._seo.setMetaAndLinks(data.title, data.seo);
        })
      );
    } else {
      this._seo.setMetaAndLinks(
        route.data.metadata.title,
        route.data.metadata.seo
      );
      return route.data.metadata;
    }
  }
}
