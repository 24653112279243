<app-modal #modal slug="register">
  <div class="register">
    <app-title-bar [center]="true">
      <h2 class="text-center">Create account</h2>
    </app-title-bar>
    <form
      [formGroup]="registerForm"
      (ngSubmit)="createAccount(registerForm.value)"
      appFirstInvalid
    >
      <app-form-group
        class="w-100-p"
        [form]="registerForm"
        [controls]="controls"
      ></app-form-group>
      <button
        appButton
        [loading]="loading$ | async"
        type="submit"
        class="btn btn-light-blue center"
      >
        Create Account
      </button>
    </form>
  </div>
</app-modal>
