<div class="student-header" *ngIf="!edit">
  <h4>Child Details</h4>
</div>
<div class="student-content" [class.edit]="edit">
  <form [formGroup]="form" *ngIf="showNewForm; else oldForm" #formElement>
    <app-custom-stepper appStepperScroll [linear]="true">
      <cdk-step
        *ngFor="let step of steps; let index = index; let last = last"
        [stepControl]="form.at(index)"
      >
        <ng-template cdkStepLabel>{{ step.label }}</ng-template>
        <formly-form
          [form]="form.at(index)"
          [model]="model"
          [fields]="step.fields"
        >
        </formly-form>
        <button [appFirstInvalidButton]="form.at(index)" type="button" class="btn center" cdkStepperNext *ngIf="!last">
          Next
        </button>
        <button
          appButton
          [loading]="saving"
          type="submit"
          class="btn center"
          (click)="addStudent()"
          *ngIf="last"
        >
          {{ student ? 'Update' : 'Save' }}
        </button>
      </cdk-step>
    </app-custom-stepper>
  </form>
  <ng-template #oldForm>
    <form [formGroup]="formOld" appFirstInvalid>
      <formly-form [form]="formOld" [model]="student" [fields]="fields">
      </formly-form>
      <button
        appButton
        [loading]="saving"
        type="submit"
        class="btn center"
        (click)="addStudent()"
      >
        {{ student ? 'Update' : 'Save' }}
      </button>
    </form>
  </ng-template>
</div>
