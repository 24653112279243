import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loading = false;
  defaultMessage = 'Loading...';
  message: string;

  setLoading(loading: boolean, message?: string) {
    this.message = message || this.defaultMessage;
    this.loading = loading;
  }
}
