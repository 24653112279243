import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SETTING_SET:
      state = {
        ...state,
        ...action.payload
      };
      return state;
    default: {
      return state;
    }
  }
}
