import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.ADD_SESSION:
      state = {
        ...state,
        sessions: [...state.sessions, action.payload]
      };
      return state;
    case ActionTypes.REMOVE_SESSION:
      state = {
        ...state,
        sessions: state.sessions.filter(s => {
          const isSlot =
            s.timeslot._id === action.timeslot &&
            s.student._id === action.student;
          if (!isSlot) {
            return s;
          }
        })
      };
      return state;
    default: {
      return state;
    }
  }
}
