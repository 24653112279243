import {
  Component,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit
} from '@angular/core';
import { LayoutService } from '../layout.service';
import { PlatformService } from '@app/core/services/platform.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements AfterViewInit {
  @ViewChild('main') main: ElementRef;
  @HostListener('window:scroll')
  onWindowScroll() {
    this.setTop();
  }
  @HostListener('window:resize')
  onResize() {
    this.setTop();
  }
  constructor(public layout: LayoutService, public ps: PlatformService) {}

  ngAfterViewInit() {
    this.setTop();
  }

  setTop() {
    if (this.main && this.ps.isBrowser) {
      const rect = this.main.nativeElement.getBoundingClientRect();
      this.layout.top = rect.top;
    }
  }
}
