<div class="container">
  <ng-container *ngIf="!success; else successMessage">
    <app-title-bar
      [center]="true"
      [barStyle]="{ 'padding-bottom': '25px', 'padding-top': '20px' }"
    >
      <h4 class="text-center">Join our newsletter</h4>
    </app-title-bar>
    <p class="text-center">
      Dont miss out! Sign up to recieve all the latest news,<br />offers and
      events happening at Clancy Briggs.
    </p>
    <form [formGroup]="mailchimpForm" (ngSubmit)="submit(mailchimpForm.value)">
      <app-form-group
        class="w-100-p"
        [form]="mailchimpForm"
        [controls]="controls"
      ></app-form-group>
      <button
        appButton
        [loading]="loading$ | async"
        type="submit"
        class="btn btn-brown center"
        [disabled]="!mailchimpForm.valid"
      >
        Sign Up
      </button>
    </form>
  </ng-container>
  <ng-template #successMessage>
    <h4 class="text-center">Success!</h4>
  </ng-template>
</div>
