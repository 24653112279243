import { Component, OnInit, Input } from '@angular/core';
import { Variation } from '@global/models/variation';

@Component({
  selector: 'app-product-price, [appProductPrice]',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss']
})
export class ProductPriceComponent implements OnInit {
  @Input() variations: Variation[];
  is_variable = false;
  constructor() {}

  get minPrice() {
    if (this.variations) {
      const prices = this.variations.map(product => product.price);
      return Math.min(...prices);
    } else {
      return 0;
    }
  }

  get maxPrice() {
    if (this.variations) {
      const prices = this.variations.map(product => product.price);
      return Math.max(...prices);
    } else {
      return 0;
    }
  }

  ngOnInit() {
    if (this.minPrice !== this.maxPrice) {
      this.is_variable = true;
    }
  }
}
