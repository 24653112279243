export const AccountMenu: any = [
  {
    url: '/account',
    title: 'Account Details'
  },
  {
    url: '/account/orders',
    title: 'Shop Orders'
  },
  {
    url: '/account/academy',
    title: 'Academy'
  },
  {
    url: '/account/coaching',
    title: 'Coaching'
  },
  {
    url: '/account/membership',
    title: 'Membership'
  },
  {
    url: '/account/payment',
    title: 'Payment'
  },
  {
    url: '/account/policy',
    title: 'Policy & Procedures'
  }
];
