import { Injectable } from '@angular/core';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { CookieLawService } from '@app/core/modules/cookie-law/cookie-law.service';
import { Variation } from '@global/models/variation';
import { Product } from '@global/models/product';
import { Booking } from '@global/models/booking';
import { LineItem } from '@global/models/line-item';
import { Order } from '@global/models/order';
import { Angulartics2Facebook } from 'angulartics2/facebook';
import { OneToOneBooking } from '@global/models/one-to-one-booking';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private brand = 'Clancy Briggs Cycling Academy';
  constructor(
    private gtm: Angulartics2GoogleTagManager,
    private fbp: Angulartics2Facebook,
    private _cookieLaw: CookieLawService
  ) { }

  get cookieConsent() {
    return this._cookieLaw.acceptCookies;
  }

  formatMembership() {
    return {
      id: 'membership',
      name: `Membership`,
      price: '25',
      brand: this.brand,
    };
  }

  formatPixelMembership() {
    return {
      id: 'membership',
      item_price: '25',
    };
  }

  formatLesson(booking: Booking) {
    const { timeslot } = booking;
    const { lesson } = timeslot;
    return {
      id: timeslot._id,
      name: `${lesson.title} at ${lesson.location.name} ${timeslot.day_of_week} ${timeslot.start_time}-${timeslot.end_time} `,
      category: lesson.type,
      price: timeslot.price.toString(),
      brand: this.brand,
    };
  }

  formatPixelLesson(booking: Booking) {
    const { timeslot } = booking;
    return {
      id: timeslot._id,
      item_price: timeslot.price.toString(),
    };
  }

  formatOneToOne(one_to_one_booking: OneToOneBooking) {
    const { one_to_one } = one_to_one_booking;
    return {
      id: one_to_one._id,
      name: `${one_to_one.title}`,
      category: 'One to One',
      price: one_to_one.price.toString(),
      brand: this.brand,
    };
  }

  formatPixelOneToOne(one_to_one_booking: OneToOneBooking) {
    const { one_to_one } = one_to_one_booking;
    return {
      id: one_to_one._id,
      item_price: one_to_one.price.toString(),
    };
  }

  formatVariation(product: Product, variation: Variation): any {
    return {
      id: variation.sku,
      name: variation.title,
      category:
        product.categories && product.categories.length
          ? product.categories[0].title
          : '',
      variant: variation.size ? variation.size.title : '',
      price: variation.price.toString(),
      brand: this.brand,
    };
  }

  formatPixelVariation(product: Product, variation: Variation): any {
    return {
      id: variation.sku,
      item_price: variation.price.toString(),
    };
  }

  productList(products: Product[], list_name) {
    if (this.cookieConsent && products && products.length) {
      products.forEach((product, index) => {
        const variations: any = [];
        if (product.variations && product.variations.length) {
          product.variations.forEach((variation) => {
            const variation_data = this.formatVariation(product, variation);
            variation_data.list = list_name;
            variation_data.position = index;
            variations.push(variation_data);
          });
        }

        const data = {
          event: 'productsImpression',
          gtmCustom: {
            ecommerce: {
              impressions: variations,
            },
          },
        };

        this.gtm.eventTrack('Product Impressions', data);
      });
    }
  }

  productDetail(product: Product) {
    if (
      this.cookieConsent &&
      product &&
      product.variations &&
      product.variations.length
    ) {
      const variations: any = [];
      if (product.variations && product.variations.length) {
        product.variations.forEach((variation) => {
          const variation_data = this.formatVariation(product, variation);
          variation_data.dimension1 = 'Product Detail View';
          variations.push(variation_data);
        });
      }
      const data: any = {
        event: 'productDetail',
        gtmCustom: {
          ecomm_totalvalue: variations[0].price,
          ecomm_prodid: variations.map((v: any) => v.id),
          ecommerce: {
            detail: {
              actionField: { list: 'Shop List' },
              products: variations,
            },
          },
        },
      };

      this.gtm.eventTrack('Product Detail', data);

      this.fbp.eventTrack('ViewContent', {
        currency: 'GBP',
        value: variations[0].price,
        content_ids: product.product_code,
        content_name: product.title,
        content_type: 'product_group',
      });
    }
  }

  productClick(product: Product, index: number, list: string) {
    if (
      this.cookieConsent &&
      product &&
      product.variations &&
      product.variations.length
    ) {
      const variations: any = [];
      if (!product.variations) {
        return;
      }
      if (product.variations && product.variations.length) {
        product.variations.forEach((variation) => {
          const variation_data = this.formatVariation(product, variation);
          variation_data.position = index;
          variations.push(variation_data);
        });
      }
      const data = {
        event: 'productClick',
        category: 'Ecommerce',
        gtmCustom: {
          ecommerce: {
            click: {
              actionField: { list },
              products: variations,
            },
          },
        },
      };
      this.gtm.eventTrack('Product Click', data);
    }
  }

  addToCart(
    event: string,
    action: string,
    qty?: number,
    variation?: Variation,
    booking?: Booking,
    membership?: boolean,
    one_to_one_booking?: OneToOneBooking,
  ) {
    if (this.cookieConsent) {
      const variations: any = [];
      let variation_data;
      let variation_pixel_data;

      if (variation) {
        const { product } = variation;
        variation_data = this.formatVariation(product, variation);
        variation_pixel_data = this.formatPixelVariation(product, variation);
      }
      if (booking) {
        variation_data = this.formatLesson(booking);
        variation_pixel_data = this.formatPixelLesson(booking);
      }
      if (one_to_one_booking) {
        variation_data = this.formatOneToOne(one_to_one_booking);
        variation_pixel_data = this.formatPixelOneToOne(one_to_one_booking);
      }
      if (membership) {
        variation_data = this.formatMembership();
        variation_pixel_data = this.formatPixelMembership();
      }

      variation_data.quantity = qty || 1;
      variations.push(variation_data);
      const data = {
        event,
        category: 'Ecommerce',
        gtmCustom: {
          ecomm_totalvalue: (
            variation_data.price * variation_data.quantity
          ).toString(),
          ecomm_prodid: variations.map((v: any) => v.id),
          ecommerce: {
            add: {
              products: variations,
            },
          },
        },
      };
      this.gtm.eventTrack(action, data);
      this.fbp.eventTrack('AddToCart', {
        value: variation_data.price.toString(),
        currency: 'GBP',
        content_type: 'product',
        contents: [variation_pixel_data],
      });
    }
  }

  getVariations(items: LineItem[]) {
    const variations: any = [];
    const pixel_variations: any = [];

    items.forEach((item) => {
      let variation_data;
      let variation_pixel_data;
      if (item.type === 'product') {
        const { product } = item.product_variation;
        variation_data = this.formatVariation(product, item.product_variation);
        variation_pixel_data = this.formatPixelVariation(
          product,
          item.product_variation
        );
      }
      if (item.type === 'lesson') {
        variation_data = this.formatLesson(item.lesson_booking);
        variation_pixel_data = this.formatPixelLesson(item.lesson_booking);
      }
      if (item.type === 'membership') {
        variation_data = this.formatMembership();
        variation_pixel_data = this.formatPixelMembership();
      }

      if (item.type === 'one-to-one') {
        variation_data = this.formatOneToOne(item.one_to_one_booking);
        variation_pixel_data = this.formatPixelOneToOne(item.one_to_one_booking);
      }

      variation_data.quantity = item.qty;
      variations.push(variation_data);

      variation_pixel_data.quantity = item.qty.toString();
      pixel_variations.push(variation_pixel_data);
    });

    return { variations, pixel_variations };
  }

  checkoutStep(step, items: LineItem[], order: Order) {
    if (this.cookieConsent && items && items.length) {
      const variations: any[] = this.getVariations(items).variations;
      const pixel_variations: any[] = this.getVariations(items)
        .pixel_variations;
      const data = {
        event: 'checkout',
        category: 'Ecommerce',
        gtmCustom: {
          ecommerce: {
            checkout: {
              actionField: { step },
              products: variations,
            },
          },
        },
      };

      this.gtm.eventTrack('Checkout', data);

      if (step === 1) {
        this.fbp.eventTrack('InitiateCheckout', {
          currency: 'GBP',
          value: order.total,
          content_type: 'product',
          contents: pixel_variations,
        });
      }

      if (step === 2) {
        this.fbp.eventTrack('AddPaymentInfo', {
          currency: 'GBP',
          value: order.total,
          content_type: 'product',
          contents: pixel_variations,
        });
      }
    }
  }

  purchase(order: Order, items: LineItem[]) {
    if (this.cookieConsent && order && items && items.length) {
      const variations: any[] = this.getVariations(items).variations;
      const pixel_variations: any[] = this.getVariations(items)
        .pixel_variations;
      const orderData: any = {
        id: order.orderid.toString(),
        revenue: order.total.toString(),
        shipping: order.shipping_price.toString(),
      };

      const data = {
        event: 'purchase',
        category: 'Ecommerce',
        gtmCustom: {
          ecomm_orderid: order.orderid.toString(),
          ecomm_totalvalue: order.total.toString(),
          ecomm_prodid: variations.map((v) => v.id),
          ecomm_currency: 'GBP',
          ecommerce: {
            purchase: {
              actionField: orderData,
              products: variations,
            },
          },
        },
      };

      this.gtm.eventTrack('Purchase', data);

      this.fbp.eventTrack('Purchase', {
        currency: 'GBP',
        value: order.total.toString(),
        content_type: 'product',
        contents: pixel_variations,
      });
    }
  }
}
