import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { PortalModule } from '@angular/cdk/portal';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { NavComponent } from './layout/nav/nav.component';
import { MenuClickDirective } from './directives/menu-click.directive';
import { SizeGuideComponent } from './components/size-guide/size-guide.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LogoComponent } from './layout/logo/logo.component';
import { ImageGalleryComponent } from './layout/image-gallery/image-gallery.component';
import { CookieLawModule } from './modules/cookie-law/cookie-law.module';
import { PartnersComponent } from './components/partners/partners.component';
import { EarlySubscriptionComponent } from './components/early-subscription/early-subscription.component';
import { WaitingListComponent } from './components/waiting-list/waiting-list.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { ResetComponent } from './components/reset/reset.component';
import { TasterConfirmComponent } from './components/taster-confirm/taster-confirm.component';
import { CdkTableModule } from '@angular/cdk/table';
import { BritishCyclingComponent } from './components/british-cycling/british-cycling.component';
import { AccelercelerateBookingComponent } from './components/accelercelerate-booking/accelercelerate-booking.component';
import { MailchimpComponent } from './components/mailchimp/mailchimp.component';
import { PagePopupComponent } from './components/page-popup/page-popup.component';

@NgModule({
  declarations: [
    MainLayoutComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    NavComponent,
    MenuClickDirective,
    SizeGuideComponent,
    NotFoundComponent,
    LogoComponent,
    ImageGalleryComponent,
    PartnersComponent,
    EarlySubscriptionComponent,
    WaitingListComponent,
    ForgotComponent,
    ResetComponent,
    TasterConfirmComponent,
    BritishCyclingComponent,
    AccelercelerateBookingComponent,
    MailchimpComponent,
    PagePopupComponent
  ],
  imports: [
    SharedModule,
    RouterModule,
    PortalModule,
    CookieLawModule,
    CdkTableModule
  ]
})
export class CoreModule {}
