export const DAYS_OF_WEEK = [
  {
    value: 'Monday',
    title: 'Monday',
  },
  {
    value: 'Tuesday',
    title: 'Tuesday',
  },
  {
    value: 'Wednesday',
    title: 'Wednesday',
  },
  {
    value: 'Thursday',
    title: 'Thursday',
  },
  {
    value: 'Friday',
    title: 'Friday',
  },
  {
    value: 'Saturday',
    title: 'Saturday',
  },
  {
    value: 'Sunday',
    title: 'Sunday',
  },
];

export const ACCELERATE_FREQUENCY = [
  {
    value: 'One off',
    title: 'One off',
  },
  {
    value: 'Weekly',
    title: 'Weekly',
  },
  {
    value: 'Bi-weekly',
    title: 'Bi-weekly',
  },
  {
    value: 'Monthly',
    title: 'Monthly',
  },
];

export const BILLING_CYCLE = [
  {
    value: 'none',
    title: 'None',
  },
  {
    value: 'term',
    title: 'One off term price',
  },
  {
    value: 'session',
    title: 'One off session',
  },
  // {
  //   value: 'day',
  //   title: 'Daily',
  // },
  {
    value: 'week',
    title: 'Weekly',
  },
  {
    value: 'month',
    title: 'Monthly',
  },
  // {
  //   value: 'year',
  //   title: 'Yearly',
  // },
];

export const LESSON_AGES = [
  {
    value: '18 Months +',
    title: '18 Months +',
  },
  {
    value: 'All Ages',
    title: 'All Ages',
  },
  {
    value: '7 Years +',
    title: '7 Years +',
  },
  {
    value: '12 Years +',
    title: '12 Years +',
  },
  {
    value: '18 Years +',
    title: '18 Years +',
  },
  {
    value: '10-16 Years',
    title: '10-16 Years',
  },
];

export const LESSON_TYPE = [
  {
    value: 'Academy Class',
    title: 'Academy',
    icon: 'academy-icon',
    img: '/assets/img/academy.svg',
    tshirt: true,
  },
  {
    value: 'School Class',
    title: 'School',
    icon: 'school-icon',
    img: '/assets/img/school.svg',
    tshirt: true,
  },
  {
    value: 'Pop Up Event',
    title: 'Pop Up',
    icon: 'pop-up-event-icon',
    img: '/assets/img/pop-up.svg',
    tshirt: false,
  },
  {
    value: 'Taster Session',
    title: 'Taster',
    icon: 'pop-up-event-icon',
    img: '/assets/img/pop-up.svg',
    tshirt: true,
  },
];

export const LESSON_NAMES = [
  {
    value: 'Balance',
    title: 'Balance',
    tshirt: true,
  },
  {
    value: 'Learn',
    title: 'Learn',
    tshirt: true,
  },
  {
    value: 'Pedal',
    title: 'Pedal',
    tshirt: true,
  },
  {
    value: 'Race',
    title: 'Race',
    tshirt: true,
  },
  {
    value: 'Accelerate',
    title: 'Accelerate',
    tshirt: true,
  },
  {
    value: 'British Cycling',
    title: 'British Cycling',
    tshirt: false,
  },
];

export const OUTCOME_TYPE = [
  {
    value: 'Core',
    title: 'Core',
  },
  {
    value: 'Optional',
    title: 'Optional',
  },
];

export const TSHIRT_SIZES = [
  {
    value: 'Choose size at class',
    title: 'Choose size at class',
  },
  {
    value: '110',
    title: '110',
  },
  {
    value: '122',
    title: '122',
  },
  {
    value: '134',
    title: '134',
  },
  {
    value: '146',
    title: '146',
  },
  {
    value: '158',
    title: '158',
  },
  {
    value: `1 - Adult's`,
    title: `1 - Adult's`,
  },
  {
    value: `2 - Adult's`,
    title: `2 - Adult's`,
  },
];

export const SURVEY = [
  {
    value: 'Google',
    title: 'Google',
  },
  {
    value: 'Word of Mouth',
    title: 'Word of Mouth',
  },
  {
    value: 'Online Ads',
    title: 'Online Ads',
  },
  {
    value: 'Leaflets',
    title: 'Leaflets',
  },
  {
    value: 'Facebook',
    title: 'Facebook',
  },
  {
    value: 'Twitter',
    title: 'Twitter',
  },
  {
    value: 'Instagram',
    title: 'Instagram',
  },
  {
    value: 'Other',
    title: 'Other',
  },
];

export const ORDER_STATUS = [
  {
    value: 'Pending',
    title: 'Pending',
  },
  {
    value: 'Shipped',
    title: 'Shipped',
  },
  {
    value: 'Complete',
    title: 'Complete',
  },
  {
    value: 'Refunded',
    title: 'Refunded',
  },
];
