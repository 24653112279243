import { User } from '@global/models/user';

export interface State {
  loading: boolean;
  isAuthenticated: boolean;
  redirectUrl: string;
  user: User | null;
  errorMessage: string | null;
}

export const initialState: State = {
  loading: false,
  isAuthenticated: false,
  redirectUrl: null,
  user: null,
  errorMessage: null
};
