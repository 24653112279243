<app-modal #modal [slug]="'login-register'">
  <div class="login">
    <div class="row">
      <div class="login-form">
        <app-title-bar
          [center]="true"
          [barStyle]="{ 'padding-bottom': '25px', 'padding-top': '20px' }"
        >
          <h2 class="text-center">Login</h2>
        </app-title-bar>
        <form [formGroup]="loginForm" (ngSubmit)="login(loginForm.value)">
          <app-form-group
            class="w-100-p"
            [form]="loginForm"
            [controls]="controls"
          ></app-form-group>
          <button
            appButton
            [loading]="loading$ | async"
            type="submit"
            class="btn btn-light-blue center"
          >
            Login
          </button>
          <a
            class="forgot"
            [routerLink]="['', { outlets: { popup: ['forgot'] } }]"
            >Forgot password?</a
          >
        </form>
      </div>
      <div class="create-account">
        <app-title-bar
          [center]="true"
          [barStyle]="{ 'padding-bottom': '25px', 'padding-top': '20px' }"
        >
          <h2 class="text-center">New Customer</h2>
        </app-title-bar>
        <p class="text-center">
          Create account to register your children and book classes!
        </p>
        <button
          [routerLink]="['', { outlets: { popup: ['register'] } }]"
          queryParamsHandling="merge"
          class="btn center"
        >
          Create Account
        </button>
      </div>
    </div>
  </div>
</app-modal>
