<app-header-content>
  <h1 class="text-center white">Terms of<span class="large">Use</span></h1>
</app-header-content>
<div class="page">
  <div class="container">
    <app-title-bar [barStyle]="{'padding-bottom': '15px'}">
      <h2>Privacy Policy</h2>
    </app-title-bar>
    <a href="/assets/terms/CBCA_Website_Privacy_Policy.pdf" target="_blank" class="doc-link">
      <h5>CBCA_Website_Privacy_Policy.pdf</h5>
      <h6>Open</h6>
    </a>
    <app-title-bar [barStyle]="{'padding-bottom': '15px'}">
      <h2>GDPR Policy</h2>
    </app-title-bar>
    <a href="/assets/terms/CBCA_GDPR_Policy.pdf" target="_blank" class="doc-link">
      <h5>CBCA_GDPR_Policy.pdf</h5>
      <h6>Open</h6>
    </a>
    <app-title-bar [barStyle]="{'padding-bottom': '15px'}">
      <h2>Cookies</h2>
    </app-title-bar>
    <a href="/assets/terms/CBCA_Cookie_Policy.pdf" target="_blank" class="doc-link">
      <h5>CBCA_Cookie_Policy.pdf</h5>
      <h6>Open</h6>
    </a>
    <app-title-bar [barStyle]="{'padding-bottom': '15px'}">
      <h2>Website T&C's</h2>
    </app-title-bar>
    <a href="/assets/terms/CBCA_Website_Terms_and_Conditions_of_Use.pdf" target="_blank" class="doc-link">
      <h5>CBCA_Website_Terms_and_Conditions_of_Use.pdf</h5>
      <h6>Open</h6>
    </a>
    <app-title-bar [barStyle]="{'padding-bottom': '15px'}">
      <h2>Complaints Procedure</h2>
    </app-title-bar>
    <a href="/assets/terms/CBCA_Complaints_Procedure.pdf" target="_blank" class="doc-link">
      <h5>CBCA_Complaints_Procedure.pdf</h5>
      <h6>Open</h6>
    </a>
  </div>
</div>
