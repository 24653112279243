import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderContentComponent } from './components/header-content/header-content.component';
import { PortalModule } from '@angular/cdk/portal';
import { WindowHeightDirective } from './directives/window-height.directive';
import { ClassSearchComponent } from './components/class-search/class-search.component';
import { LazyLoadDirective } from './directives/lazy-load.directive';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypeaheadComponent } from './components/typeahead/typeahead.component';
import { ModalComponent } from './components/modal/modal.component';
import { FormGroupComponent } from './components/form/form-group/form-group.component';
import { FormFieldComponent } from './components/form/form-field/form-field.component';
import { InputDirective } from './components/form/input.directive';
import { DateSuffixPipe } from './pipes/date-suffix.pipe';
import { ButtonComponent } from './components/form/button/button.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { ContentExpandComponent } from './components/content-expand/content-expand.component';
import { FilterExpandComponent } from './components/filter-expand/filter-expand.component';
import { SearchModeDirective } from './directives/search-mode.directive';
import { AddressComponent } from './components/address/address.component';
import { ProductPriceComponent } from './components/product-price/product-price.component';
import { OrderCountdownComponent } from './components/order-countdown/order-countdown.component';
import { BreadcrumbsModule } from '@exalif/ngx-breadcrumbs';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { ProductCarouselComponent } from './components/product-carousel/product-carousel.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { FirstInvalidDirective } from './directives/first-invalid.directive';
import { TextAreaPipe } from './pipes/text-area.pipe';
import { DefaultPaymentMethodComponent } from './components/default-payment-method/default-payment-method.component';
import { PaymentStripeComponent } from './components/payment-stripe/payment-stripe.component';
import { AddressStripeComponent } from './components/address-stripe/address-stripe.component';
import { ElementHeightDirective } from './directives/element-height.directive';
import { InViewDirective } from './directives/in-view.directive';
import { AccordianComponent } from './components/accordian/accordian.component';
import { AccordianItemComponent } from './components/accordian/accordian-item.component';
import { BackgroundImageDirective } from './directives/background-image.directive';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ScrollTopDirective } from './directives/scroll-top.directive';
import { BookClassAddStudentComponent } from './components/book-class-add-student/book-class-add-student.component';
import { TextMaskModule } from 'angular2-text-mask';
import { VideoComponent } from './components/video/video.component';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { TimePipe } from './pipes/time.pipe';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CustomStepperComponent } from './components/custom-stepper/custom-stepper.component';
import { EditorjsComponent } from './components/editorjs/editorjs.component';
import { SafePipe } from './pipes/safe.pipe';
import { CdkTableModule } from '@angular/cdk/table';
import { FormlyCustomModule } from './modules/formly/formly-custom.module';
import { FormlyModule } from '@ngx-formly/core';
import { StepperScrollDirective } from './directives/stepper-scroll.directive';
import { LazysizesDirective } from './directives/lazysizes.directive';
import { ImageWrapperComponent } from './components/image-wrapper/image-wrapper.component';
import { FirstInvalidButtonDirective } from './directives/first-invalid-button.directive';
import { BottomBarButtonComponent } from './components/bottom-bar-button/bottom-bar-button.component';
import { ImageSlideUpTextComponent } from './components/image-slide-up-text/image-slide-up-text.component';

const COMPONENTS = [
  HeaderContentComponent,
  ClassSearchComponent,
  TitleBarComponent,
  TypeaheadComponent,
  ModalComponent,
  FormGroupComponent,
  FormFieldComponent,
  ButtonComponent,
  ProgressSpinnerComponent,
  ContentExpandComponent,
  FilterExpandComponent,
  AddressComponent,
  ProductPriceComponent,
  OrderCountdownComponent,
  BreadcrumbComponent,
  ProductCarouselComponent,
  DefaultPaymentMethodComponent,
  PaymentStripeComponent,
  AddressStripeComponent,
  AccordianComponent,
  AccordianItemComponent,
  ContactFormComponent,
  BookClassAddStudentComponent,
  VideoComponent,
  CustomStepperComponent,
  EditorjsComponent,
  ImageWrapperComponent,
  BottomBarButtonComponent,
  ImageSlideUpTextComponent
];
const DIRECTIVES = [
  WindowHeightDirective,
  LazyLoadDirective,
  InputDirective,
  SearchModeDirective,
  FirstInvalidDirective,
  ElementHeightDirective,
  InViewDirective,
  BackgroundImageDirective,
  ScrollTopDirective,
  DebounceClickDirective,
  StepperScrollDirective,
  LazysizesDirective,
  FirstInvalidButtonDirective
];
const MODULES = [
  RouterModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  BreadcrumbsModule,
  SwiperModule,
  NgxCaptchaModule,
  TextMaskModule,
  CdkStepperModule,
  CdkTableModule,
  FormlyModule,
  FormlyCustomModule,
];
const PIPES = [DateSuffixPipe, TextAreaPipe, TimePipe, SafePipe];

@NgModule({
  declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
  imports: [...MODULES, PortalModule, NgSelectModule],
  exports: [...MODULES, ...COMPONENTS, ...DIRECTIVES, ...PIPES],
  providers: [TextAreaPipe],
})
export class SharedModule { }
