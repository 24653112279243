import {
  MemoizedSelector,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import { State } from './state';
import { User } from '@global/models/user';

const getloading = (state: State) => state.loading;
const getIsAuthed = (state: State) => state.isAuthenticated;
const getUser = (state: State) => state.user;

export const selectAuthStoreState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('authStore');

export const selectIsAuthed: MemoizedSelector<object, boolean> = createSelector(
  selectAuthStoreState,
  getIsAuthed
);

export const selectAuthLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectAuthStoreState,
  getloading
);

export const selectUser: MemoizedSelector<object, User> = createSelector(
  selectAuthStoreState,
  getUser
);
