import { Component, OnInit, ViewChild } from '@angular/core';
import { CookieLawService } from './cookie-law.service';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-cookie-law',
  templateUrl: './cookie-law.component.html',
  styleUrls: ['./cookie-law.component.scss']
})
export class CookieLawComponent implements OnInit {
  seen = false;
  @ViewChild('modal') modal: ModalComponent;
  constructor(public cookie: CookieLawService, private _router: Router) {}

  ngOnInit() {
    this.seen = this.cookie.seen();
  }

  modalClose() {
    this.cookie.acceptCookies();
  }

  viewPrivacy() {
    this.cookie.acceptCookies();
    this._router.navigate(['/terms-of-use']).then(() => {
      this.modal.closeModal({});
    });
  }
}
