import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State } from './state';
import { Order } from '@global/models/order';
import { LineItem } from '@global/models/line-item';
import { Country } from '@global/models/country';

const getCountries = (state: State) => state.countries;

const getCartQty = (state: State) => state.cartQty;
const getCarts = (state: State) => state.order.items;
const getCartLessons = (state: State) =>
  state.order && state.order.items
    ? state.order.items.filter((i) => i.type === 'lesson')
    : null;
const getCartBritishCycling = (state: State) =>
  state.order && state.order.items
    ? state.order.items.filter(
      (i) =>
        i.type === 'lesson' &&
        i.lesson_booking.timeslot.lesson.title === 'British Cycling'
    )
    : null;
const getCartProducts = (state: State) =>
  state.order && state.order.items
    ? state.order.items.filter((i) => i.type.toLowerCase() === 'product')
    : null;
const getCartMembership = (state: State) =>
  state.order && state.order.items
    ? state.order.items.filter((i) => i.type.toLowerCase() === 'membership')
    : null;
const getCartOneToOne = (state: State) => state.order && state.order.items
  ? state.order.items.filter((i) => i.type.toLowerCase() === 'one-to-one')
  : null;
const getOrder = (state: State) => state.order;
const getLoading = (state: State) => state.loading;

export const selectCartStoreState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('cartStore');

export const selectCartLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(selectCartStoreState, getLoading);

export const selectCartQty: MemoizedSelector<object, number> = createSelector(
  selectCartStoreState,
  getCartQty
);

export const selectCountries: MemoizedSelector<
  object,
  Country[]
> = createSelector(selectCartStoreState, getCountries);

export const selectCarts: MemoizedSelector<object, LineItem[]> = createSelector(
  selectCartStoreState,
  getCarts
);

export const selectCartLessons: MemoizedSelector<
  object,
  LineItem[]
> = createSelector(selectCartStoreState, getCartLessons);

export const selectCartBritishCycling: MemoizedSelector<
  object,
  LineItem[]
> = createSelector(selectCartStoreState, getCartBritishCycling);

export const selectCartProducts: MemoizedSelector<
  object,
  LineItem[]
> = createSelector(selectCartStoreState, getCartProducts);

export const selectCartMembership: MemoizedSelector<
  object,
  LineItem[]
> = createSelector(selectCartStoreState, getCartMembership);

export const selectCartOneToOne: MemoizedSelector<
  object,
  LineItem[]
> = createSelector(selectCartStoreState, getCartOneToOne);

export const selectOrder: MemoizedSelector<object, Order> = createSelector(
  selectCartStoreState,
  getOrder
);
