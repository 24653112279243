<ng-container *ngFor="let block of blocks">
  <ng-container [ngSwitch]="block.type">
    <p
      *ngSwitchCase="'paragraph'"
      [innerHTML]="block.data.text"
      [style.text-align]="block.data.alignment || 'left'"
    ></p>
    <ng-container *ngSwitchCase="'list'">
      <ng-container [ngSwitch]="block.data.style">
        <ol *ngSwitchCase="'ordered'">
          <li [innerHTML]="item" *ngFor="let item of block.data.items"></li>
        </ol>
        <ul *ngSwitchCase="'unordered'">
          <li [innerHTML]="item" *ngFor="let item of block.data.items"></li>
        </ul>
      </ng-container>
    </ng-container>
    <blockquote
      *ngSwitchCase="'quote'"
      [innerHTML]="block.data.text"
    ></blockquote>
    <div class="image-block" *ngSwitchCase="'image'">
      <img [alt]="block.data.alt" [appLazysizes]="block.data.file.public_id" />
    </div>

    <div class="iframe-container" *ngSwitchCase="'embed'">
      <iframe [src]="block.data.embed | safe"></iframe>
    </div>
    <table
      *ngSwitchCase="'table'"
      cdk-table
      [dataSource]="getTableRows(block.data.content)"
    >
      <ng-container
        [cdkColumnDef]="'col' + i"
        *ngFor="
          let header of getTableHeaders(block.data.content) as headers;
          let i = index
        "
      >
        <th cdk-header-cell *cdkHeaderCellDef>{{ headers[i] }}</th>
        <td [attr.data-title]="headers[i]" cdk-cell *cdkCellDef="let row">
          <span [innerHTML]="row[i]"></span>
        </td>
      </ng-container>
      <tr
        cdk-header-row
        *cdkHeaderRowDef="columnsToDisplay(block.data.content)"
      ></tr>
      <tr
        cdk-row
        *cdkRowDef="let row; columns: columnsToDisplay(block.data.content)"
      ></tr>
    </table>
    <ng-container *ngSwitchCase="'header'">
      <ng-container [ngSwitch]="block.data.level">
        <app-title-bar [center]="true" *ngSwitchCase="1">
          <h1 [innerHTML]="block.data.text"></h1>
        </app-title-bar>
        <app-title-bar [center]="true" *ngSwitchCase="2">
          <h2 [innerHTML]="block.data.text"></h2>
        </app-title-bar>
        <app-title-bar [center]="true" *ngSwitchCase="3">
          <h3 [innerHTML]="block.data.text"></h3>
        </app-title-bar>
        <app-title-bar [center]="true" *ngSwitchCase="4">
          <h4 [innerHTML]="block.data.text"></h4>
        </app-title-bar>
        <h5 *ngSwitchCase="5" [innerHTML]="block.data.text"></h5>
        <h6 *ngSwitchCase="6" [innerHTML]="block.data.text"></h6>
        <app-title-bar [center]="true" *ngSwitchDefault>
          <h2 [innerHTML]="block.data.text"></h2>
        </app-title-bar>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
