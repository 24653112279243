import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-size-guide',
  templateUrl: './size-guide.component.html',
  styleUrls: ['./size-guide.component.scss'],
})
export class SizeGuideComponent implements OnDestroy {
  src = '/assets/img/size-guide.jpg';
  constructor(private route: ActivatedRoute) {
    this.route.params
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        if (params && params['slug']) {
          this.src = `/assets/img/size-guide-${params['slug']}.jpg`;
        }
      });

    this.route.queryParams
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        if (params && params['member']) {
          this.src = '/assets/img/member-size-guide.jpg';
        }
      });
  }

  ngOnDestroy() {}
}
