<app-header-content>
  <h1 class="text-center white">Pop Up<span class="large">Events</span></h1>
</app-header-content>
<div class="page">
  <div class="container">
    <app-title-bar [center]="true">
      <h2 class="text-center">Host your own Clancy Briggs event</h2>
    </app-title-bar>
  </div>
  <div class="form-block">
    <div class="container">
      <app-title-bar [center]="true">
        <h2 class="text-center h1 large">Host an academy taster day</h2>
      </app-title-bar>
      <p class="text-center">If we're not yet running in your area, or if you'd like to get a flavour for what an
        academy could look like where you're based - why not get in touch to discuss hosting a pop-up event with us?
        Also if you're thinking about becoming a franchisee, hosting a pop-event can be the first step on that journey.
      </p>
      <p class="text-center">Fill out the form below to let us know about an event you would like us to run.</p>
      <app-contact-form [location]="true" type="popup" [center]="true"></app-contact-form>
    </div>
  </div>
</div>
