import {
  Directive,
  ElementRef,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { PlatformService } from '@app/core/services/platform.service';
import { LazyLoadService } from './lazy-load.service';
import { filter, take, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Directive({
  selector: '[appInView]'
})
export class InViewDirective implements OnInit, OnDestroy {
  @Input() trigger: ElementRef;
  @Output() appear: EventEmitter<void> = new EventEmitter();
  constructor(
    private _ps: PlatformService,
    private lazyService: LazyLoadService
  ) {}

  ngOnInit() {
    if (this._ps.isBrowser && this.trigger) {
      this.lazyService
        .elementInSight(new ElementRef(this.trigger))
        .pipe(
          filter(visible => visible),
          take(1),
          takeUntil(componentDestroyed(this))
        )
        .subscribe(() => {
          this.appear.emit();
        });
    }
  }

  ngOnDestroy() {}
}
