import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import { hmrBootstrap } from './hmr';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { VERSION } from '@env/version';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn:
    'https://105ffbaf1ac74dba8ee5f30e130172c2@o220243.ingest.sentry.io/1538310',
  environment: environment.production ? 'production' : 'development',
  release: VERSION.version,
  ignoreErrors: ['ResizeObserver loop limit exceeded', 'Illegal invocation'],
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [environment.site, environment.host],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 0.2,
});

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  document.addEventListener('DOMContentLoaded', () => {
    bootstrap().catch((err) => console.error(err));
  });
}
