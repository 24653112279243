import {
  Component,
  OnInit,
  Input,
  ContentChild,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  HostListener,
  ElementRef,
  ViewChild
} from '@angular/core';
import { ICustomControl } from '@global/models/fields';
import { NgControl, FormControlName } from '@angular/forms';
import { InputDirective } from '../input.directive';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'form-field',
    '[class.select]': '_isSelect()',
    '[class.form-field-invalid]': 'formControl.hasErrors',
    '[class.accent]': 'color == "accent"',
    '[class.warn]': 'color == "warn"',
    '[class.ng-untouched]': '_shouldForward("untouched")',
    '[class.ng-touched]': '_shouldForward("touched")',
    '[class.ng-pristine]': '_shouldForward("pristine")',
    '[class.ng-dirty]': '_shouldForward("dirty")',
    '[class.ng-valid]': '_shouldForward("valid")',
    '[class.ng-invalid]': '_shouldForward("invalid")',
    '[class.ng-pending]': '_shouldForward("pending")',
    '[class.focused]': 'inputRef.focused',
    '[class.empty]': 'isEmpty()',
    '[class.has-value]': 'hasValue()',
    '[class.w-50]': 'control.half_width'
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldComponent implements OnInit {
  @ViewChild('fieldClick') fieldClick: ElementRef;
  @ContentChild(FormControlName)
  formControl: FormControlName;
  @ContentChild(InputDirective) inputRef: InputDirective;
  @Input() control: ICustomControl;
  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent): void {
    if (event.target === this.fieldClick.nativeElement) {
      this.inputRef.focus();
    }
  }
  constructor() {}

  ngOnInit() {}

  hasValue() {
    return this.formControl.value || this.control.value;
  }

  isEmpty() {
    return !this.formControl.value || this.formControl.value === '';
  }

  isRequired() {
    return (
      this.control &&
      this.control.validators &&
      this.control.validators.find(v => v === 'required')
    );
  }

  _isSelect() {
    return this.control.controlType && this.control.controlType === 'select';
  }

  _shouldForward(prop: keyof NgControl): boolean {
    const ngControl = this.formControl ? this.formControl : null;
    return ngControl && ngControl[prop];
  }
}
