import {
  Component,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  TemplateRef
} from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { LayoutService } from '@app/core/layout/layout.service';

@Component({
  selector: 'app-header-content',
  templateUrl: './header-content.component.html',
  styleUrls: ['./header-content.component.scss']
})
export class HeaderContentComponent implements AfterViewInit, OnDestroy {
  @ViewChild('templatePortalContent')
  templatePortalContent: TemplateRef<any>;
  private templatePortal: TemplatePortal<any>;
  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _layoutService: LayoutService
  ) {}

  ngAfterViewInit(): void {
    this.templatePortal = new TemplatePortal(
      this.templatePortalContent,
      this._viewContainerRef
    );
    this._layoutService.selectedPortal = this.templatePortal;
  }

  ngOnDestroy(): void {
    this._viewContainerRef.clear();
  }
}
