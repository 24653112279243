import {
  Component,
  HostBinding,
  ElementRef,
  AfterViewInit,
  HostListener,
  ViewChild
} from '@angular/core';
import { LayoutService } from '../layout.service';
import { SafeStyle } from '@angular/platform-browser';
import { ImageService } from '@app/core/services/image.service';

@Component({
  selector: 'app-header, [app-header]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  exportAs: 'header'
})
export class HeaderComponent implements AfterViewInit {
  @ViewChild('videoPlayer', { static: true }) videoplayer: any;
  @HostBinding('style.background-image') public backgroundImage: SafeStyle;
  @HostBinding('class') class: string;
  video_url: string;
  @HostListener('window:resize')
  onResize() {
    this.layoutService.headerHeight = this.height;
  }

  get height() {
    return this._el.nativeElement.clientHeight;
  }

  constructor(
    public layoutService: LayoutService,
    private _imageService: ImageService,
    private _el: ElementRef
  ) {
    this.layoutService.backgroundImage$.subscribe((id: string) => {
      if (id) {
        this.video_url = null;
        this.backgroundImage = this._imageService.backgroundImage(
          id,
          this._el,
          'linear-gradient(rgba(0,0,0,0.15) 0%, rgba(0,0,0,0.15) 100%)'
        );
      } else {
        this.backgroundImage = null;
      }
      this.setHeaderHeight();
    });
    this.layoutService.backgroundVideo$.subscribe((url: string) => {
      this.video_url = url;
      this.backgroundImage = null;

      this.setHeaderHeight();
    });
    this.layoutService.headerClass$.subscribe((className: string) => {
      this.class = className;
      this.setHeaderHeight();
    });
  }

  ngAfterViewInit() {
    this.setHeaderHeight();
  }

  canPlay() {
    if (this.videoplayer && this.videoplayer.nativeElement) {
      this.videoplayer.nativeElement.play();
    }
  }

  setHeaderHeight() {
    setTimeout(() => {
      this.layoutService.headerHeight = this.height;
    }, 0);
  }
}
