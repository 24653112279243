import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-content-expand',
  templateUrl: './content-expand.component.html',
  styleUrls: ['./content-expand.component.scss'],
  exportAs: 'expand'
})
export class ContentExpandComponent implements OnInit {
  @Input() color: string;
  @Input() hideTitle: boolean;
  opened = false;
  constructor() { }

  ngOnInit() { }
}
