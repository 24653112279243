<ng-container [formGroup]="form">
  <ng-container
    *ngFor="let control of controls"
    [ngSwitch]="control.controlType"
  >
    <app-form-field [control]="control" *ngSwitchCase="'text'">
      <input
        appInput
        class="input-element"
        [textMask]="control.mask"
        [name]="control.id"
        [formControlName]="control.id"
        [type]="control.type ? control.type : 'text'"
        [attr.autocomplete]="control.autocomplete ? control.autocomplete : null"
        *ngIf="control.mask; else noMask"
      />
      <ng-template #noMask
        ><input
          appInput
          class="input-element"
          [name]="control.id"
          [formControlName]="control.id"
          [type]="control.type ? control.type : 'text'"
          [attr.autocomplete]="
            control.autocomplete ? control.autocomplete : null
          "
      /></ng-template>
    </app-form-field>
    <app-form-field [control]="control" *ngSwitchCase="'textarea'">
      <textarea
        appInput
        class="input-element"
        [name]="control.id"
        [formControlName]="control.id"
      ></textarea>
    </app-form-field>
    <div class="checkbox" *ngSwitchCase="'checkbox'">
      <input
        appInput
        [id]="control.id"
        [name]="control.id"
        [formControlName]="control.id"
        type="checkbox"
      />
      <label [attr.for]="control.id"
        ><span class="checkbox-label">{{ control.label }}</span>
      </label>
    </div>
    <div class="radio" *ngSwitchCase="'radio'">
      <span
        class="intro"
        [innerHTML]="control.introText"
        *ngIf="control.introText"
      ></span>
      <ng-container *ngIf="control.optionsAsync; else noAsyncRadio">
        <ng-container *ngFor="let option of control.optionsAsync | async">
          <input
            appInput
            [id]="option[control.optionValue]"
            [attr.name]="control.id"
            [formControlName]="control.id"
            [attr.value]="option[control.optionValue]"
            type="radio"
          />
          <label [attr.for]="option[control.optionValue]"
            >{{ option[control.optionLabel] }}
          </label>
        </ng-container>
      </ng-container>
      <ng-template #noAsyncRadio>
        <ng-container *ngFor="let option of control.options">
          <label>
            {{ option[control.optionLabel] }}
            <input
              appInput
              [id]="option[control.optionValue]"
              [name]="control.id"
              [formControlName]="control.id"
              [value]="option[control.optionValue]"
              type="radio"
            />
            <span class="checkmark"></span>
          </label>
        </ng-container>
      </ng-template>
    </div>
    <app-form-field [control]="control" *ngSwitchCase="'select'">
      <span class="dropdown-select">
        <select
          appInput
          [id]="control.id"
          [name]="control.id"
          [formControlName]="control.id"
          *ngIf="control.optionsAsync; else noAsyncSelect"
        >
          <option
            [value]="option[control.optionLabel]"
            *ngFor="let option of control.optionsAsync | async"
          >
            {{ option[control.optionValue] }}</option
          >
        </select>
        <ng-template #noAsyncSelect>
          <select
            appInput
            [id]="control.id"
            [name]="control.id"
            [formControlName]="control.id"
          >
            <option
              [value]="option[control.optionLabel]"
              *ngFor="let option of control.options"
            >
              {{ option[control.optionValue] }}</option
            >
          </select>
        </ng-template>
      </span>
    </app-form-field>
    <ng-container *ngSwitchCase="'recaptcha'">
      <ngx-recaptcha2
        #captchaElem
        [siteKey]="siteKey"
        [useGlobalDomain]="false"
        [formControlName]="control.id"
        *ngIf="ps.isBrowser"
        [ngClass]="{ center: center }"
      >
      </ngx-recaptcha2>
    </ng-container>
  </ng-container>
</ng-container>
