import {
  MemoizedSelector,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import { State } from './state';

const getOrderDeadline = (state: State) => state.deadlines.order_deadline;
const getDeliveryDeadline = (state: State) => state.deadlines.delivery_deadline;

export const selectSettingStoreState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('settingStore');

export const selectOrderDeadline: MemoizedSelector<
  object,
  Date
> = createSelector(
  selectSettingStoreState,
  getOrderDeadline
);

export const selectDeliveryDeadline: MemoizedSelector<
  object,
  Date
> = createSelector(
  selectSettingStoreState,
  getDeliveryDeadline
);
