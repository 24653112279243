<app-modal #modal [showClose]="false">
  <div class="reset">
    <app-title-bar [center]="true" [barStyle]="{'padding-bottom': '25px', 'padding-top': '20px'}">
      <h2 class="text-center">British Cycling</h2>
    </app-title-bar>
    <form [formGroup]="form" appFirstInvalid>
      <ng-container formArrayName="students">
        <ng-container *ngFor="let student of form.controls['students']['controls']; let i=index">
          <ng-container [formGroupName]="i">
            <h4>{{student.get('name').value}}</h4>
            <app-form-group class="w-100-p" [form]="student" [controls]="controlsArray[i]"></app-form-group>
          </ng-container>
        </ng-container>
      </ng-container>
      <app-form-group class="w-100-p" [form]="form" [controls]="controls"></app-form-group>
      <button appButton [loading]="loading" type="submit" class="btn btn-light-blue center" [disabled]="!form.valid"
        (click)="save()">Save</button>
    </form>
  </div>
</app-modal>
