import { Component } from '@angular/core';

@Component({
  selector: 'app-early-subscription',
  templateUrl: './early-subscription.component.html',
  styleUrls: ['./early-subscription.component.scss']
})
export class EarlySubscriptionComponent {
  constructor() {}
}
