import { Action } from '@ngrx/store';

export enum ActionTypes {
  MENU_TOGGLE = '[State] Menu Toggle',
  MENU_OPEN = '[State] Menu Open'
}

export class MenuToggle implements Action {
  readonly type = ActionTypes.MENU_TOGGLE;
  constructor() {}
}

export class MenuOpen implements Action {
  readonly type = ActionTypes.MENU_OPEN;
  constructor(public open: boolean) {}
}

export type Actions = MenuOpen | MenuToggle;
