import { Timeslot } from '@global/models/timeslot';
import { Student } from '@global/models/student';
import { Lesson } from '@global/models/lesson';
import { Location } from '@global/models/location';

export interface Session {
  lesson: Lesson;
  location: Location;
  timeslot: Timeslot;
  student: Student;
}

export interface State {
  sessions: Session[];
}

export const initialState: State = {
  sessions: []
};
