<div class="header-container">
  <div class="background-video" *ngIf="video_url">
    <video controls="false" width="100%" autoplay playsinline preload="auto" loop="loop" [muted]="true"
      (canplaythrough)="canPlay()" #videoPlayer>
      <source [src]="video_url" type="video/mp4">
    </video>
  </div>
  <div class="hero-content container" *ngIf="layoutService.selectedPortal">
    <ng-template [cdkPortalOutlet]="layoutService.selectedPortal"></ng-template>
  </div>
</div>
