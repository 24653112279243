import { Injectable, ElementRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PlatformService } from '@app/core/services/platform.service';
import { map, distinctUntilChanged, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LazyLoadService {
  constructor(private _ps: PlatformService) {}
  elementInSight(element: ElementRef): Observable<boolean> {
    if (this._ps.isServer) {
      return of(true);
    }
    const el = element.nativeElement;

    const elementVisible$ = new Observable((observer) => {
      const intersectionObserver = new IntersectionObserver((entries) => {
        observer.next(entries);
      });
      intersectionObserver.observe(el);

      return () => {
        intersectionObserver.disconnect();
      };
    }).pipe(
      mergeMap((entries: IntersectionObserverEntry[]) => {
        return entries;
      }),
      map((entry: any) => entry.isIntersecting),
      distinctUntilChanged()
    );

    return elementVisible$;
  }
}
