import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-accordian-item',
  templateUrl: './accordian-item.component.html',
  styleUrls: ['./accordian-item.component.scss']
})
export class AccordianItemComponent implements OnInit {
  @Input() color: string;
  @Input() opened = false;
  @Output() toggle: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {}
}
