import { Directive, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Directive({
  selector: '[appScrollTop]'
})
export class ScrollTopDirective {
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    this._viewPortScroll.scrollToPosition([0, 0]);
  }
  constructor(private _viewPortScroll: ViewportScroller) {}
}
