import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ICustomControl } from '@global/models/fields';
import { FormService } from '@app/core/services/form.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, ApiOptions } from '@app/core/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PasswordValidation } from '@app/shared/validators/register-validation';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
})
export class ResetComponent implements OnInit, OnDestroy {
  resetForm: FormGroup;
  controls: ICustomControl[];
  loading = false;
  constructor(
    private _formService: FormService,
    private _fb: FormBuilder,
    private _router: Router,
    private _api: ApiService,
    private _toastr: ToastrService,
    private _route: ActivatedRoute
  ) {
    this.resetForm = this._fb.group({});
  }

  ngOnInit() {
    this.controls = [
      {
        id: 'password',
        label: 'New Password',
        controlType: 'text',
        type: 'password',
        validators: ['required'],
      },
      {
        id: 'confirmPassword',
        label: 'Confirm Password',
        controlType: 'text',
        type: 'password',
        validators: ['required'],
      },
    ];
    this._formService.addControls(this.controls, this.resetForm);
    this.resetForm.setValidators(PasswordValidation.MatchPassword);
  }

  reset(data: any) {
    if (!this.resetForm.valid) {
      return;
    }
    this.loading = true;
    const reset = {
      action: 'resetPwdLong',
      value: {
        token: this._route.snapshot.params.token,
        password: data.password,
      },
    };
    const options: ApiOptions = new ApiOptions({
      endpoint: 'authManagement',
    });
    this._api
      .create(options, reset)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        () => {
          this.loading = false;
          this._toastr.success(
            'Your password has been reset you may now login.',
            'Success'
          );
          this._router.navigate(['', { outlets: { popup: ['login'] } }]);
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  ngOnDestroy() {}
}
