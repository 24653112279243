import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { Country } from '@global/models/country';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ICustomControl } from '@global/models/fields';
import { FormService } from '@app/core/services/form.service';
import { ApiService, ApiOptions } from '@app/core/services/api.service';
import { tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PaymentMethod } from 'ngx-stripe/lib/interfaces/payment-intent';

@Component({
  selector: 'app-address-stripe',
  templateUrl: './address-stripe.component.html',
  styleUrls: ['./address-stripe.component.scss']
})
export class AddressStripeComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() paymentMethod: PaymentMethod;
  controls: ICustomControl[];
  countries$: Observable<Country[]>;
  private countries: Country[];
  constructor(
    private _formService: FormService,
    private _fb: FormBuilder,
    private _api: ApiService
  ) {}

  ngOnInit() {
    const options: ApiOptions = new ApiOptions({
      endpoint: 'country',
      query: {
        $select: ['name', 'cca2'],
        $sort: ['name']
      }
    });
    this._api
      .find(options)
      .pipe(
        tap((countries: Country[]) => (this.countries = countries)),
        takeUntil(componentDestroyed(this))
      )
      .subscribe(() => this.setupForm());
  }

  setupForm() {
    this.controls = [
      {
        id: 'name',
        label: 'Name',
        value: this.paymentMethod
          ? this.paymentMethod.billing_details.name
          : null,
        controlType: 'text',
        validators: ['required'],
        errors: {
          required: 'required'
        }
      },
      {
        id: 'line1',
        label: 'House number and street name',
        value: this.paymentMethod
          ? this.paymentMethod.billing_details.address.line1
          : null,
        controlType: 'text',
        validators: ['required'],
        errors: {
          required: 'required'
        }
      },
      {
        id: 'line2',
        label: 'Apartment, suite, unit etc. (optional)',
        value: this.paymentMethod
          ? this.paymentMethod.billing_details.address.line2
          : null,
        controlType: 'text'
      },
      {
        id: 'city',
        label: 'Town/City',
        value: this.paymentMethod
          ? this.paymentMethod.billing_details.address.city
          : null,
        controlType: 'text',
        validators: ['required'],
        errors: {
          required: 'required'
        }
      },
      {
        id: 'state',
        label: 'County',
        value: this.paymentMethod
          ? this.paymentMethod.billing_details.address.state
          : null,
        controlType: 'text'
      },
      {
        id: 'country_select',
        label: 'Country',
        value: this.paymentMethod
          ? this.countries.find(
              c => c.cca2 === this.paymentMethod.billing_details.address.country
            ).name
          : 'United Kingdom',
        controlType: 'select',
        options: this.countries,
        optionValue: 'name',
        optionLabel: 'name',
        validators: ['required'],
        errors: {
          required: 'required'
        }
      },
      {
        id: 'country',
        label: 'Country Code',
        value: this.paymentMethod
          ? this.paymentMethod.billing_details.address.country
          : 'GB',
        controlType: 'hidden'
      },
      {
        id: 'postal_code',
        label: 'Postcode',
        value: this.paymentMethod
          ? this.paymentMethod.billing_details.address.postal_code
          : null,
        controlType: 'text',
        validators: ['required'],
        errors: {
          required: 'required'
        }
      }
    ];
    this._formService.addControls(this.controls, this.form);
    this.form
      .get('country_select')
      .valueChanges.pipe(takeUntil(componentDestroyed(this)))
      .subscribe((name: string) => {
        const country = this.countries.find(c => c.name === name);
        this.form.get('country').setValue(country.cca2);
      });
  }

  ngOnDestroy() {}
}
