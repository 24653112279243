<div class="swiper-container-outside">
  <div class="swiper-container" [swiper]="sliderConfig">
    <div class="swiper-wrapper">
      <a class="swiper-slide product" [routerLink]="['/shop', product.slug]" *ngFor="let product of products">
        <div class="product-col">
          <div class="icon-wrapper">
            <svg class="icon">
              <use [attr.xlink:href]="
                  '/assets/img/sprite.svg?v1.7#' +
                  product.categories[0].slug +
                  '-icon'
                "></use>
            </svg>
          </div>
          <div appImageWrapper [aspect_ratio]="0.8958566629" *ngIf="product.feature_image">
            <img [alt]="product.title" [appLazysizes]="product.feature_image.public_id" [crop_ratio]="0.8958566629" />
          </div>
          <div class="product-title">
            <h2>{{ product.collections[0].title }}</h2>
            <h3>{{ product.title }}</h3>
          </div>
          <div appProductPrice class="price" [variations]="product.variations"></div>
        </div>
      </a>
    </div>
  </div>
  <div class="swiper-button-prev"></div>
  <div class="swiper-button-next"></div>
</div>