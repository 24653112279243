import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { VisibilityState } from '../nav/nav.component';
import { PlatformService } from '@app/core/services/platform.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  animations: [
    trigger('toggle', [
      state(
        VisibilityState.Hidden,
        style({ opacity: 1, transform: 'translateY(-100%)' })
      ),
      state(
        VisibilityState.Visible,
        style({ opacity: 1, transform: 'translateY(0)' })
      ),
      transition('hidden=>visible', animate('500ms 250ms ease-in-out')),
      transition('visible=>hidden', animate('300ms 200ms ease-in-out'))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent {
  @Input() toggle: VisibilityState;
  disableAnimation = true;
  constructor(public ps: PlatformService) {}
}
