import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { ApiService, ApiOptions } from '@app/core/services/api.service';
import { SeoService } from '@app/core/services/seo.service';
import { tap, catchError } from 'rxjs/operators';
import { Page } from '@global/models/page';

@Injectable({
  providedIn: 'root',
})
export class PageResolver implements Resolve<Page> {
  constructor(
    private _api: ApiService,
    private _seo: SeoService,
    private _router: Router
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Page> {
    const apiOptions: ApiOptions = new ApiOptions({
      endpoint: 'page',
    });
    return this._api.findOne(route.data.slug || route.params.slug, apiOptions).pipe(
      catchError(() => {
        return of([false]);
      }),
      tap((data: Page) => {
        if (data) {
          this._seo.setMetaAndLinks(data.title, data.seo);
        } else {
          this._router.navigate(['/404']);
        }
      })
    );
  }
}
