import { Injectable, OnDestroy } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { PlatformService } from './platform.service';
import { environment } from '@env/environment';
import { Location } from '@angular/common';
import { Image } from '@global/models/image';
import { LinkService } from './link.service';

@Injectable({
  providedIn: 'root',
})
export class SeoService implements OnDestroy {
  private endPageTitle = 'Clancy Briggs Cycling Academy';
  constructor(
    protected title: Title,
    protected meta: Meta,
    protected location: Location,
    protected link: LinkService,
    protected ps: PlatformService
  ) {}
  setMetaAndLinks(
    item_title: string,
    seo?: any,
    section?: string,
    image?: Image,
    type?: string,
    canonical?: string,
    home?: boolean,
    robots = true
  ) {
    const url = `${environment.site}${this.location.path()}`;
    let social_image = `${environment.site}/assets/img/facebook-logo.jpg`;
    if (image) {
      social_image = `${environment.image_cdn}/${image.public_id}?crop=smart&width=1200&height=630`;
    }

    this.endPageTitle = section
      ? `${section} | ${this.endPageTitle}`
      : `${this.endPageTitle}`;

    const title = seo && seo.title ? `${seo.title}` : `${item_title}`;

    this.title.setTitle(title);

    this.meta.updateTag({
      name: 'description',
      content: seo ? seo.description : '',
    });

    // Facebook tags
    this.meta.updateTag({ property: 'fb:pages', content: '101012887920614' });
    this.meta.updateTag({
      property: 'og:site_name',
      content: 'Clancy Briggs Cycling Academy',
    });
    this.meta.updateTag({ property: 'og:title', content: title });

    this.meta.updateTag({
      property: 'og:description',
      content: seo ? seo.description : '',
    });

    this.meta.updateTag({
      property: 'og:url',
      content: url,
    });

    this.meta.updateTag({
      property: 'og:image',
      content: social_image,
    });

    this.meta.updateTag({
      property: 'og:image:width',
      content: '1200',
    });

    this.meta.updateTag({
      property: 'og:image:height',
      content: '630',
    });

    this.meta.updateTag({
      property: 'og:type',
      content: type,
    });

    // Twitter

    this.meta.updateTag({
      name: 'twitter:card',
      content: 'summary_large_image',
    });

    this.meta.updateTag({
      name: 'twitter:site',
      content: '@clancybriggs',
    });

    this.meta.updateTag({ name: 'twitter:title', content: title });

    this.meta.updateTag({
      name: 'twitter:description',
      content: seo ? seo.description : '',
    });

    this.meta.updateTag({
      name: 'twitter:image',
      content: social_image,
    });

    this.meta.updateTag({
      name: 'robots',
      content: robots ? 'all' : 'noindex',
    });

    // Canonical
    if (canonical && this.ps.isServer) {
      this.clearCanonical();
      this.link.addTag({
        href: `${environment.site}/${canonical}`,
        rel: 'canonical',
      });
    } else if (this.ps.isServer) {
      this.clearCanonical();
      this.link.addTag({
        href: url,
        rel: 'canonical',
      });
    }
  }

  clearCanonical() {
    if (this.ps.isServer) {
      this.link.removeTag('rel=canonical');
    }
  }

  ngOnDestroy() {
    this.clearCanonical();
  }
}
