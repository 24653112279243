import { Injectable } from '@angular/core';

import { Observable, of as observableOf } from 'rxjs';
import { Action } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, catchError, startWith, concatMap } from 'rxjs/operators';
import * as featureActions from './actions';
import { environment } from '@env/environment';
import { ApiService, ApiOptions } from '@app/core/services/api.service';

@Injectable()
export class SettingEffects {
  private endpoint = 'setting';
  private host: string = environment.host;

  get url() {
    return `${this.host}/${this.endpoint}`;
  }

  
  loadSettings$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.SettingLoad>(featureActions.ActionTypes.SETTING_LOAD),
    startWith(new featureActions.SettingLoad()),
    map(action => action.query),
    concatMap((query: any) => {
      const options: ApiOptions = new ApiOptions({
        endpoint: this.endpoint,
        query,
        loader: false,
        skip: false
      });
      return this.api.find(options).pipe(
        map((settings: any) => new featureActions.SettingSet(settings)),
        catchError(error =>
          observableOf(new featureActions.SettingFailure({ error }))
        )
      );
    })
  ));

  constructor(private actions$: Actions, private api: ApiService) {}
}
