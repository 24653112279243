import { Action } from '@ngrx/store';
import { Session } from './state';

export enum ActionTypes {
  ADD_SESSION = '[Taster Sessions] Add Session',
  REMOVE_SESSION = '[Taster Sessions] Remove Session'
}

export class AddSession implements Action {
  readonly type = ActionTypes.ADD_SESSION;
  constructor(public payload: Session) {}
}

export class RemoveSession implements Action {
  readonly type = ActionTypes.REMOVE_SESSION;
  constructor(public timeslot: string, public student: string) {}
}

export type Actions = AddSession | RemoveSession;
