import { Injectable } from '@angular/core';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Angulartics2Facebook } from 'angulartics2/facebook';
import { CookieService } from '@gorniv/ngx-universal';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CookieLawService {
  cookieName = 'cookie_consent_given';
  tracking = false;
  constructor(
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private angulartics2Facebook: Angulartics2Facebook,
    private _cookieService: CookieService,
    private _router: Router
  ) {}

  public seen(code?: string): boolean {
    if (
      this._cookieService.get(this.cookieName) &&
      this._cookieService.get(this.cookieName) === 'allow'
    ) {
      this.startTracking();
      this.tracking = true;
      return true;
    }
    this.tracking = false;
    return false;
  }

  showCookie() {
    if (!this._cookieService.get(this.cookieName)) {
      this._router.navigate(['', { outlets: { popup: ['cookie'] } }]);
    }
  }

  acceptCookies() {
    this._cookieService.put(this.cookieName, 'allow', {
      expires: new Date(Date.now() + 365 * 86400000),
    });
    this.tracking = true;
    this.startTracking();
  }

  startTracking() {
    this.angulartics2GoogleTagManager.startTracking();
    this.angulartics2Facebook.startTracking();
  }
}
