<svg class="spinner" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
  <style>
    .spinner {
      animation: rotator 5s linear infinite;
      transform-origin: center;
      overflow: hidden;
    }

    @keyframes rotator {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    .path {
      stroke-dasharray: 270;
      stroke-dashoffset: 0;
      transform-origin: center;
      stroke: white;
      animation: dash 1.4s ease-in-out infinite;
    }

    @keyframes dash {
      0% {
        stroke-dashoffset: 265;
      }

      50% {
        stroke-dashoffset: 65;
        transform: rotate(90deg);
      }

      100% {
        stroke-dashoffset: 265;
        transform: rotate(360deg);
      }
    }

  </style>
  <circle class="path" fill="none" stroke-width="10" stroke-linecap="butt" cx="50" cy="50" r="40"></circle>
</svg>
