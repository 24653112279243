import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
} from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { ICustomControl } from '@global/models/fields';
import { ApiService, ApiOptions } from '@app/core/services/api.service';
import { Student } from '@global/models/student';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Lesson } from '@global/models/lesson';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import {
  BookClassAddStudentService,
  StepType,
} from './book-class-add-student.service';
import { TSHIRT_SIZES } from '@global/data';

@Component({
  selector: 'app-book-class-add-student',
  templateUrl: './book-class-add-student.component.html',
  styleUrls: ['./book-class-add-student.component.scss'],
  providers: [BookClassAddStudentService],
})
export class BookClassAddStudentComponent implements OnInit, OnDestroy {
  @Input() student: Student;
  @Input() lesson: Lesson;
  @Input() edit: boolean;
  studentForm: FormGroup;
  controls: ICustomControl[];
  @Output() studentAdded: EventEmitter<Student> = new EventEmitter<Student>();
  @Output() studentUpdated: EventEmitter<Student> = new EventEmitter<Student>();
  @Output() studentValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  model = {
    tshirt_size: TSHIRT_SIZES[0].value,
  };
  saving = false;
  steps: StepType[];
  fields: FormlyFieldConfig[];
  form: FormArray;
  formOld = new FormGroup({});
  options: FormlyFormOptions = {};
  constructor(
    public bookingService: BookClassAddStudentService,
    private _api: ApiService
  ) {}

  get showNewForm() {
    return (
      !this.student ||
      (this.student && this.student.consent && this.student.consent.signature)
    );
  }

  ngOnInit() {
    if (this.student) {
      this.model = {
        ...this.model,
        ...this.student,
      };
    }

    this.fields = this.bookingService.createFormSimple(
      this.lesson,
      this.student
    );
    this.steps = this.bookingService.createForm(this.lesson, this.student);
    this.form = new FormArray(this.steps.map(() => new FormGroup({})));

    if (this.student) {
      this.student.valid = this.form.valid;
    }
  }

  addStudent() {
    if (this.showNewForm && !this.form.valid) {
      return;
    }

    if (!this.showNewForm && !this.formOld.valid) {
      return;
    }

    let values;

    if (this.showNewForm) {
      values = Object.assign({}, ...this.form.value);
    } else {
      values = this.formOld.value;
    }

    this.saving = true;
    if (this.student) {
      const options: ApiOptions = new ApiOptions({
        endpoint: 'student',
      });
      this._api
        .update(this.student._id, options, values)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((student: Student) => {
          if (student) {
            student.selected = this.student.selected;
            student.valid = this.form.valid;
            this.studentUpdated.emit(student);
          }
          this.saving = false;
        });
    } else {
      const options: ApiOptions = new ApiOptions({
        endpoint: 'student',
      });
      this._api
        .create(options, values)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((student: Student) => {
          if (student) {
            student.selected = true;
            student.valid = this.form.valid;
            this.studentAdded.emit(student);
          }
          this.saving = false;
        });
    }
  }

  ngOnDestroy() {}
}
