import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CartStoreModule } from './cart-store';
import { AuthStoreModule } from './auth-store';
import { AcademyStoreModule } from './academy-store';
import { StateStoreModule } from './state-store';
import { TasterStoreModule } from './taster-store';
import { SettingStoreModule } from './setting-store';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      }
    ),
    EffectsModule.forRoot([]),
    CartStoreModule,
    AuthStoreModule,
    AcademyStoreModule,
    StateStoreModule,
    TasterStoreModule,
    SettingStoreModule,
  ],
})
export class RootStoreModule {}
