import { Directive } from '@angular/core';
import { LayoutService } from '@app/core/layout/layout.service';
import { PlatformService } from '@app/core/services/platform.service';

@Directive({
  selector: '[appSearchMode]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(blur)': '_focusChanged(false)',
    '(focus)': '_focusChanged(true)'
  }
})
export class SearchModeDirective {
  focused = false;

  constructor(private layout: LayoutService, private _ps: PlatformService) {}

  _focusChanged(isFocused: boolean) {
    if (isFocused !== this.focused) {
      this.focused = isFocused;
      // this.layout.searchMode = isFocused;
      if (this._ps.isBrowser && !isFocused) {
        this._ps.getNativeWindow().scrollTo(0, 0);
      }
    }
  }
}
