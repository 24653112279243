import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ICustomControl } from '@global/models/fields';
import { FormService } from '@app/core/services/form.service';
import { Router } from '@angular/router';
import { ApiService, ApiOptions } from '@app/core/services/api.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit, OnDestroy {
  forgotForm: FormGroup;
  controls: ICustomControl[];
  loading = false;
  constructor(
    private _formService: FormService,
    private _fb: FormBuilder,
    private _router: Router,
    private _api: ApiService,
    private _toastr: ToastrService
  ) {
    this.forgotForm = this._fb.group({});
  }

  ngOnInit() {
    this.controls = [
      {
        id: 'email',
        label: 'Email',
        controlType: 'text',
        type: 'email',
        validators: ['required', 'email']
      }
    ];
    this._formService.addControls(this.controls, this.forgotForm);
  }

  reset(data: any) {
    this.loading = true;
    const reset = {
      action: 'sendResetPwd',
      value: data
    };
    const options: ApiOptions = new ApiOptions({
      endpoint: 'authManagement'
    });
    this._api
      .create(options, reset)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        resp => {
          this.loading = false;
          this._toastr.success(
            'Please check your email account for your password reset link.',
            'Success'
          );
          this._router.navigate([{ outlets: { popup: null } }]);
        },
        err => {
          this.loading = false;
        }
      );
  }

  ngOnDestroy() {}
}
