export interface State {
  deadlines: {
    order_deadline: Date;
    delivery_deadline: Date;
  };
}

export const initialState: State = {
  deadlines: {
    order_deadline: null,
    delivery_deadline: null
  }
};
