import { Component, Input } from '@angular/core';
import { BottomBarButton } from '@app/core/layout/layout.service';

@Component({
  selector: 'app-bottom-bar-button',
  templateUrl: './bottom-bar-button.component.html',
  styleUrls: ['./bottom-bar-button.component.scss']
})
export class BottomBarButtonComponent {
  @Input() options: BottomBarButton;
}
