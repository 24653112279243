import { Injectable, ElementRef } from '@angular/core';
import { PlatformService } from './platform.service';
import { MediaMatcher, BreakpointObserver } from '@angular/cdk/layout';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private _dpr = 1;
  private _orientation: string;
  constructor(
    private _ps: PlatformService,
    private _mediaMatcher: MediaMatcher,
    private _sanitizer: DomSanitizer,
    private _breakpointObserver: BreakpointObserver
  ) {
    const layoutChanges = this._breakpointObserver.observe([
      '(orientation: portrait)',
      '(orientation: landscape)',
    ]);

    layoutChanges.subscribe((result) => {
      this._orientation = result.breakpoints['(orientation: portrait)']
        ? 'portrait'
        : 'landscape';
    });
  }

  backgroundImage(id: string, el: ElementRef, color?: string): SafeStyle {
    if (this._ps.isBrowser) {
      let imageUrl = `url("${
        environment.image_cdn
      }/${id}?width=${this._getWidth(el)}")`;
      if (this._orientation === 'portrait') {
        imageUrl = `url("${
          environment.image_cdn
        }/${id}?height=${this._getHeight(el)}")`;
      }

      return this._sanitizer.bypassSecurityTrustStyle(
        color ? `${color},${imageUrl}` : imageUrl
      );
    } else {
      const imageUrl = `url("${environment.image_cdn}/${id}?width=600")`;
      return this._sanitizer.bypassSecurityTrustStyle(
        color ? `${color},${imageUrl}` : imageUrl
      );
    }
  }

  setDpr() {
    const highRes = this._mediaMatcher.matchMedia(
      'only screen and (-webkit-min-device-pixel-ratio: 3), ' +
        'only screen and (min-resolution: 3dppx), ' +
        'only screen and (min-resolution: 288dpi)'
    ).matches;
    const mediumRes = this._mediaMatcher.matchMedia(
      'only screen and (-webkit-min-device-pixel-ratio: 1.5), ' +
        'only screen and (min-resolution: 1.5dppx), ' +
        'only screen and (min-resolution: 144dpi)'
    ).matches;
    this._dpr = highRes || mediumRes ? 2 : 1;
  }

  private _getWidth(el: ElementRef): number {
    const width = this._ps.isBrowser
      ? 100 * Math.ceil(el.nativeElement.clientWidth / 100)
      : 600;

    return width * this._dpr;
  }

  private _getHeight(el: ElementRef): number {
    const height = this._ps.isBrowser
      ? 100 * Math.ceil(el.nativeElement.clientHeight / 100)
      : 900;

    return height * this._dpr;
  }
}
