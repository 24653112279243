import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Renderer2,
  OnDestroy,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Image } from '@global/models/image';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { PlatformService } from '@app/core/services/platform.service';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() images: Image[];
  @Input() index = 0;
  sliderConfig: SwiperConfigInterface = {
    slidesPerView: 1,
    spaceBetween: 0,
    initialSlide: this.index,
    loop: true,
    preventClicks: false,
    preventClicksPropagation: false,
    autoHeight: true,
    centeredSlides: true,
    slideNextClass: '.swiper-button-next',
    slidePrevClass: '.swiper-button-prev',
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };
  @ViewChild(SwiperDirective) directiveRef?: SwiperDirective;
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();
  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent): void {
    if (event.target === this._elementRef.nativeElement) {
      this.closeModal(event);
    }
  }
  constructor(
    private _ps: PlatformService,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private _layout: LayoutService
  ) {}

  ngOnInit() {
    if (this._ps.isBrowser) {
      this._renderer.addClass(this._ps.getNativeDocument().body, 'modal-open');
    }
  }

  ngAfterViewInit() {
    this.sliderConfig.initialSlide = this.index;
    this.directiveRef.update();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.images && !changes.images.firstChange) {
      this.directiveRef.update();
    }
  }

  closeModal($event) {
    this.modalClose.next($event);
    this._layout.images = [];
    this._layout.indexImage = 0;
  }

  ngOnDestroy() {
    if (this._ps.isBrowser) {
      this._renderer.removeClass(
        this._ps.getNativeDocument().body,
        'modal-open'
      );
    }
  }
}
