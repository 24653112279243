import { NgModule } from '@angular/core';
import { ContactComponent } from './contact/contact.component';
import { FranchiseComponent } from './franchise/franchise.component';
import { PopupComponent } from './popup/popup.component';
import { RecruitmentComponent } from './recruitment/recruitment.component';
import { SharedModule } from '@app/shared/shared.module';
import { PrivacyComponent } from './privacy/privacy.component';
import { PageViewComponent } from './page-view/page-view.component';
import { PageRoutingModule } from './page-routing.module';

@NgModule({
  declarations: [
    ContactComponent,
    FranchiseComponent,
    PopupComponent,
    RecruitmentComponent,
    PrivacyComponent,
    PageViewComponent,
  ],
  imports: [SharedModule, PageRoutingModule],
})
export class PagesModule {}
