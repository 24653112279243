import { Component, OnInit } from '@angular/core';
import { LayoutService } from '@app/core/layout/layout.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  constructor(private _layoutService: LayoutService) {}

  ngOnInit() {
    this._layoutService.backgroundImage$.next(
      'img/page/CBCA_PopUpEventHeader_1920x900.jpg'
    );
    this._layoutService.headerClass$.next('hero');
  }
}
