import { Injectable } from '@angular/core';

import { Observable, of as observableOf, EMPTY } from 'rxjs';
import { Action } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
  map,
  mergeMap,
  tap,
  catchError,
  startWith,
  concatMap,
  filter,
} from 'rxjs/operators';
import * as featureActions from './actions';
import { CookieService } from '@gorniv/ngx-universal';
import { Order } from '@global/models/order';
import { ApiService, ApiOptions } from '@app/core/services/api.service';
import { Router } from '@angular/router';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { LineItem } from '@global/models/line-item';
import { Country } from '@global/models/country';

@Injectable()
export class CartEffects {
  
  getCountries$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.LoadCart>(featureActions.ActionTypes.LOAD_CART),
    startWith(new featureActions.LoadCart()),
    concatMap((action: Action) => {
      if (!action) {
        return EMPTY;
      }
      const options: ApiOptions = new ApiOptions({
        endpoint: 'country',
        query: {
          $select: ['name', 'cca2'],
          $sort: ['name'],
        },
      });
      return this.api.find(options).pipe(
        map(
          (countries: Country[]) => new featureActions.SetCountries(countries)
        ),
        catchError((error) =>
          observableOf(new featureActions.ErrorCart({ error }))
        )
      );
    })
  ));

  
  loadCart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.LoadCart>(featureActions.ActionTypes.LOAD_CART),
    startWith(this.hasToken() ? new featureActions.LoadCart() : null),
    concatMap((action: Action) => {
      if (!action) {
        return EMPTY;
      }
      const options: ApiOptions = new ApiOptions({
        endpoint: 'cart',
      });
      return this.api.find(options).pipe(
        map((order: Order) => new featureActions.LoadOrder(order)),
        catchError((error) =>
          observableOf(new featureActions.ErrorCart({ error }))
        )
      );
    })
  ));

  
  addBooking$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.AddBooking>(featureActions.ActionTypes.ADD_BOOKING),
    map((action) => action.payload),
    mergeMap((data) => {
      const lesson = {
        ...data,
        type: 'lesson',
      };
      const options: ApiOptions = new ApiOptions({
        endpoint: 'lesson-booking',
      });
      return this.api.create(options, lesson).pipe(
        map((resp: Order) => new featureActions.AddToCart(resp)),
        tap((resp) => this.itemAdded(resp, lesson)),
        catchError((error) =>
          observableOf(new featureActions.ErrorCart({ error }))
        )
      );
    })
  ));

  
  updateBooking$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.UpdateBooking>(
      featureActions.ActionTypes.UPDATE_BOOKING
    ),
    mergeMap((action) => {
      const options: ApiOptions = new ApiOptions({
        endpoint: 'lesson-booking',
      });
      return this.api.update(action.id, options, action.payload).pipe(
        map((resp: Order) => new featureActions.UpdateCart(resp)),
        tap((resp) => {
          this.itemAdded(resp);
          this.bookingUpdated(resp, action.id, action.payload);
        }),
        catchError((error) =>
          observableOf(new featureActions.ErrorCart({ error }))
        )
      );
    })
  ));

  
  addOneToOneBooking$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.AddOneToOneBooking>(featureActions.ActionTypes.ADD_ONE_TO_ONE_BOOKING),
    map((action) => action.payload),
    mergeMap((data) => {
      const lesson = {
        ...data,
        type: 'one-to-one',
      };
      const options: ApiOptions = new ApiOptions({
        endpoint: 'one-to-one-booking',
      });
      return this.api.create(options, lesson).pipe(
        map((resp: Order) => new featureActions.AddToCart(resp)),
        tap((resp) => this.itemAdded(resp, lesson)),
        catchError((error) =>
          observableOf(new featureActions.ErrorCart({ error }))
        )
      );
    })
  ));

  
  updateOneToONeBooking$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.UpdateOneToOneBooking>(
      featureActions.ActionTypes.UPDATE_ONE_TO_ONE_BOOKING
    ),
    mergeMap((action) => {
      const options: ApiOptions = new ApiOptions({
        endpoint: 'one-to-one-booking',
      });
      return this.api.update(action.id, options, action.payload).pipe(
        map((resp: Order) => new featureActions.UpdateCart(resp)),
        tap((resp) => {
          this.itemAdded(resp);
          this.oneToOneBookingUpdated(resp, action.id, action.payload);
        }),
        catchError((error) =>
          observableOf(new featureActions.ErrorCart({ error }))
        )
      );
    })
  ));

  
  addToMembership$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.AddMembership>(
      featureActions.ActionTypes.ADD_MEMBERSHIP
    ),
    map((action) => action.payload),
    mergeMap((data) => {
      const membership = {
        ...data,
        type: 'membership',
      };
      const options: ApiOptions = new ApiOptions({
        endpoint: 'cart/item',
      });
      return this.api.create(options, membership).pipe(
        map((resp: Order) => new featureActions.AddToCart(resp)),
        tap((resp) => this.itemAdded(resp, membership)),
        catchError((error) =>
          observableOf(new featureActions.ErrorCart({ error }))
        )
      );
    })
  ));

  
  updateMembershipInCart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.UpdateMembership>(
      featureActions.ActionTypes.UPDATE_MEMBERSHIP
    ),
    mergeMap((action) => {
      const membership = {
        ...action.payload,
        type: 'membership',
      };
      const options: ApiOptions = new ApiOptions({
        endpoint: 'cart/item',
      });
      return this.api.update(action.id, options, membership).pipe(
        map((resp: Order) => new featureActions.UpdateCart(resp)),
        tap((resp) => {
          this.itemAdded(resp);
          this.membershipUpdated(resp, membership);
        }),
        catchError((error) =>
          observableOf(new featureActions.ErrorCart({ error }))
        )
      );
    })
  ));

  
  addToCart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.AddItem>(featureActions.ActionTypes.ADD_ITEM),
    map((action) => action.payload),
    mergeMap((data: any) => {
      const product = {
        ...data,
        type: 'product',
      };
      const options: ApiOptions = new ApiOptions({
        endpoint: 'cart/item',
      });

      return this.api.create(options, product).pipe(
        map((resp: Order) => new featureActions.AddToCart(resp)),
        tap((resp) => this.itemAdded(resp, product)),
        catchError((error) =>
          observableOf(new featureActions.ErrorCart({ error }))
        )
      );
    })
  ));

  
  updateItemInCart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.UpdateItem>(featureActions.ActionTypes.UPDATE_ITEM),
    mergeMap((action) => {
      const options: ApiOptions = new ApiOptions({
        endpoint: 'cart/item',
      });
      return this.api.update(action.id, options, action.payload).pipe(
        map((resp: Order) => new featureActions.UpdateCart(resp)),
        tap((resp) => this.itemUpdated(resp, action.id, action.payload)),
        catchError((error) =>
          observableOf(new featureActions.ErrorCart({ error }))
        )
      );
    })
  ));

  
  removeItemFromCart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.RemoveItem>(featureActions.ActionTypes.REMOVE_ITEM),
    map((action) => action.payload),
    mergeMap((payload) => {
      const apiOptions: ApiOptions = new ApiOptions({
        endpoint: 'cart/item',
        loader: false,
      });
      return this.api.remove(payload._id, apiOptions).pipe(
        map((resp: Order) => new featureActions.RemoveFromCart(resp)),
        tap(() => this.itemRemoved(payload)),
        catchError((error) =>
          observableOf(new featureActions.ErrorCart({ error }))
        )
      );
    })
  ));

  
  completeCart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.CartCompleted>(
      featureActions.ActionTypes.COMPLETE_CART
    ),
    map((action) => action),
    map(() => new featureActions.ClearCart()),
    tap(() => this.deleteToken()),
    catchError((error) => observableOf(new featureActions.ErrorCart({ error })))
  ));

  
  errorCartExpired$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.ErrorCart>(featureActions.ActionTypes.ERROR_CART),
    map((action) => action.payload.error),
    filter(
      (error: any) =>
        error.error &&
        error.status === 400 &&
        error.error.data &&
        error.error.data.reason &&
        error.error.data.reason === 'cart_expired'
    ),
    map(() => new featureActions.ClearCart()),
    tap(() => this.deleteToken()),
    catchError((error) => observableOf(new featureActions.ErrorCart({ error })))
  ));

  
  clearCart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<featureActions.ClearCart>(featureActions.ActionTypes.CLEAR_CART),
    map((action) => action),
    tap(() => this.deleteToken())
  ), {
    dispatch: false,
  });

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private _cookieService: CookieService,
    private _router: Router,
    private _analytics: AnalyticsService
  ) { }

  private itemAdded(resp: any, data?: any) {
    if (resp.payload.token) {
      this.setToken(resp.payload.token);
    }
    this._router.navigate(['/checkout']);
    if (resp.payload && resp.payload.items && data && data.type) {
      if (data.type === 'product') {
        const getItem: LineItem = resp.payload.items.find(
          (i: LineItem) =>
            i.product_variation &&
            i.product_variation._id === data.product_variation
        );
        if (getItem) {
          this._analytics.addToCart(
            'addToCart',
            'Add to Cart',
            getItem.qty,
            getItem.product_variation
          );
        }
      }
      if (data.type === 'lesson') {
        const getItem: LineItem = resp.payload.items.find(
          (i: LineItem) =>
            i.lesson_booking &&
            i.lesson_booking.timeslot &&
            i.lesson_booking.timeslot._id === data.timeslot
        );
        if (getItem) {
          this._analytics.addToCart(
            'addToCart',
            'Add to Cart',
            getItem.qty,
            null,
            getItem.lesson_booking
          );
        }
      }
      if (data.type === 'one-to-one') {
        const getItem: LineItem = resp.payload.items.find(
          (i: LineItem) =>
            i.one_to_one_booking &&
            i.one_to_one_booking.one_to_one &&
            i.one_to_one_booking.one_to_one._id === data.one_to_one
        );
        if (getItem) {
          this._analytics.addToCart(
            'addToCart',
            'Add to Cart',
            getItem.qty,
            null,
            null,
            null,
            getItem.one_to_one_booking
          );
        }
      }
      if (data.type === 'membership') {
        const getItem: LineItem = resp.payload.items.find(
          (i: LineItem) => i.type && i.type === 'membership'
        );
        if (getItem) {
          this._analytics.addToCart(
            'addToCart',
            'Add to Cart',
            getItem.qty,
            null,
            null,
            true
          );
        }
      }
    }
  }

  private itemUpdated(resp: any, id: string, item: LineItem) {
    if (resp.payload && resp.payload.items) {
      const getItem = resp.payload.items.find((i) => i._id === id);
      this.updateItem(getItem, item.previous_qty);
    }
  }

  private bookingUpdated(resp: any, id: string, item: any) {
    if (resp.payload && resp.payload.items) {
      const getItem = resp.payload.items.find(
        (i: LineItem) => i.lesson_booking && i.lesson_booking._id === id
      );
      this.updateItem(getItem, item.previous_qty);
    }
  }

  private oneToOneBookingUpdated(resp: any, id: string, item: any) {
    if (resp.payload && resp.payload.items) {
      const getItem = resp.payload.items.find(
        (i: LineItem) => i.one_to_one_booking && i.one_to_one_booking._id === id
      );
      this.updateItem(getItem, item.previous_qty);
    }
  }

  private membershipUpdated(resp: any, item: any) {
    if (resp.payload && resp.payload.items) {
      const getItem = resp.payload.items.find(
        (i: LineItem) => i.type && i.type === 'membership'
      );
      this.updateItem(getItem, item.previous_qty);
    }
  }

  private updateItem(item: LineItem, previous_qty: number) {
    if (item) {
      let add_qty: number;
      let event: string;
      let action: string;

      if (previous_qty === item.qty) {
        return;
      } else if (previous_qty < item.qty) {
        event = 'addToCart';
        action = 'Add to Cart';
        add_qty = item.qty - previous_qty;
      } else {
        event = 'removeFromCart';
        action = 'Remove from Cart';
        add_qty = previous_qty - item.qty;
      }
      this._analytics.addToCart(
        event,
        action,
        add_qty,
        item.product_variation,
        item.lesson_booking,
        item.type === 'membership' ? true : false
      );
    }
  }

  private itemRemoved(item: LineItem) {
    this._analytics.addToCart(
      'removeFromCart',
      'Remove from Cart',
      item.qty,
      item.product_variation,
      item.lesson_booking,
      item.type === 'membership' ? true : false,
      item.one_to_one_booking
    );
  }

  private hasToken() {
    return this._cookieService.get('cart-token');
  }

  private setToken(token: string) {
    this._cookieService.put('cart-token', token, {
      expires: new Date(Date.now() + 365 * 86400000),
    });
  }

  private deleteToken() {
    this._cookieService.remove('cart-token');
  }
}
