import { Directive, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@app/root-store';
import { StateStoreActions } from '@app/root-store/state-store';

@Directive({
  selector: '[appMenuClick]'
})
export class MenuClickDirective {
  @HostListener('click', ['$event.target']) onClick(btn) {
    this._store.dispatch(new StateStoreActions.MenuOpen(false));
  }
  constructor(private _store: Store<RootStoreState.State>) {}
}
