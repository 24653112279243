<div class="container">
  <div class="footer-menus">
    <div class="menu" [ngClass]="{'first': first}" *ngFor="let menu of menus; let first = first">
      <app-title-bar [barStyle]="{'padding-bottom': '0', 'padding-top': '0'}">
        <h4>{{menu.title}}</h4>
      </app-title-bar>
      <nav [ngClass]="{'inline': menu.inline}">
        <ul>
          <li *ngFor="let item of menu.children">
            <a [routerLink]="[item.url]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
              true}" [attr.aria-label]="item.title" *ngIf="!item.external;else externalLink">
              <ng-container *ngIf="item.icon; else noIcon">
                <svg class="icon">
                  <use [attr.xlink:href]="'/assets/img/sprite.svg?v1.7#' + item.icon"></use>
                </svg>
              </ng-container>
              <ng-template #noIcon>{{item.title}}</ng-template>
            </a>
            <ng-template #externalLink>
              <a [href]="item.url" target="_blank" rel="noopener" [attr.aria-label]="item.title">
                <ng-container *ngIf="item.icon; else noIcon">
                  <svg class="icon">
                    <use [attr.xlink:href]="'/assets/img/sprite.svg?v1.7#' + item.icon"></use>
                  </svg>
                </ng-container>
                <ng-template #noIcon>{{item.title}}</ng-template>
              </a>
            </ng-template>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
<div class="copyright">
  <div class="container">
    <p>© Clancy Briggs Cycling Academy {{year}}. All Rights Reserved. Website designed & maintained by <a class="link"
        href="https://www.firstlight.studio/" target="_blank" rel="nofollow noopener">First Light Creative</a></p>
  </div>
</div>