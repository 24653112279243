import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ICustomControl } from '@global/models/fields';
import { FormService } from '@app/core/services/form.service';
import { Store, select } from '@ngrx/store';
import { RootStoreState } from '@app/root-store';
import {
  AuthStoreActions,
  AuthStoreSelectors,
} from '@app/root-store/auth-store';
import { Observable } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { withLatestFrom, map, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ModalComponent } from '@app/shared/components/modal/modal.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  controls: ICustomControl[];
  loading$: Observable<boolean>;
  @ViewChild('modal') modal: ModalComponent;
  constructor(
    private _formService: FormService,
    private _fb: FormBuilder,
    private _store: Store<RootStoreState.State>,
    private action$: Actions,
    private _router: Router
  ) {
    this.loading$ = this._store.pipe(
      select(AuthStoreSelectors.selectAuthLoading)
    );
    this.action$
      .pipe(
        ofType<AuthStoreActions.SetUser>(AuthStoreActions.ActionTypes.SET_USER),
        withLatestFrom(this._store.select((state) => state['authStore'])),
        map(([action, auth]) => {
          if (auth && auth.redirectUrl && action.payload) {
            this._router.navigateByUrl(auth.redirectUrl).then(() => {
              this.modal.closeModal({});
            });
          } else {
            this._router.navigate([{ outlets: { popup: null } }]);
          }
          return action;
        }),
        takeUntil(componentDestroyed(this))
      )
      .subscribe();
    this.loginForm = this._fb.group({});
  }

  ngOnInit() {
    this.controls = [
      {
        id: 'email',
        label: 'Email',
        controlType: 'text',
        type: 'email',
        validators: ['required', 'email'],
      },
      {
        id: 'password',
        label: 'Password',
        controlType: 'text',
        type: 'password',
        validators: ['required'],
      },
    ];
    this._formService.addControls(this.controls, this.loginForm);
  }

  login(values: any) {
    this._store.dispatch(new AuthStoreActions.LogIn(values));
  }

  ngOnDestroy() {}
}
