import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { PaymentMethod } from 'ngx-stripe/lib/interfaces/payment-intent';
import { FormService } from '@app/core/services/form.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService, ApiOptions } from '@app/core/services/api.service';
import { ICustomControl } from '@global/models/fields';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-default-payment-method',
  templateUrl: './default-payment-method.component.html',
  styleUrls: ['./default-payment-method.component.scss']
})
export class DefaultPaymentMethodComponent implements OnInit, OnDestroy {
  @Input() paymentMethod: PaymentMethod;
  @Output() paymentUpdated: EventEmitter<PaymentMethod> = new EventEmitter<
    PaymentMethod
  >();
  editForm: FormGroup;
  addressForm: FormGroup;
  editing = false;
  controls: ICustomControl[];
  saving = false;
  constructor(
    private _formService: FormService,
    private _fb: FormBuilder,
    private _api: ApiService
  ) {
    this.addressForm = this._fb.group({});
    this.editForm = this._fb.group({
      address: this.addressForm
    });
  }

  get hasCard() {
    return (
      this.paymentMethod.card.brand !== 'unionpay' &&
      this.paymentMethod.card.brand !== 'unknown'
    );
  }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.controls = [
      {
        id: 'exp_month',
        label: 'Exp Month',
        value: this.paymentMethod ? this.paymentMethod.card.exp_month : null,
        controlType: 'text',
        validators: ['required'],
        errors: {
          required: 'required'
        },
        half_width: true
      },
      {
        id: 'exp_year',
        label: 'Exp Year',
        value: this.paymentMethod ? this.paymentMethod.card.exp_year : null,
        controlType: 'text',
        validators: ['required'],
        errors: {
          required: 'required'
        },
        half_width: true
      }
    ];
    this._formService.addControls(this.controls, this.editForm);
  }

  updateCard(values: any) {
    if (!this.editForm.valid) {
      return;
    }
    this.saving = true;

    const cardData = {
      billing_details: {
        name: values.address.name,
        address: {}
      },
      card: {
        exp_month: values.exp_month,
        exp_year: values.exp_year
      }
    };
    delete values.address.name;
    delete values.address.country_select;
    cardData.billing_details.address = values.address;
    const options: ApiOptions = new ApiOptions({
      endpoint: 'users/payment'
    });
    this._api
      .update(this.paymentMethod.id, options, cardData)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (paymentMethod: PaymentMethod) => {
          this.paymentMethod = paymentMethod;
          this.editing = false;
          this.saving = false;
        },
        err => {
          this.saving = false;
        }
      );
  }

  ngOnDestroy() {}
}
