<app-modal #modal>
  <div class="taster">
    <app-title-bar [center]="true" [barStyle]="{'padding-bottom': '25px', 'padding-top': '20px'}">
      <h2 class="text-center">Taster Sessions</h2>
    </app-title-bar>
    <table class="sessions" cdk-table [dataSource]="sessions$ | async">
      <ng-container cdkColumnDef="session">
        <th cdk-header-cell *cdkHeaderCellDef>Class</th>
        <td data-title="Class" cdk-cell *cdkCellDef="let session">{{session.location.name}} -
          {{session.lesson.title}}
          <br>{{ session.timeslot?.start_date | date: 'EEEE d' }}{{ (session.timeslot?.start_date | date: 'd') | dateSuffix }}
          {{ session.timeslot?.start_date | date: 'MMMM'}}
          {{ session.timeslot?.start_time }}-{{ session.timeslot?.end_time }}</td>
      </ng-container>
      <ng-container cdkColumnDef="child">
        <th cdk-header-cell *cdkHeaderCellDef>Child</th>
        <td data-title="Child" cdk-cell *cdkCellDef="let session">{{session.student?.name}}</td>
      </ng-container>
      <ng-container cdkColumnDef="remove">
        <th cdk-header-cell *cdkHeaderCellDef></th>
        <td data-title="Child" cdk-cell *cdkCellDef="let session"><button class="basket-remove"
            (click)="removeItem(session.timeslot._id, session.student._id)">X</button></td>
      </ng-container>
      <tr cdk-header-row *cdkHeaderRowDef="columnsToDisplay"></tr>
      <tr cdk-row *cdkRowDef="let row; columns: columnsToDisplay;"></tr>
    </table>
    <div class="cart-error complete">
      Once you select complete booking, your selections will be added and you will no longer be able to use the code.
      Please note, you can add 1 session per date for each child.
    </div>
    <div class="btn-group complete">
      <button type="submit" class="btn btn-light-blue center" (click)="modal.closeModal({})">Add more
        sessions</button>
      <button appButton [loading]="loading" type="submit" class="btn btn-red center" (click)="confirmBooking()">Complete
        Booking</button>
    </div>
  </div>
  <!-- <div class="taster-footer">
    <p>YOU WILL RECEIVE AN EMAIL ONCE A SPACE BECOMES AVAILABLE</p>
  </div> -->
</app-modal>
