import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ICustomControl } from '@global/models/fields';
import { environment } from '@env/environment';
import { PlatformService } from '@app/core/services/platform.service';

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss']
})
export class FormGroupComponent {
  siteKey = environment.captcha;
  @Input() center: boolean;
  @Input() form: FormGroup;
  @Input() controls: ICustomControl[];
  constructor(public ps: PlatformService) {}
}
