<ng-container *ngIf="page$ | async as page">
  <app-header-content>
    <h1 class="text-center white">
      Clancy Briggs<span class="large">{{ page.title }}</span>
    </h1>
  </app-header-content>
  <div class="page">
    <div class="container">
      <app-breadcrumb></app-breadcrumb>
      <app-editorjs
        [blocks]="page.editor.blocks"
        *ngIf="page.editor"
      ></app-editorjs>
    </div>
  </div>
</ng-container>
