<div class="content-header" [ngClass]="color" (click)="opened = !opened">
  <div class="wrapper">
    <h4 *ngIf="!hideTitle">
      <ng-content select=".content-title"></ng-content>
    </h4>
    <ng-content select=".content-text"></ng-content>
  </div>
  <svg class="icon" [ngClass]="{'open': opened}">
    <use xlink:href="/assets/img/sprite.svg?v1.7#arrow-collapse-icon"></use>
  </svg>
</div>
<div class="content" [ngClass]="{'hidden': !opened}">
  <ng-content></ng-content>
</div>