<div class="form-field" [class.has-error]="showError">
  <div class="intro" [innerHTML]="to.introText" *ngIf="to.introText"></div>
  <label
    class="field-label"
    *ngIf="to.label && to.hideLabel !== true"
    [attr.for]="id"
  >
    {{ to.label }}
    <span class="asterisk" *ngIf="to.required && to.hideRequiredMarker !== true"
      >&#32;*</span
    >
  </label>
  <div class="field-wrapper" #fieldClick>
    <ng-template #fieldComponent></ng-template>
  </div>
  <p class="hint" *ngIf="to.description">{{ to.description }}</p>
  <a
    class="help-link"
    [routerLink]="['', { outlets: { popup: [to.helpLink.url] } }]"
    [queryParams]="to.helpLink.query"
    *ngIf="to.helpLink"
    >{{ to.helpLink.title }}</a
  >
</div>
