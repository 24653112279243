import { FormlyFieldConfig } from '@ngx-formly/core';
import { Country } from '@global/models/country';

const AddressForm = (
  countries: Country[],
  name = true,
  email = false,
  phone = false
): FormlyFieldConfig[] => {
  let fields = [];
  if (name) {
    fields = [
      ...fields,
      {
        className: 'w-50',
        key: 'first_name',
        type: 'input',
        templateOptions: {
          label: 'First name',
          required: true,
        },
      },
      {
        className: 'w-50',
        key: 'last_name',
        type: 'input',
        templateOptions: {
          label: 'Last name',
          required: true,
        },
      },
    ];
  }

  fields = [
    ...fields,
    {
      key: 'address_line_1',
      type: 'input',
      templateOptions: {
        label: 'House number and street name',
        required: true,
      },
    },
    {
      key: 'address_line_2',
      type: 'input',
      templateOptions: {
        label: 'Apartment, suite, unit etc. (optional)',
      },
    },
    {
      key: 'city',
      type: 'input',
      templateOptions: {
        label: 'Town/City',
        required: true,
      },
    },
    {
      key: 'region',
      type: 'input',
      templateOptions: {
        label: 'County',
      },
    },
    {
      key: 'country',
      type: 'select',
      defaultValue: 'United Kingdom',
      templateOptions: {
        label: 'Country',
        required: true,
        options: countries,
        valueProp: 'name',
        labelProp: 'name',
      },
    },
    {
      key: 'country_code',
      type: 'input',
      hide: true,
      templateOptions: {
        label: 'Country Code',
        required: true,
      },
      expressionProperties: {
        defaultValue: countries
          ? countries.find((c) => c.name === 'model.country')
          : null,
      },
    },
    {
      key: 'postcode',
      type: 'input',
      templateOptions: {
        label: 'Postcode',
        required: true,
      },
    },
  ];

  if (phone) {
    fields.push({
      className: 'w-50',
      key: 'phone',
      type: 'input',
      templateOptions: {
        label: 'Phone',
        type: 'tel',
        required: true,
      },
    });
  }

  if (email) {
    fields.push({
      className: 'w-50',
      key: 'email',
      type: 'input',
      templateOptions: {
        label: 'Email',
        type: 'email',
        required: true,
      },
    });
  }

  return fields as FormlyFieldConfig[];
};
export default AddressForm;
