import {
  Component,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  OnInit,
  OnDestroy,
  Renderer2,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { PlatformService } from '@app/core/services/platform.service';
import { appAnimations } from '@app/animations';
import { ApiService, ApiOptions } from '@app/core/services/api.service';
import { SeoService } from '@app/core/services/seo.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Page } from '@global/models/page';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  exportAs: 'modal',
  animations: [appAnimations],
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() closeText = 'Close';
  @Input() showClose = true;
  @Input() slug: string; // This corresponds to the slug from the page api endpoint
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();
  state: any;
  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent): void {
    if (event.target === this._elementRef.nativeElement && this.showClose) {
      this.closeModal(event);
    }
  }
  constructor(
    private _router: Router,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private _ps: PlatformService,
    private _api: ApiService,
    private _seo: SeoService
  ) {
    const navigation = this._router.getCurrentNavigation();

    if (
      navigation.extras &&
      navigation.extras.state &&
      navigation.extras.state.modelCloseText
    ) {
      this.closeText = navigation.extras.state.modelCloseText;
    }

    if (navigation.extras && navigation.extras.state) {
      this.state = navigation.extras.state;
    }
  }

  ngOnInit() {
    if (this._ps.isBrowser) {
      this._renderer.addClass(this._ps.getNativeDocument().body, 'modal-open');
    }

    if (this.slug) {
      const apiOptions: ApiOptions = new ApiOptions({
        endpoint: 'page',
      });
      this._api
        .findOne(this.slug, apiOptions)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((data: Page) => {
          if (data) {
            this._seo.setMetaAndLinks(
              data.title,
              data.seo,
              null,
              null,
              null,
              null,
              null,
              false
            );
          }
        });
    }
  }

  closeModal($event?: any) {
    this._router.navigate([{ outlets: { popup: null } }], {
      queryParamsHandling: 'merge',
    });
    this.modalClose.next($event);
  }

  ngOnDestroy() {
    if (this._ps.isBrowser) {
      this._renderer.removeClass(
        this._ps.getNativeDocument().body,
        'modal-open'
      );
    }
  }
}
