<div class="swiper-container-outside gallery">
  <div class="swiper-container" [swiper]="sliderConfig">
    <div class="swiper-wrapper">
      <div class="swiper-slide image" *ngFor="let image of images">
        <img [appLazysizes]="image.public_id" />
      </div>
    </div>
  </div>
  <div class="swiper-button-prev"></div>
  <div class="swiper-button-next"></div>
</div>
<button class="btn btn-red center" (click)="closeModal($event)">Close</button>
