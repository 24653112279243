import { NgModule } from '@angular/core';
import { CookieLawComponent } from './cookie-law.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [CookieLawComponent],
  imports: [SharedModule],
  exports: [CookieLawComponent]
})
export class CookieLawModule {}
