import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PlatformService } from '@app/core/services/platform.service';

@Directive({
  selector: '[appFirstInvalid]',
})
export class FirstInvalidDirective {
  @Input() formGroup: NgForm;
  constructor(private _el: ElementRef, private _ps: PlatformService) {}

  static scrollToElement(element) {
    if (element) {
      const distance =
        window.pageYOffset - Math.abs(element.getBoundingClientRect().y);

      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: element.getBoundingClientRect().top + window.scrollY - 250,
      });

      setTimeout(() => {
        element.focus();
        element.blur(); // Trigger error messages
        element.focus();
      }, distance);
    }
  }

  static markFormGroupTouched(formGroup) {
    (Object as any).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        FirstInvalidDirective.markFormGroupTouched(control);
      }
    });
  }

  @HostListener('submit', ['$event'])
  onSubmit(event) {
    event.preventDefault();
    console.log('submit');

    if (!this.formGroup.valid && this._ps.isBrowser) {
      FirstInvalidDirective.markFormGroupTouched(this.formGroup);

      const formControlInvalid = this._el.nativeElement.querySelector(
        '.ng-invalid'
      );

      if (formControlInvalid) {
        return FirstInvalidDirective.scrollToElement(formControlInvalid);
      } else {
        // The first element is the global form and here we are looking for the first nested form
        const formGroupInvalid = this._el.nativeElement.querySelectorAll(
          'form .ng-invalid'
        );
        if (formGroupInvalid && formGroupInvalid.length) {
          return FirstInvalidDirective.scrollToElement(formGroupInvalid[0]);
        }
      }

      return FirstInvalidDirective.scrollToElement(this._el.nativeElement);
    }
  }
}
