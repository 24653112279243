import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { RootStoreState } from '@app/root-store';
import { Session } from '@app/root-store/taster-store/state';
import {
  TasterStoreSelectors,
  TasterStoreActions
} from '@app/root-store/taster-store';
import { Observable } from 'rxjs';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { tap, switchMap, takeUntil } from 'rxjs/operators';
import { ApiService, ApiOptions } from '@app/core/services/api.service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Router } from '@angular/router';

@Component({
  selector: 'app-taster-confirm',
  templateUrl: './taster-confirm.component.html',
  styleUrls: ['./taster-confirm.component.scss']
})
export class TasterConfirmComponent
  implements OnInit, AfterViewInit, OnDestroy {
  sessions$: Observable<Session[]>;
  columnsToDisplay: string[] = ['session', 'child', 'remove'];
  loading = false;
  hasSessions = true;
  @ViewChild('modal') modal: ModalComponent;
  constructor(
    private _store: Store<RootStoreState.State>,
    private _api: ApiService,
    private _router: Router
  ) {
    this.sessions$ = this._store.pipe(
      select(TasterStoreSelectors.selectSessions),
      tap((sessions: Session[]) => {
        if (sessions.length === 0) {
          this.hasSessions = false;
          if (this.modal) {
            this.modal.closeModal({});
          }
        }
      })
    );
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (!this.hasSessions) {
      this.modal.closeModal({});
    }
  }

  confirmBooking() {
    this.loading = true;
    const options: ApiOptions = new ApiOptions({
      endpoint: 'subscription/taster'
    });
    this._store
      .pipe(
        select(TasterStoreSelectors.selectSessions),
        switchMap((sessions: Session[]) =>
          this._api.create(options, { sessions })
        ),
        takeUntil(componentDestroyed(this))
      )
      .subscribe(
        resp => {
          this.loading = false;
          if (resp) {
            this._router.navigate(['account', 'academy']).then(() => {
              this.modal.closeModal({});
            });
          }
        },
        err => {
          this.loading = false;
        }
      );
  }

  removeItem(timeslot: string, student: string) {
    this._store.dispatch(
      new TasterStoreActions.RemoveSession(timeslot, student)
    );
  }

  ngOnDestroy() {}
}
