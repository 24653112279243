import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'textArea'
})
export class TextAreaPipe implements PipeTransform {
  constructor(protected _sanitizer: DomSanitizer) {}
  transform(value: any, args?: any): SafeHtml {
    if (value) {
      value = value
        .replace(/<p>/g, '')
        .replace(new RegExp('</p>$'), '')
        .replace(/<br>/g, '\n')
        .replace(/<\/p>/g, '\n\n');
    }

    return value;
  }
}
