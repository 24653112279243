import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-editorjs',
  templateUrl: './editorjs.component.html',
  styleUrls: ['./editorjs.component.scss'],
})
export class EditorjsComponent {
  @Input() blocks: any[];
  constructor() {}

  getTableHeaders(data: any[]) {
    return data[0];
  }

  getTableRows(data: any[]) {
    const rows = [...data];
    return rows.slice(1);
  }

  columnsToDisplay(data: any[]) {
    return data[0].map((h, i) => `col${i}`);
  }
}
