<app-modal #modal>
  <div class="reset">
    <app-title-bar [center]="true" [barStyle]="{'padding-bottom': '25px', 'padding-top': '20px'}">
      <h2 class="text-center">Reset Password</h2>
    </app-title-bar>
    <form [formGroup]="resetForm" (ngSubmit)="reset(resetForm.value)" appFirstInvalid>
      <app-form-group class="w-100-p" [form]="resetForm" [controls]="controls"></app-form-group>
      <button appButton [loading]="loading" type="submit" class="btn btn-light-blue center">Change
        password</button>
    </form>
  </div>
</app-modal>
