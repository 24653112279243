import {
  MemoizedSelector,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import { State } from './state';

const getMenuOpen = (state: State) => state.menuOpen;

export const selectStateStoreState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('stateStore');

export const selectMenuOpen: MemoizedSelector<object, boolean> = createSelector(
  selectStateStoreState,
  getMenuOpen
);
