import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageResolver } from '@app/core/services/page.resolver';

import { PageViewComponent } from './page-view/page-view.component';

const routes: Routes = [
  {
    path: ':slug',
    component: PageViewComponent,
    data: {
      breadcrumbs: '{{ page.title }}',
    },
    resolve: {
      page: PageResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [PageResolver],
})
export class PageRoutingModule { }
