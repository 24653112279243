import { Order } from '@global/models/order';
import { Country } from '@global/models/country';

export interface State {
  loading: boolean;
  order: Order;
  cartQty: number;
  order_email: string;
  countries: Country[];
  error?: any;
}

export const initialState: State = {
  loading: false,
  order: null,
  cartQty: 0,
  order_email: null,
  countries: [],
  error: null,
};
