import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-input-mask',
  templateUrl: './input-mask.component.html',
  styleUrls: ['./input-mask.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputMaskComponent extends FieldType {
  get type() {
    return this.to.type || 'text';
  }
}
