import { Component, OnInit } from '@angular/core';
import { footerMenus } from '@app/core/footer-menu';

@Component({
  selector: 'app-footer, [app-footer]',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  menus = footerMenus;
  year = new Date().getFullYear();
  constructor() { }

  ngOnInit() { }
}
