<app-modal #modal [slug]="'forgot'">
  <div class="forgot">
    <app-title-bar
      [center]="true"
      [barStyle]="{ 'padding-bottom': '25px', 'padding-top': '20px' }"
    >
      <h2 class="text-center">Forgot</h2>
    </app-title-bar>
    <form [formGroup]="forgotForm" (ngSubmit)="reset(forgotForm.value)">
      <app-form-group
        class="w-100-p"
        [form]="forgotForm"
        [controls]="controls"
      ></app-form-group>
      <button
        appButton
        [loading]="loading"
        type="submit"
        class="btn btn-light-blue center"
      >
        Reset password
      </button>
    </form>
  </div>
</app-modal>
