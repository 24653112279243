<div class="filter" [class.show]="opened">
  <div class="filter__header">
    <div class="filter__button" (click)="showFilter()">
      <h4>{{ title }}</h4>
      <img class="icon" src="/assets/svg/filter-icon.svg" />
    </div>
    <div class="filter__search">
      <h4>SEARCH</h4>
      <button class="filter__search__btn" (click)="searchFilter()" appButton>
        <svg class="icon">
          <use xlink:href="/assets/img/sprite.svg?v1.7#search-icon"></use>
        </svg>
      </button>
      <input class="filter__search__input" type="text" id="search" name="search" [(ngModel)]="searchInput"
        (keydown.enter)="searchFilter()" />
    </div>
  </div>
  <div class="filter__content" [hidden]="!opened">
    <ng-content></ng-content>
  </div>
</div>