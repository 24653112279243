<app-header-content>
  <h1 class="text-center white"><span class="large">Franchise</span></h1>
</app-header-content>
<div class="page">
  <div class="container">
    <app-title-bar [center]="true">
      <h2 class="text-center">Become a Clancy Briggs Cycling Academy</h2>
    </app-title-bar>
  </div>
  <div class="form-block">
    <div class="container">
      <app-title-bar [center]="true">
        <h2 class="text-center h1 large">We will be launching our franchises soon</h2>
      </app-title-bar>
      <p class="text-center">Do you share our vision? Would you like to run a Clancy Briggs Cycling Academy of your own?
        We're looking for entrepeneurial people with the hands on skills to deliver great coaching to be our partners in
        the growth of the Clancy Briggs Cycling Academy. If that sounds like you, let us know via the form below and
        we'll be in touch to talk more.</p>
      <p class="text-center">Fill out the form below to express your interest in running one of our franchises.</p>
      <app-contact-form [location]="true" type="franchise" [center]="true"></app-contact-form>
    </div>
  </div>
</div>
