import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingService } from '@app/core/services/loading.service';
import { environment } from '@env/environment';
import { finalize } from 'rxjs/operators';
import { InterceptorSkipHeader } from './jwt-interceptor';

interface IApiOptions {
  endpoint: string;
  query?: any;
  loader?: boolean;
  message?: string;
  skip?: boolean;
}

export class ApiOptions implements IApiOptions {
  host: string = environment.host;
  endpoint: string;
  query?: any;
  loader?: boolean;
  message?: string;
  skip?: boolean;
  constructor(data: IApiOptions) {
    this.endpoint = data.endpoint;
    this.query = data.query;
    this.loader = data.loader === undefined ? true : data.loader;
    this.message = data.message;
    this.skip = data.skip;
  }

  get url() {
    return `${this.host}/${this.endpoint}`;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(public http: HttpClient, public loading: LoadingService) {}

  find(options: ApiOptions) {
    this.showLoader(options.loader);

    const headers = options.skip
      ? new HttpHeaders().set(InterceptorSkipHeader, '')
      : {};

    return this.http
      .get<any>(options.url, { params: options.query, headers })
      .pipe(finalize(() => this.hideLoader()));
  }

  findOne(id: number | string, options: ApiOptions) {
    this.showLoader(options.loader);
    return this.http
      .get<any>(`${options.url}/${id}`, { params: options.query })
      .pipe(finalize(() => this.hideLoader()));
  }

  create(options: ApiOptions, data: any) {
    this.showLoader(options.loader, options.message);
    return this.http
      .post<any>(options.url, data, { params: options.query })
      .pipe(finalize(() => this.hideLoader()));
  }

  update(id: number | string, options: ApiOptions, data: any) {
    this.showLoader(options.loader);
    return this.http
      .patch<any>(`${options.url}/${id}`, data, { params: options.query })
      .pipe(finalize(() => this.hideLoader()));
  }

  remove(id: number | string, options: ApiOptions) {
    this.showLoader(options.loader);
    return this.http
      .delete<any>(`${options.url}/${id}`, { params: options.query })
      .pipe(finalize(() => this.hideLoader()));
  }

  private showLoader(loader: boolean, message?: string): void {
    if (loader) {
      this.loading.setLoading(loader, message);
    }
  }

  private hideLoader(): void {
    this.loading.setLoading(false);
  }
}
