import { Component, OnInit } from '@angular/core';
import { SeoService } from '@app/core/services/seo.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  constructor(private _seo: SeoService) {}
  ngOnInit() {
    this._seo.setMetaAndLinks('Page Not Found');
  }
}
