export const footerMenus: any = [
  {
    title: 'Clancy Briggs',
    children: [
      {
        title: 'About',
        url: '/about',
      },
      {
        title: 'Academy',
        url: '/academy',
      },
      {
        title: 'Coaching',
        url: '/coaching',
      },
      {
        title: 'Membership',
        url: '/membership',
      },
      {
        title: 'Shop',
        url: '/shop',
      },
      {
        title: 'Blog',
        url: '/blog',
      },
      {
        title: 'Cycling Resources',
        url: '/cycling-resources',
      },
      {
        title: 'Basket',
        url: '/checkout',
      },
      {
        title: 'Donate',
        url: '/donate',
      },
      {
        title: 'Account',
        url: '/account',
      },
      {
        title: 'Contact',
        url: '/contact',
      },
    ],
  },
  {
    title: 'Information',
    children: [
      {
        title: 'Find A Class',
        url: '/academy/find-a-class',
      },
      {
        title: 'Franchises',
        url: '/franchises',
      },
      {
        title: 'Pop Up Events',
        url: '/pop-up-events',
      },
      {
        title: 'Recruitment',
        url: '/recruitment',
      },
      {
        title: `Terms of Use`,
        url: '/terms-of-use',
      },
      {
        title: `Privacy Policy`,
        url: '/privacy-policy',
      },
      {
        title: 'FAQs',
        url: '/faqs',
      },
    ],
  },
  {
    title: 'Follow Us',
    inline: true,
    children: [
      {
        title: `Facebook`,
        url: 'https://www.facebook.com/clancybriggsUK/',
        external: true,
        icon: 'facebook',
      },
      {
        title: `Twitter`,
        url: 'https://twitter.com/clancybriggs',
        external: true,
        icon: 'twitter',
      },
      {
        title: `Instagram`,
        url: 'https://www.instagram.com/clancybriggsuk/',
        external: true,
        icon: 'insta',
      },
    ],
  },
];
