<span class="dropdown-select">
  <select
    [formControl]="formControl"
    [compareWith]="to.compareWith"
    [class.custom-select]="to.customSelect"
    [class.is-invalid]="showError"
    [formlyAttributes]="field"
  >
    <option *ngIf="to.placeholder" [ngValue]="undefined">{{
      to.placeholder
    }}</option>
    <ng-container
      *ngIf="to.options | formlySelectOptions: field | async as opts"
    >
      <ng-container *ngIf="to._flatOptions; else grouplist">
        <ng-container *ngFor="let opt of opts">
          <option [ngValue]="opt.value" [disabled]="opt.disabled">{{
            opt.label
          }}</option>
        </ng-container>
      </ng-container>
      <ng-template #grouplist>
        <ng-container *ngFor="let opt of opts">
          <option
            *ngIf="!opt.group; else optgroup"
            [ngValue]="opt.value"
            [disabled]="opt.disabled"
            >{{ opt.label }}</option
          >
          <ng-template #optgroup>
            <optgroup [label]="opt.label">
              <option
                *ngFor="let child of opt.group"
                [ngValue]="child.value"
                [disabled]="child.disabled"
              >
                {{ child.label }}
              </option>
            </optgroup>
          </ng-template>
        </ng-container>
      </ng-template>
    </ng-container></select
  >
</span>
