<app-modal #modal>
  <ng-container *ngIf="page$ | async as page">
    <div class="page">
      <app-title-bar
        [center]="true"
        [barStyle]="{ 'padding-bottom': '25px', 'padding-top': '20px' }"
      >
        <h2 class="text-center">{{ page.title }}</h2>
      </app-title-bar>

      <app-editorjs
        [blocks]="page.editor.blocks"
        *ngIf="page.editor"
      ></app-editorjs>
    </div>
  </ng-container>
</app-modal>
