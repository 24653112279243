import { ClassSearch } from '@global/models/class-search';

export interface State {
  classSearch: ClassSearch;
}

export const initialState: State = {
  classSearch: {
    query: '',
    lat: null,
    lng: null,
    town: null,
    postcode: null
  }
};
