import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ICustomControl } from '@global/models/fields';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { FormService } from '@app/core/services/form.service';
import { ApiService, ApiOptions } from '@app/core/services/api.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ACCELERATE_FREQUENCY } from '@global/data';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-accelercelerate-booking',
  templateUrl: './accelercelerate-booking.component.html',
  styleUrls: ['./accelercelerate-booking.component.scss'],
})
export class AccelercelerateBookingComponent implements OnInit, OnDestroy {
  form: FormGroup;
  controls: ICustomControl[];
  loading: boolean;
  @ViewChild('modal') modal: ModalComponent;
  constructor(
    private _fb: FormBuilder,
    private _formService: FormService,
    private _toastr: ToastrService,
    private _api: ApiService
  ) {
    this.form = this._fb.group({});
  }

  ngOnInit() {
    this.controls = [
      {
        id: 'postcode',
        label: 'Postcode',
        controlType: 'text',
        validators: ['required'],
      },
      {
        id: 'email',
        label: 'Email',
        controlType: 'text',
        type: 'email',
        validators: ['required', 'email'],
      },
      {
        id: 'phone',
        label: 'Phone',
        controlType: 'text',
        type: 'tel',
        validators: ['required', 'minLength'],
        minLength: 10,
      },
      {
        id: 'name',
        label: 'Your name',
        controlType: 'text',
        validators: ['required'],
      },
      {
        id: 'child_name',
        label: 'Childs name',
        controlType: 'text',
        validators: ['required'],
      },
      {
        id: 'child_age',
        label: 'Childs age',
        controlType: 'text',
        validators: ['required'],
      },
      {
        id: 'frequency',
        label: 'Lesson type',
        controlType: 'select',
        options: ACCELERATE_FREQUENCY,
        optionLabel: 'title',
        optionValue: 'value',
        validators: ['required'],
      },
      {
        id: 'availability',
        label: 'Availability',
        controlType: 'textarea',
        validators: ['required'],
        hintText:
          'Please provide us with the days and times that would be most convenient for you and your child.',
      },
    ];
    this._formService.addControls(this.controls, this.form);
  }

  save() {
    this.loading = true;
    const options: ApiOptions = new ApiOptions({
      endpoint: 'message',
    });
    const values = this.form.value;
    values.type = 'accelerate';
    this._api
      .create(options, this.form.value)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        (resp) => {
          this.loading = false;
          this._toastr.success(
            'Thanks for your interest in Clancy Briggs.',
            'Request sent'
          );
          this.modal.closeModal({});
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  ngOnDestroy() {}
}
