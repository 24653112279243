import {
  MemoizedSelector,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import { State } from './state';
import { ClassSearch } from '@global/models/class-search';

const getClassSearch = (state: State) => state.classSearch;

export const selectRootStoreState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('academyStore');

export const selectClassSearch: MemoizedSelector<
  object,
  ClassSearch
> = createSelector(
  selectRootStoreState,
  getClassSearch
);
