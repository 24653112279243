import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy
} from '@angular/core';
import { Observable, timer, of, combineLatest, Subject } from 'rxjs';
import {
  switchMap,
  takeUntil,
  tap,
  debounceTime,
  distinctUntilChanged
} from 'rxjs/operators';

import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds
} from 'date-fns';
import { isBefore, addDays, addHours, addMinutes } from 'date-fns/esm';
import { Store, select } from '@ngrx/store';
import { RootStoreState } from '@app/root-store';
import {
  SettingStoreSelectors,
  SettingStoreActions
} from '@app/root-store/setting-store';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { utcToZonedTime } from 'date-fns-tz';
interface CountDown {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

@Component({
  selector: 'app-order-countdown',
  templateUrl: './order-countdown.component.html',
  styleUrls: ['./order-countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.sticky]': 'sticky'
  }
})
export class OrderCountdownComponent implements OnInit, OnDestroy {
  @Input() sticky: boolean;
  countdown$: Observable<CountDown>;
  order_deadline: Date;
  delivery_deadline: Date;
  get_next = new Subject();
  constructor(private _store: Store<RootStoreState.State>) {
    combineLatest([
      this._store.pipe(
        select(SettingStoreSelectors.selectOrderDeadline),
        tap((date: any) => {
          this.order_deadline = new Date(date);
        })
      ),
      this._store.pipe(
        select(SettingStoreSelectors.selectDeliveryDeadline),
        tap((date: Date) => (this.delivery_deadline = date))
      )
    ])
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe();
    this.get_next
      .pipe(
        distinctUntilChanged(),
        debounceTime(1000),
        takeUntil(componentDestroyed(this))
      )
      .subscribe(() => {
        this._store.dispatch(
          new SettingStoreActions.SettingLoad({ current: this.order_deadline })
        );
      });
  }

  getUTC(date: Date) {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      )
    );
  }

  getTime(): CountDown {
    let now = utcToZonedTime(this.getUTC(new Date()), 'Europe/London');

    const future = this.order_deadline;
    if (isBefore(future, now)) {
      this.get_next.next();
    }

    const time = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };
    let temp: number;
    temp = differenceInDays(future, now);

    time.days = temp > 0 ? temp : 0;
    now = addDays(now, temp);
    temp = differenceInHours(future, now);
    time.hours = temp > 0 ? temp : 0;
    now = addHours(now, temp);
    temp = differenceInMinutes(future, now);
    time.minutes = temp > 0 ? temp : 0;
    now = addMinutes(now, temp);
    temp = differenceInSeconds(future, now);
    time.seconds = temp > 0 ? temp : 0;
    return time;
  }

  ngOnInit() {
    this.countdown$ = timer(0, 1000).pipe(switchMap(() => of(this.getTime())));
    this._store.dispatch(new SettingStoreActions.SettingLoad());
  }

  ngOnDestroy() {}
}
