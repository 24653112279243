export const environment = {
  production: true,
  hmr: false,
  host: 'https://api.clancybriggs.co.uk',
  site: 'https://www.clancybriggs.co.uk',
  admin: 'https://admin.clancybriggs.co.uk',
  cookie: '.clancybriggs.co.uk',
  cookie_key: 'clancy-jwt',
  stripe_key: 'pk_live_PddeCSJ0A8AHGylhwIozUZqE00YB2kbX4B',
  stripe_key_donate: 'pk_live_k7M1gtUg9ooNnbelvUq6GAGq00YOG6glPl',
  spaces_url: 'https://cb-images.ams3.cdn.digitaloceanspaces.com',
  image_cdn: 'https://image.clancybriggs.co.uk',
  captcha: '6LdLp7kUAAAAAIDN7aHcdVsHy62fNV0lre5_hWxp',
};
