<div class="menu-bar">
  <div class="menu-wrapper" [ngClass]="{'in-view': headerInView}" [class]="toggle" [@toggle]="toggle" #menuWrapper>
    <div class="container">
      <div class="main-menu">
        <ng-container *ngTemplateOutlet="mainMenu">
        </ng-container>
      </div>
      <div class="menu-icon">
        <button class="btn-menu" aria-label="Menu" [ngClass]="{ 'menu-open': menuOpen$ | async }"
          (click)="toggleMenu()">
          <div class="btn__hamburger__line"></div>
          <div class="btn__hamburger__line"></div>
          <div class="btn__hamburger__line"></div>
        </button>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="brand-wrapper">
      <div class="brand" *ngIf="!home; else isHome">
        <a [routerLink]=" ['/']" title="Clancy Briggs" appMenuClick>
          <app-logo [toggle]="toggle"></app-logo>
        </a>
      </div>
      <ng-template #isHome>
        <h1 class="brand" appMenuClick>
          <app-logo [toggle]="toggle"></app-logo>
        </h1>
      </ng-template>
    </div>
  </div>
</div>
<div class="mobile-menu" [ngClass]="{ 'menu-open': menuOpen }" *ngIf="(menuOpen$ | async) as menuOpen" @slideInRight>
  <div class="container">
    <ng-container *ngTemplateOutlet="mainMenu">
    </ng-container>
  </div>
</div>
<ng-template #mainMenu>
  <nav>
    <ul>
      <li *ngFor="let item of menus">
        <a class="menu-link" [routerLink]="[item.url]" routerLinkActive="active"
          [target]="item.external ? '_blank' : null" [rel]="item.external ? 'noopener' : null" appMenuClick
          *ngIf="item.url; else noLink">
          <span class="title" [class.has-badge]="item.badge && item.badge.title !== 0">{{item.title}}
            <svg class="icon">
              <use xlink:href="/assets/img/sprite.svg?v1.7#header-underline"></use>
            </svg>
            <span class="badge" *ngIf="item.badge && item.badge.title !== 0">
              {{item.badge.title}}
            </span>
          </span>
        </a>
        <ng-template #noLink>
          <a class="menu-link"> <span class="title"
              [class.has-badge]="item.badge && item.badge.title !== 0">{{item.title}}
              <svg class="icon">
                <use xlink:href="/assets/img/sprite.svg?v1.7#header-underline"></use>
              </svg>
              <span class="badge" *ngIf="item.badge && item.badge.title !== 0">
                {{item.badge.title}}
              </span>
            </span>
          </a>
        </ng-template>
      </li>
    </ul>
  </nav>
</ng-template>