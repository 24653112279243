<app-modal #modal slug="waiting-list">
  <div class="subscription">
    <app-title-bar
      [center]="true"
      [barStyle]="{ 'padding-bottom': '25px', 'padding-top': '20px' }"
    >
      <h2 class="text-center">Waiting List</h2>
    </app-title-bar>
    <p>ADD YOURSELF TO THE WAITING LIST FOR THE FOLLOWING CLASS.</p>
    <p>
      <span *ngIf="location">{{ location }}</span>
      <span *ngIf="lesson"><br />{{ lesson.title }} {{ lesson.age }}</span>
      <span *ngIf="timeslot"
        ><br />{{ timeslot.day_of_week }} {{ timeslot.start_time }} -
        {{ timeslot.end_time }}</span
      >
    </p>
    <form [formGroup]="waitingForm" appFirstInvalid>
      <app-form-group
        class="w-100-p"
        [form]="waitingForm"
        [controls]="controls"
      ></app-form-group>
      <button
        type="submit"
        appButton
        [loading]="saving"
        class="btn btn-light-blue center"
        (click)="addToList(waitingForm.value)"
      >
        Add to waiting list
      </button>
    </form>
  </div>
  <div class="subscription-footer">
    <p>YOU WILL RECEIVE AN EMAIL ONCE A SPACE BECOMES AVAILABLE</p>
  </div>
</app-modal>
