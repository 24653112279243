import { Injectable } from '@angular/core';
import { Portal } from '@angular/cdk/portal';
import { Subject, Observable } from 'rxjs';
import { Image } from '@global/models/image';
import { BreadcrumbsService, Breadcrumb } from '@exalif/ngx-breadcrumbs';

export interface BottomBarButton {
  title: string;
  route: string[];
  fragment: string;
}

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  public crumbs$: Observable<
    Breadcrumb[]
  > = this.breadcrumbsService.getCrumbs();
  images: Image[] = [];
  indexImage = 0;
  selectedPortal: Portal<any>;
  backgroundVideo$ = new Subject<string>();
  backgroundImage$ = new Subject<string>();
  headerClass$ = new Subject<string>();
  searchMode = false;
  modal = false;
  showCountDown = false;
  showClassSearch = false;
  bottomBarButton: BottomBarButton;

  private _headerHeight: number;
  private _top: number;
  get headerHeight() {
    return this._headerHeight;
  }

  set headerHeight(height) {
    this._headerHeight = height;
  }

  get top() {
    return this._top;
  }

  set top(top) {
    this._top = top;
  }

  constructor(private breadcrumbsService: BreadcrumbsService) { }
}
