import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

export interface IPlatformService {
  isBrowser: boolean;
  isServer: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private _mediaMatcher: MediaMatcher
  ) {}

  public get isDesktop(): boolean {
    return this.isBrowser
      ? this._mediaMatcher.matchMedia('only screen and (min-width: 768px)')
          .matches
      : true;
  }

  public get smBreak(): boolean {
    return this._mediaMatcher.matchMedia('only screen and (min-width: 667px)')
      .matches;
  }

  public get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  public get isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  public getNativeDocument(): Document {
    if (isPlatformBrowser(this.platformId)) {
      return document;
    }
    return {} as Document;
  }

  public getNativeWindow(): Window {
    if (isPlatformBrowser(this.platformId)) {
      return window;
    }
    return {} as Window;
  }
}
