import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { MainLayoutComponent } from './core/layout/main-layout/main-layout.component';
import { LoginComponent } from './core/components/login/login.component';
import { RegisterComponent } from './core/components/register/register.component';
import { SizeGuideComponent } from './core/components/size-guide/size-guide.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FranchiseComponent } from './pages/franchise/franchise.component';
import { PopupComponent } from './pages/popup/popup.component';
import { RecruitmentComponent } from './pages/recruitment/recruitment.component';
import { CookieLawComponent } from './core/modules/cookie-law/cookie-law.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { PartnersComponent } from './core/components/partners/partners.component';
import { EarlySubscriptionComponent } from './core/components/early-subscription/early-subscription.component';
import { SeoResolver } from './core/services/seo.resolver';
import { WaitingListComponent } from './core/components/waiting-list/waiting-list.component';
import { ForgotComponent } from './core/components/forgot/forgot.component';
import { ResetComponent } from './core/components/reset/reset.component';
import { TasterConfirmComponent } from './core/components/taster-confirm/taster-confirm.component';
import { AuthGuard } from './core/guards/auth.guard';
import { BritishCyclingComponent } from './core/components/british-cycling/british-cycling.component';
import { AccelercelerateBookingComponent } from './core/components/accelercelerate-booking/accelercelerate-booking.component';
import { PagePopupComponent } from './core/components/page-popup/page-popup.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: '404',
        component: NotFoundComponent,
      },
      {
        path: 'contact',
        component: ContactComponent,
      },
      {
        path: 'franchises',
        component: FranchiseComponent,
        data: {
          slug: 'franchises',
        },
        resolve: {
          metadata: SeoResolver,
        },
      },
      {
        path: 'pop-up-events',
        component: PopupComponent,
        data: {
          slug: 'pop-up-events',
        },
        resolve: {
          metadata: SeoResolver,
        },
      },
      {
        path: 'recruitment',
        component: RecruitmentComponent,
        data: {
          slug: 'recruitment',
        },
        resolve: {
          metadata: SeoResolver,
        },
      },
      {
        path: 'terms-of-use',
        component: PrivacyComponent,
      },
      {
        path: 'about',
        loadChildren: () =>
          import('./about/about.module').then((m) => m.AboutModule),
      },
      {
        path: 'academy',
        loadChildren: () =>
          import('./academy/academy.module').then((m) => m.AcademyModule),
      },
      {
        path: 'account',
        loadChildren: () =>
          import('./account/account.module').then((m) => m.AccountModule),
      },
      {
        path: 'checkout',
        loadChildren: () =>
          import('./checkout/checkout.module').then((m) => m.CheckoutModule),
      },
      {
        path: 'membership',
        loadChildren: () =>
          import('./membership/membership.module').then(
            (m) => m.MembershipModule
          ),
      },
      {
        path: 'coaching',
        loadChildren: () =>
          import('./one-to-one/one-to-one.module').then(
            (m) => m.OneToOneModule
          ),
      },
      {
        path: 'donate',
        loadChildren: () =>
          import('./donate/donate.module').then((m) => m.DonateModule),
      },
      {
        path: 'faqs',
        loadChildren: () =>
          import('./faqs/faqs.module').then((m) => m.FaqsModule),
      },
      {
        path: 'shop',
        loadChildren: () =>
          import('./shop/shop.module').then((m) => m.ShopModule),
        data: {
          breadcrumbs: 'Shop',
        },
      },
      {
        path: 'blog',
        loadChildren: () =>
          import('./blog/blog.module').then((m) => m.BlogModule),
        data: {
          breadcrumbs: 'Blog',
        },
      },
      {
        path: 'cycling-resources',
        loadChildren: () =>
          import('./resource/resource.module').then((m) => m.ResourceModule),
        data: {
          breadcrumbs: 'Resource',
        },
      },
      {
        path: '',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule),
      },
    ],
  },
  {
    path: 'login',
    outlet: 'popup',
    component: LoginComponent,
  },
  {
    path: 'register',
    outlet: 'popup',
    component: RegisterComponent,
  },
  {
    path: 'size-guide',
    outlet: 'popup',
    component: SizeGuideComponent,
  },
  {
    path: 'size-guide/:slug',
    outlet: 'popup',
    component: SizeGuideComponent,
  },
  {
    path: 'info/:slug',
    outlet: 'popup',
    component: PagePopupComponent,
  },
  {
    path: 'cookie',
    outlet: 'popup',
    component: CookieLawComponent,
  },
  {
    path: 'partners',
    outlet: 'popup',
    component: PartnersComponent,
  },
  {
    path: 'early-subscription',
    outlet: 'popup',
    component: EarlySubscriptionComponent,
  },
  {
    path: 'waiting-list',
    outlet: 'popup',
    component: WaitingListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'taster-confirm',
    outlet: 'popup',
    component: TasterConfirmComponent,
  },
  {
    path: 'forgot',
    outlet: 'popup',
    component: ForgotComponent,
  },
  {
    path: 'reset/:token',
    outlet: 'popup',
    component: ResetComponent,
  },
  {
    path: 'british-cycling',
    outlet: 'popup',
    component: BritishCyclingComponent,
  },
  {
    path: 'accelerate',
    outlet: 'popup',
    component: AccelercelerateBookingComponent,
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollOffset: [0, 85],
    initialNavigation: 'enabled',
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
