<app-content-expand color="grey">
  <div class="content-title">
    <svg class="icon" *ngIf="hasCard">
      <use [attr.xlink:href]="'/assets/svg/card-sprite.svg?v1#' + paymentMethod.card.brand"></use>
    </svg>
    <div class="ending">
      Ending {{paymentMethod.card.last4}}
    </div>
    <div class="exp">
      Expires {{paymentMethod.card.exp_month}}/{{paymentMethod.card.exp_year}}
    </div>
  </div>
  <div class="extra-details">
    <ng-container *ngIf="!editing; else editDetails">
      <app-title-bar [barStyle]="{'padding-bottom': '15px', 'padding-top': '15px'}">
        <h5>Billing Address</h5>
      </app-title-bar>
      <p *ngIf="paymentMethod.billing_details.address as address">
        {{paymentMethod.billing_details.name}}
        <br>{{address.line1}}
        <span *ngIf="address.line2"><br>{{address.line2}}</span>
        <br>{{address.city}}
        <span *ngIf="address.state"><br>{{address.state}}</span>
        <br>{{address.postal_code}}
      </p>
      <button class="btn" (click)="editing = true">Edit card</button>
    </ng-container>
    <ng-template #editDetails>
      <app-title-bar [barStyle]="{'padding-bottom': '15px', 'padding-top': '15px'}">
        <h5>Update card details</h5>
      </app-title-bar>
      <p>Please make sure the details enter below match your card to prevent failed payments.</p>
      <form [formGroup]="editForm" (ngSubmit)="updateCard(editForm.value)" appFirstInvalid>
        <app-address-stripe [form]="addressForm" [paymentMethod]="paymentMethod"></app-address-stripe>
        <app-form-group class="w-100-p" [form]="editForm" [controls]="controls"></app-form-group>
        <button appButton [loading]="saving" type="submit" class="btn btn-light-blue">Update
          Card</button>
      </form>
    </ng-template>
  </div>
</app-content-expand>
