import { Component, OnInit, OnDestroy } from '@angular/core';
import { ImageService } from './core/services/image.service';
import { ViewportScroller } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { AccountMenu } from './account/account-menu';
import { CookieLawService } from './core/modules/cookie-law/cookie-law.service';
import { filter, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

export const noScrollRoutes: string[] = [
  ...AccountMenu.map((m) => m.url),
  '/shop',
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  popup = false;
  constructor(
    private _imageService: ImageService,
    private _viewPortScroll: ViewportScroller,
    private _router: Router,
    private _cookie: CookieLawService
  ) {}

  ngOnInit(): void {
    this._cookie.seen();
    this._viewPortScroll.setHistoryScrollRestoration('auto');
    this._imageService.setDpr();
    this._router.events
      .pipe(
        filter((evt) => evt instanceof NavigationEnd),
        takeUntil(componentDestroyed(this))
      )
      .subscribe(() => {
        // Scroll to top unless conditions met
        if (noScrollRoutes.find((r) => r === this._router.url) === undefined) {
          if (this._router.url.indexOf('popup') !== -1) {
            this.popup = true;
            return;
          }
          if (this._router.url.indexOf('popup') === -1 && this.popup) {
            this.popup = false;
            return;
          }
          this._viewPortScroll.scrollToPosition([0, 0]);
        }
        // Make sure GDPR cookie banner is shown if required
        if (
          this._router.url.indexOf('popup:login') === -1 &&
          !this._cookie.tracking
        ) {
          this._cookie.acceptCookies();
        }

        if (
          this._router.url.indexOf('popup:cookie') === -1 &&
          !this._cookie.tracking
        ) {
          this._cookie.showCookie();
        }
      });
  }

  ngOnDestroy() {}
}
