import {
  MemoizedSelector,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import { State, Session } from './state';

const getSessions = (state: State) => state.sessions;

export const selectRootStoreState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('tasterStore');

export const selectSessions: MemoizedSelector<
  object,
  Session[]
> = createSelector(
  selectRootStoreState,
  getSessions
);
