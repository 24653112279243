import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { InputComponent } from './input/input.component';
import { FormFieldWrapperComponent } from './form-field/form-field.component';
import { MulticheckboxComponent } from './multicheckbox/multicheckbox.component';
import { RadioComponent } from './radio/radio.component';
import { SelectComponent } from './select/select.component';
import { TextareaComponent } from './textarea/textarea.component';
import { InputMaskComponent } from './input-mask/input-mask.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { RouterModule } from '@angular/router';
import { TextMaskModule } from 'angular2-text-mask';
import { PanelComponent } from './panel/panel.component';
import { RepeatSectionComponent } from './repeat-section/repeat-section.component';
import { LinkWrapperComponent } from './link-wrapper/link-wrapper.component';
import { HtmlTemplateComponent } from './html-template/html-template.component';

const COMPONENTS = [
  CheckboxComponent,
  InputComponent,
  FormFieldWrapperComponent,
  MulticheckboxComponent,
  RadioComponent,
  SelectComponent,
  TextareaComponent,
  InputMaskComponent,
  PanelComponent,
  RepeatSectionComponent,
  LinkWrapperComponent,
  HtmlTemplateComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    TextMaskModule,
    FormlyModule.forChild({
      extras: { checkExpressionOn: 'modelChange' },
      wrappers: [
        { name: 'form-field', component: FormFieldWrapperComponent },
        { name: 'panel', component: PanelComponent },
        { name: 'link-wrapper', component: LinkWrapperComponent },
      ],
      types: [
        { name: 'html-template', component: HtmlTemplateComponent },
        {
          name: 'input',
          component: InputComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'input-mask',
          component: InputMaskComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'checkbox',
          component: CheckboxComponent,
        },
        {
          name: 'multi-checkbox',
          component: MulticheckboxComponent,
        },
        {
          name: 'radio',
          component: RadioComponent,
        },
        {
          name: 'select',
          component: SelectComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'textarea',
          component: TextareaComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'repeat',
          component: RepeatSectionComponent,
        },
      ],
    }),
    FormlySelectModule,
  ],
  exports: [...COMPONENTS],
})
export class FormlyCustomModule {}
