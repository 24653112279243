<app-nav></app-nav>
<header app-header appWindowHeight></header>
<div class="spacer" [style.height.px]="layout.headerHeight"></div>
<main [class.searching]="layout.searchMode" [class.modal]="layout.modal" #main>
  <router-outlet></router-outlet>
</main>
<app-image-gallery [images]="layout.images" [index]="layout.indexImage" *ngIf="layout.images && layout.images.length">
</app-image-gallery>
<app-class-search *ngIf="layout.showClassSearch"></app-class-search>
<app-bottom-bar-button *ngIf="layout.bottomBarButton" [options]="layout.bottomBarButton"></app-bottom-bar-button>
<app-order-countdown [sticky]="true" *ngIf="layout.showCountDown && ps.isBrowser"></app-order-countdown>
<app-mailchimp></app-mailchimp>
<footer app-footer *ngIf="!layout.searchMode"></footer>
<router-outlet name="popup"></router-outlet>