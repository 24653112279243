import {
  Directive,
  ElementRef,
  HostListener,
  AfterViewInit,
  ChangeDetectorRef,
  NgZone
} from '@angular/core';
import { PlatformService } from '@app/core/services/platform.service';

@Directive({
  selector: '[appWindowHeight]'
})
export class WindowHeightDirective implements AfterViewInit {
  scrolling: any;
  isScrolling: boolean;
  constructor(
    private _el: ElementRef,
    private _ps: PlatformService,
    private _cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}

  @HostListener('window:resize')
  onResize() {
    if (!this.isScrolling) {
      this.setVh();
    }
  }

  @HostListener('window:scroll')
  onScroll() {
    this.isScrolling = true;
    clearTimeout(this.scrolling);
    this.scrolling = setTimeout(() => {
      this.isScrolling = false;
    }, 66);
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.setVh();
    });
  }

  setVh() {
    if (this._ps.isBrowser && !this.isScrolling) {
      const vh = this._ps.getNativeWindow().innerHeight * 0.01;
      this._el.nativeElement.style.setProperty('--vh', `${vh}px`);
      this._cdr.detectChanges();
    }
  }
}
