import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Address } from '@global/models/address';
import { ICustomControl } from '@global/models/fields';
import { Observable } from 'rxjs';
import { Country } from '@global/models/country';
import { ApiService, ApiOptions } from '@app/core/services/api.service';
import { FormService } from '@app/core/services/form.service';
import { tap, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() address: Address;
  @Input() name = true;
  @Input() email: boolean;
  @Input() phone: boolean;
  controls: ICustomControl[];
  countries$: Observable<Country[]>;
  private countries: Country[];
  constructor(private _formService: FormService, _api: ApiService) {
    const options: ApiOptions = new ApiOptions({
      endpoint: 'country',
      query: {
        $select: ['name', 'cca2'],
        $sort: ['name']
      }
    });
    this.countries$ = _api
      .find(options)
      .pipe(tap((countries: Country[]) => (this.countries = countries)));
  }

  ngOnInit() {
    this.controls = [];
    if (this.name) {
      const namefields = [
        {
          id: 'first_name',
          label: 'First name',
          value: this.address ? this.address.first_name : null,
          controlType: 'text',
          type: 'text',
          validators: ['required'],
          errors: {
            required: 'required'
          },
          half_width: true,
          autocomplete: 'given-name'
        },
        {
          id: 'last_name',
          label: 'Last name',
          value: this.address ? this.address.last_name : null,
          controlType: 'text',
          type: 'text',
          validators: ['required'],
          errors: {
            required: 'required'
          },
          half_width: true,
          autocomplete: 'family-name'
        }
      ];

      this.controls = [...this.controls, ...namefields];
    }

    const addressfields = [
      {
        id: 'address_line_1',
        label: 'House number and street name',
        value: this.address ? this.address.address_line_1 : null,
        controlType: 'text',
        type: 'text',
        validators: ['required'],
        errors: {
          required: 'required'
        },
        autocomplete: 'address-line1'
      },
      {
        id: 'address_line_2',
        label: 'Apartment, suite, unit etc. (optional)',
        value: this.address ? this.address.address_line_2 : null,
        controlType: 'text',
        type: 'text',
        autocomplete: 'address-line2'
      },
      {
        id: 'city',
        label: 'Town/City',
        value: this.address ? this.address.city : null,
        controlType: 'text',
        type: 'text',
        validators: ['required'],
        errors: {
          required: 'required'
        },
        autocomplete: 'locality'
      },
      {
        id: 'region',
        label: 'County',
        value: this.address ? this.address.region : null,
        controlType: 'text',
        type: 'text',
        autocomplete: 'region'
      },
      {
        id: 'country',
        label: 'Country',
        value:
          this.address && this.address.country
            ? this.address.country
            : 'United Kingdom',
        controlType: 'select',
        optionsAsync: this.countries$,
        optionValue: 'name',
        optionLabel: 'name',
        validators: ['required'],
        errors: {
          required: 'required'
        },
        autocomplete: 'country'
      },
      {
        id: 'country_code',
        label: 'Country Code',
        value:
          this.address && this.address.country_code
            ? this.address.country_code
            : 'GB',
        controlType: 'hidden'
      },
      {
        id: 'postcode',
        label: 'Postcode',
        value: this.address ? this.address.postcode : null,
        controlType: 'text',
        type: 'text',
        validators: ['required'],
        errors: {
          required: 'required'
        },
        autocomplete: 'postal-code'
      }
    ];
    this.controls = [...this.controls, ...addressfields];
    if (this.phone) {
      this.controls.push({
        id: 'phone',
        label: 'Phone',
        value: this.address ? this.address.phone : null,
        controlType: 'text',
        type: 'tel',
        validators: ['required'],
        errors: {
          required: 'required'
        },
        half_width: true,
        autocomplete: 'tel'
      });
    }
    if (this.email) {
      this.controls.push({
        id: 'email',
        label: 'Email',
        value: this.address ? this.address.email : null,
        controlType: 'text',
        type: 'email',
        validators: ['required', 'email'],
        errors: {
          required: 'required',
          email: 'email'
        },
        half_width: true,
        autocomplete: 'email'
      });
    }

    this._formService.addControls(this.controls, this.form);
    this.form
      .get('country')
      .valueChanges.pipe(takeUntil(componentDestroyed(this)))
      .subscribe((name: string) => {
        const country = this.countries.find(c => c.name === name);
        this.form.get('country_code').setValue(country.cca2);
      });
  }

  ngOnDestroy() {}
}
