import { Injectable, OnDestroy } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Lesson } from '@global/models/lesson';
import { Student } from '@global/models/student';
import { LESSON_TYPE, LESSON_NAMES, TSHIRT_SIZES } from '@global/data';
import AddressForm from '@app/shared/modules/formly/forms/address';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { TextAreaPipe } from '@app/shared/pipes/text-area.pipe';
import { Store, select } from '@ngrx/store';
import { RootStoreState } from '@app/root-store';
import { Country } from '@global/models/country';
import { CartStoreSelectors } from '@app/root-store/cart-store';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { FormArray } from '@angular/forms';

export interface StepType {
  label: string;
  fields: FormlyFieldConfig[];
}

@Injectable()
export class BookClassAddStudentService implements OnDestroy {
  steps: StepType[];
  fields: FormlyFieldConfig[];
  autoCorrectedDatePipe: any = createAutoCorrectedDatePipe('dd/mm/yyyy');
  countries: Country[];
  constructor(
    private _store: Store<RootStoreState.State>,
    private _textAreaPipe: TextAreaPipe
  ) {
    this._store
      .pipe(
        select(CartStoreSelectors.selectCountries),
        takeUntil(componentDestroyed(this))
      )
      .subscribe((countries: Country[]) => (this.countries = countries));
  }

  hideTshirt(lesson: Lesson, student: Student) {
    const lessonTshirt = !lesson
      ? false
      : LESSON_TYPE.find((t) => t.value === lesson.type).tshirt
        ? LESSON_NAMES.find((t) => t.value === lesson.title).tshirt
        : false;

    const hideTshirt =
      !lessonTshirt ||
        (student && student.tshirt_given && student.tshirt_given.given)
        ? true
        : false;
    return hideTshirt;
  }

  createFormSimple(lesson: Lesson, student: Student) {
    const hideTshirt = this.hideTshirt(lesson, student);
    const textAreaHook = {
      onInit: (field) => {
        const { formControl } = field;
        setTimeout(() => {
          if (formControl.value) {
            formControl.patchValue(
              this._textAreaPipe.transform(formControl.value)
            );
          }
        }, 500);
      },
    };
    this.fields = [
      {
        key: 'name',
        type: 'input',
        templateOptions: {
          label: 'Full name',
          required: true,
        },
      },
      {
        key: 'date_of_birth',
        type: 'input-mask',
        templateOptions: {
          label: 'Date of birth (DD/MM/YYYY)',
          required: true,
          mask: {
            mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
            pipe: this.autoCorrectedDatePipe,
            guide: false,
          },
        },
      },
      {
        key: 'photo_consent',
        type: 'checkbox',
        templateOptions: {
          label:
            // eslint-disable-next-line max-len
            'As part of the classes, from time to time, photographs will be taken. Please tick here if you consent to images of your child to be used solely for marketing purposes.',
        },
      },
      {
        key: 'information',
        type: 'textarea',
        hooks: textAreaHook,
        templateOptions: {
          label: 'Information',
          introText:
            // eslint-disable-next-line max-len
            '<p>IMPORTANT INFORMATION.<br>Please enter any medical or relevant information our coaches should know about your child:</p>',
        },
      },
      {
        key: 'emergency_contact',
        type: 'textarea',
        hooks: textAreaHook,
        templateOptions: {
          label: 'Emergency Contact',
          required: true,
          introText:
            // eslint-disable-next-line max-len
            '<p>EMERGENCY CONTACT DETAILS.<br>Please enter the name, relationship and phone number of the person we should contact in the event of an emergency:</p>',
        },
      },
      {
        key: 'tshirt_size',
        type: 'select',
        hideExpression: hideTshirt,
        templateOptions: {
          label: 'Select Size',
          required: true,
          options: TSHIRT_SIZES,
          valueProp: 'value',
          labelProp: 'title',
          introText:
            // eslint-disable-next-line max-len
            '<p>MEMBERS JERSEY.<br>As part of the joining fee, you will receive a long sleeved top that we ask your child to wear at every class. Please select your registered child’s size below:</p>',
          helpLink: {
            title: 'Size Guide',
            url: 'size-guide',
            query: { member: true },
          },
        },
      },
    ];
    return this.fields;
  }

  createForm(lesson: Lesson, student: Student) {
    const hideTshirt = this.hideTshirt(lesson, student);

    const textAreaHook = {
      onInit: (field) => {
        const { formControl } = field;
        if (formControl.value) {
          formControl.patchValue(
            this._textAreaPipe.transform(formControl.value)
          );
        }
      },
    };

    this.steps = [
      {
        label: 'Child details',
        fields: [
          {
            key: 'name',
            type: 'input',
            templateOptions: {
              label: 'Full name',
              required: true,
            },
          },
          {
            key: 'date_of_birth',
            type: 'input-mask',
            templateOptions: {
              label: 'Date of birth (DD/MM/YYYY)',
              required: true,
              mask: {
                mask: [
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ],
                pipe: this.autoCorrectedDatePipe,
                guide: false,
              },
            },
          },
          {
            key: 'photo_consent',
            type: 'checkbox',
            templateOptions: {
              label:
                // eslint-disable-next-line max-len
                'As part of the classes, from time to time, photographs will be taken. Please tick here if you consent to images of your child to be used solely for marketing purposes.',
            },
          },
          {
            key: 'tshirt_size',
            type: 'select',
            hideExpression: hideTshirt,
            templateOptions: {
              label: 'Select Size',
              required: true,
              options: TSHIRT_SIZES,
              valueProp: 'value',
              labelProp: 'title',
              introText:
                // eslint-disable-next-line max-len
                '<p>MEMBERS JERSEY.<br>As part of the joining fee, you will receive a long sleeved top that we ask your child to wear at every class. Please select your registered child’s size below:</p>',
              helpLink: {
                title: 'Size Guide',
                url: 'size-guide',
                query: { member: true },
              },
            },
          },
          {
            key: 'address',
            wrappers: ['panel'],
            templateOptions: { label: 'Participant address' },
            fieldGroup: AddressForm(this.countries, false, true, true),
          },
        ],
      },
      {
        label: 'Emergency Contact Details',
        fields: [
          {
            key: 'first_contact',
            wrappers: ['panel'],
            templateOptions: { label: 'Parent/Carer' },
            fieldGroup: [
              {
                key: 'name',
                type: 'input',
                templateOptions: {
                  required: true,
                  type: 'text',
                  label: 'Full Name',
                },
              },
              {
                key: 'day_phone',
                type: 'input',
                templateOptions: {
                  required: true,
                  type: 'tel',
                  label: 'Day Phone',
                },
              },
              {
                key: 'evening_phone',
                type: 'input',
                templateOptions: {
                  required: true,
                  type: 'tel',
                  label: 'Evening Phone',
                },
              },
              {
                key: 'email',
                type: 'input',
                templateOptions: {
                  required: true,
                  type: 'email',
                  label: 'Email',
                },
              },
              {
                key: 'relationship',
                type: 'input',
                templateOptions: {
                  required: true,
                  type: 'text',
                  label: 'Relationship',
                },
              },
            ],
          },
          {
            key: 'second_contact',
            wrappers: ['panel'],
            templateOptions: { label: 'Second Contact' },
            fieldGroup: [
              {
                key: 'name',
                type: 'input',
                templateOptions: {
                  required: true,
                  type: 'text',
                  label: 'Full Name',
                },
              },
              {
                key: 'day_phone',
                type: 'input',
                templateOptions: {
                  required: true,
                  type: 'tel',
                  label: 'Day Phone',
                },
              },
              {
                key: 'evening_phone',
                type: 'input',
                templateOptions: {
                  required: true,
                  type: 'tel',
                  label: 'Evening Phone',
                },
              },
              {
                key: 'email',
                type: 'input',
                templateOptions: {
                  required: true,
                  type: 'email',
                  label: 'Email',
                },
              },
              {
                key: 'relationship',
                type: 'input',
                templateOptions: {
                  required: true,
                  type: 'text',
                  label: 'Relationship',
                },
              },
            ],
          },
        ],
      },
      {
        label: 'Medical Information',
        fields: [
          {
            key: 'medical',
            fieldGroup: [
              {
                key: 'disability',
                type: 'radio',
                templateOptions: {
                  options: [
                    {
                      title: 'Yes',
                      value: 'Yes',
                    },
                    {
                      title: 'No',
                      value: 'No',
                    },
                  ],
                  valueProp: 'value',
                  labelProp: 'title',
                  introText:
                    // eslint-disable-next-line max-len
                    '<p>Would the participant be considered to have a disability?</p>',
                },
              },
              {
                key: 'disability_details',
                type: 'textarea',
                hooks: textAreaHook,
                templateOptions: {
                  label: 'Details',
                },
                hideExpression: (
                  model: any,
                  formState: any,
                  field: FormlyFieldConfig
                ) => (
                  (field.parent.model &&
                    field.parent.model.disability &&
                    field.parent.model.disability === 'No') ||
                  (field.parent.model && !field.parent.model.disability)
                ),
              },
              {
                key: 'conditions',
                type: 'multi-checkbox',
                templateOptions: {
                  type: 'array',
                  options: [
                    {
                      title: 'Infectious Disease',
                      value: 'Infectious Disease',
                    },
                    {
                      title: 'Epilepsy',
                      value: 'Epilepsy',
                    },
                    {
                      title: 'Heart Condition',
                      value: 'Heart Condition',
                    },
                    {
                      title: 'Fainting/Dizziness',
                      value: 'Fainting/Dizziness',
                    },
                    {
                      title: 'Asthma',
                      value: 'Asthma',
                    },
                    {
                      title: 'Allergies',
                      value: 'Allergies',
                    },
                    {
                      title: 'Mobility problems',
                      value: 'Mobility problems',
                    },
                    {
                      title: 'Pregnancy',
                      value: 'Pregnancy',
                    },
                    {
                      title: 'Diabetes',
                      value: 'Diabetes',
                    },
                    {
                      title: 'Eye condition',
                      value: 'Eye condition',
                    },
                    {
                      title:
                        'Had major surgery within last year (please provide details)',
                      value: 'Had major surgery within last year',
                    },
                    {
                      title: 'Other',
                      value: 'Other',
                    },
                  ],
                  valueProp: 'value',
                  labelProp: 'title',
                  introText:
                    // eslint-disable-next-line max-len
                    '<p>Has the participant any of the conditions below, limiting involvement in the activity?<br>Please select any or all that apply</p>',
                },
              },
              {
                key: 'conditions_details',
                type: 'textarea',
                hooks: textAreaHook,
                templateOptions: {
                  label: 'Details',
                },
                expressionProperties: {
                  'templateOptions.required': (
                    model: any,
                    formState: any,
                    field: FormlyFieldConfig
                  ) => {
                    if (
                      field.parent.model.conditions &&
                      (field.parent.model.conditions[
                        'Had major surgery within last year'
                      ] ||
                        field.parent.model.conditions['Other'])
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
                hideExpression: (
                  model: any,
                  formState: any,
                  field: FormlyFieldConfig
                ) => {
                  if (
                    field.parent.model.conditions &&
                    (field.parent.model.conditions[
                      'Had major surgery within last year'
                    ] ||
                      field.parent.model.conditions['Other'])
                  ) {
                    return false;
                  }
                  return true;
                },
              },
              {
                key: 'medical_treatments',
                type: 'textarea',
                hooks: textAreaHook,
                templateOptions: {
                  label: 'Details of medical treatment and/or drugs prescribed',
                },
              },
              {
                key: 'allergic',
                type: 'radio',
                templateOptions: {
                  options: [
                    {
                      title: 'Yes',
                      value: 'Yes',
                    },
                    {
                      title: 'No',
                      value: 'No',
                    },
                  ],
                  valueProp: 'value',
                  labelProp: 'title',
                  introText:
                    // eslint-disable-next-line max-len
                    '<p>Is the participant allergic to any medication?</p>',
                },
              },
              {
                key: 'allergic_details',
                type: 'textarea',
                hooks: textAreaHook,
                templateOptions: {
                  label: 'Details',
                },
                hideExpression: (
                  model: any,
                  formState: any,
                  field: FormlyFieldConfig
                ) => (
                  (field.parent.model &&
                    field.parent.model.allergic &&
                    field.parent.model.allergic === 'No') ||
                  (field.parent.model && !field.parent.model.allergic)
                ),
              },
              {
                key: 'doctor',
                type: 'textarea',
                hooks: textAreaHook,
                templateOptions: {
                  label: 'Doctors Name/Surgery',
                  required: true,
                },
              },
              {
                template:
                  // eslint-disable-next-line max-len
                  '<p>Please note below any medication (over the counter or prescription drugs) that you are happy for a member of the Clancy Briggs team to administer. This form should be completed for such items as antihistamines; paracetamol (or Calpol); as well as inhalers for asthma for example. If your child takes controlled substances please discuss this with a member of the team before progressing further as separate arrangements will be made.</p>',
              },
              {
                key: 'medications',
                type: 'repeat',
                templateOptions: {
                  addText: 'Add medication',
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: 'name',
                      type: 'input',
                      templateOptions: {
                        required: true,
                        type: 'text',
                        label: 'Name of Medication',
                      },
                    },
                    {
                      key: 'reason',
                      type: 'input',
                      templateOptions: {
                        required: true,
                        type: 'text',
                        label: 'Reason for Medication',
                      },
                    },
                    {
                      key: 'method',
                      type: 'radio',
                      templateOptions: {
                        required: true,
                        options: [
                          {
                            title: 'Oral',
                            value: 'Oral',
                          },
                          {
                            title: 'Topical',
                            value: 'Topical',
                          },
                          {
                            title: 'Inhaled',
                            value: 'Inhaled',
                          },
                          {
                            title: 'Injection',
                            value: 'Injection',
                          },
                          {
                            title: 'Other',
                            value: 'Other',
                          },
                        ],
                        valueProp: 'value',
                        labelProp: 'title',
                        introText:
                          // eslint-disable-next-line max-len
                          '<p>How is medication taken?</p>',
                      },
                    },
                    {
                      key: 'method_details',
                      type: 'textarea',
                      hooks: textAreaHook,
                      templateOptions: {
                        label: 'Details',
                      },
                      expressionProperties: {
                        'templateOptions.required': (
                          model: any,
                          formState: any,
                          field: FormlyFieldConfig
                        ) => {
                          if (
                            field.parent.model &&
                            field.parent.model.method &&
                            field.parent.model.method === 'Other'
                          ) {
                            return true;
                          }
                          return false;
                        },
                      },
                      hideExpression: (
                        model: any,
                        formState: any,
                        field: FormlyFieldConfig
                      ) => {
                        if (
                          field.parent.model &&
                          field.parent.model.method &&
                          field.parent.model.method === 'Other'
                        ) {
                          return false;
                        }
                        return true;
                      },
                    },
                    {
                      key: 'instructions',
                      type: 'textarea',
                      hooks: textAreaHook,
                      templateOptions: {
                        label: 'Additional instructions/comments',
                      },
                    },
                  ],
                },
              },
              {
                template:
                  // eslint-disable-next-line max-len
                  '<p>I authorise Clancy Briggs Academy personnel to administer the medication named above to my child in the manner as stated. I realise any liability in relation to the administration of this medication. I also acknowledge that I, the parent/carer/guardian, have given the first does of this medication without any allergic or unexpected reactions.</p>',
              },
              {
                key: 'signature',
                type: 'input',
                templateOptions: {
                  required: true,
                  type: 'text',
                  label: 'Enter Name as Signature',
                },
              },
            ],
          },
        ],
      },
      {
        label: 'Consent',
        fields: [
          {
            key: 'consent',
            wrappers: ['link-wrapper'],
            templateOptions: {
              links: [
                {
                  title: 'Information Sheet',
                  link: [
                    '',
                    {
                      outlets: {
                        popup: ['info', 'information-sheet'],
                      },
                    },
                  ],
                },
                {
                  title:
                    'Behaviour Code for Children and Young People Using Our Services',
                  link: [
                    '',
                    {
                      outlets: {
                        popup: ['info', 'behaviour-code'],
                      },
                    },
                  ],
                },
                {
                  title:
                    'Acceptable use statement for internet and social media use',
                  link: [
                    '',
                    {
                      outlets: {
                        popup: ['info', 'acceptable-use-statement'],
                      },
                    },
                  ],
                },
              ],
            },
            fieldGroup: [
              {
                type: 'html-template',
                templateOptions: {
                  template: '',
                },
                expressionProperties: {
                  'templateOptions.template': (
                    model,
                    formState: any,
                    field: FormlyFieldConfig
                  ) =>
                    // eslint-disable-next-line max-len
                    `<p>I have read, noted and understood the information on the information sheet and I consent to my child taking part in any of the activities listed.</p><p>I acknowledge that cycling activities carry an element of risk and therefore accept the need for responsible behaviour, including listening to and following safety instructions. In the event of an emergency I agree to my child receiving medical treatment, including anaesthetic, as considered necessary by the medical authorities present.</p><p>We have discussed the <strong>Behaviour Code for Children and Young People Using Our Services</strong> statement and also the <strong>Acceptable use statement for internet and social media use.</strong> We can confirm that we have read and understood these and <strong>${field.parent.parent.model.name
                      ? field.parent.parent.model.name.toUpperCase()
                      : ''
                    }</strong> agrees to follow these during activities at Clancy Briggs Cycling Academy.</p>`
                  ,
                },
              },
              {
                key: 'signature',
                type: 'input',
                templateOptions: {
                  required: true,
                  type: 'text',
                  label: 'Enter Name as Signature',
                },
              },
            ],
          },
        ],
      },
    ];
    return this.steps;
  }

  ngOnDestroy() { }
}
