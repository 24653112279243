import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ICustomControl } from '@global/models/fields';
import { FormService } from '@app/core/services/form.service';
import { ApiService, ApiOptions } from '@app/core/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactFormComponent implements OnInit, OnDestroy {
  @Input() center: boolean;
  @Input() location: boolean;
  @Input() phone: boolean;
  @Input() type: string;
  contactForm: FormGroup;
  controls: ICustomControl[];
  saving = false;
  constructor(
    private _formService: FormService,
    private _api: ApiService,
    private _fb: FormBuilder,
    private _toastr: ToastrService
  ) {
    this.contactForm = this._fb.group({});
  }

  ngOnInit() {
    const baseControls = [
      {
        id: 'name',
        label: 'Full Name',
        controlType: 'text',
        type: 'text',
        validators: ['required'],
      },
      {
        id: 'email',
        label: 'Email',
        controlType: 'text',
        type: 'email',
        validators: ['required', 'email'],
      },
    ];

    let locationControls = [];

    if (this.location) {
      locationControls = [
        {
          id: 'city',
          label: 'Town/City',
          controlType: 'text',
          type: 'text',
          validators: ['required'],
        },
        {
          id: 'postcode',
          label: 'Postcode',
          controlType: 'text',
          type: 'text',
          validators: ['required'],
        },
      ];
    }

    let phoneControls = [];

    if (this.phone) {
      phoneControls = [
        {
          id: 'phone',
          label: 'Mobile Number',
          controlType: 'text',
          type: 'tel',
        },
      ];
    }

    this.controls = [
      ...baseControls,
      ...locationControls,
      ...phoneControls,
      {
        id: 'message',
        label: 'Message',
        controlType: 'textarea',
        type: 'text',
      },
      {
        id: 'recaptcha',
        label: 'recaptcha',
        controlType: 'recaptcha',
        validators: ['required'],
      },
    ];
    this._formService.addControls(this.controls, this.contactForm);
  }

  send(values: any) {
    this.saving = true;
    values.type = this.type;
    const options: ApiOptions = new ApiOptions({
      endpoint: 'message',
    });
    this._api
      .create(options, values)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(
        () => {
          this._toastr.success(
            'Thanks for your interest in Clancy Briggs.',
            'Request sent'
          );
          this.saving = false;
          this.contactForm.reset();
        },
        (err) => {
          this.saving = false;
        }
      );
  }

  ngOnDestroy() {}
}
