export const mainMenu: any = [
  {
    id: 'about',
    title: 'About',
    url: '/about',
  },
  {
    id: 'academy',
    title: 'Academy',
    url: '/academy',
  },
  {
    id: 'coaching',
    title: 'Coaching',
    url: '/coaching',
  },
  {
    id: 'membership',
    title: 'Membership',
    url: '/membership',
  },
  {
    id: 'shop',
    title: 'Shop',
    url: '/shop',
  },
  {
    id: 'basket',
    title: 'Basket',
    url: '/checkout',
    badge: {
      title: 0,
    },
  },
  {
    id: 'blog',
    title: 'Blog',
    url: '/blog',
  },
  {
    id: 'donate',
    title: 'Donate',
    url: '/donate',
  },
  {
    id: 'account',
    title: 'Account',
    url: '/account',
  },
  {
    id: 'contact',
    title: 'Contact',
    url: '/contact',
  },
];
