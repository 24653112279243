import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-button, button[appButton]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() loading: boolean;
  constructor() {}

  ngOnInit() {}
}
