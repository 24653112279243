import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PlatformService } from '@app/core/services/platform.service';

@Directive({
  selector: '[appFirstInvalidButton]'
})
export class FirstInvalidButtonDirective {
  @Input('appFirstInvalidButton') formGroup: NgForm;
  @Input() element: ElementRef;
  constructor(private _el: ElementRef, private _ps: PlatformService) {}

  static scrollToElement(element) {
    if (element) {
      const distance =
        window.pageYOffset - Math.abs(element.getBoundingClientRect().y);

      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: element.getBoundingClientRect().top + window.scrollY - 250,
      });

      setTimeout(() => {
        element.focus();
        element.blur(); // Trigger error messages
        element.focus();
      }, distance);
    }
  }

  static markFormGroupTouched(formGroup) {
    (Object as any).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        FirstInvalidButtonDirective.markFormGroupTouched(control);
      }
    });
  }

  @HostListener('click', ['$event'])
  onSubmit(event) {
    event.preventDefault();
    if (!this.formGroup.valid && this._ps.isBrowser) {
      FirstInvalidButtonDirective.markFormGroupTouched(this.formGroup);
      const parentForm = this._el.nativeElement.closest('form');
      const formControlInvalid = parentForm.querySelector(
        '.ng-invalid'
      );

      if (formControlInvalid) {
        return FirstInvalidButtonDirective.scrollToElement(formControlInvalid);
      } else {
        // The first element is the global form and here we are looking for the first nested form
        const formGroupInvalid = parentForm.querySelectorAll(
          'form .ng-invalid'
        );
        if (formGroupInvalid && formGroupInvalid.length) {
          return FirstInvalidButtonDirective.scrollToElement(formGroupInvalid[0]);
        }
      }

      return FirstInvalidButtonDirective.scrollToElement(parentForm);
    }
  }

}
