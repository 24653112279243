<app-modal #modal slug="partners">
  <div class="partners">
    <app-title-bar
      [center]="true"
      [barStyle]="{ 'padding-bottom': '25px', 'padding-top': '20px' }"
    >
      <h2 class="text-center">Discounts Include</h2>
    </app-title-bar>
    <div class="discounts">
      <div class="row">
        <div class="partner">
          <div class="content">
            <div class="image">
              <div class="img-partner">
                <img
                  alt="OTE Sports"
                  [appLazysizes]="'img/membership/ote-nutrition-logo.jpg'"
                />
              </div>
            </div>
            <div class="info">
              <p>
                OTE Sports is our official Nutrition Partner giving 20% discount
                to all members.
              </p>
            </div>
          </div>
        </div>
        <div class="partner">
          <div class="content">
            <div class="image">
              <div class="img-partner">
                <img
                  alt="Doncaster Culture and Leisure Trust"
                  [appLazysizes]="'img/membership/dclt-logo.jpg'"
                />
              </div>
            </div>
            <div class="info">
              <p>
                Doncaster Culture and Leisure Trust giving 20% discount to each
                Clancy Briggs Cycling Academy Member off Choose Fitness
                Wellbeing Membership.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="partner-footer">OUR AFFILIATE PARTNERS ARE SUBJECT TO CHANGE</div>
</app-modal>
