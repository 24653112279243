import { Component } from '@angular/core';
import { LayoutService } from '@app/core/layout/layout.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  constructor(public layout: LayoutService) {}
}
