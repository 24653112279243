<app-header-content>
  <h1 class="text-center white">Get in<span class="large">touch</span></h1>
</app-header-content>
<div class="page">
  <div class="container">
    <app-title-bar [center]="true">
      <h2 class="text-center">Contact Clancy Briggs</h2>
    </app-title-bar>
    <div class="row">
      <div class="address">
        <h4>Clancy Briggs Cycling Academy</h4>
        <p>C/o Whinfrey Briggs<br>
          Unit 1 Ashton Farm<br>
          4 High Street<br>
          Braithwell<br>
          S66 7AL</p>
        <p>team@clancybriggs.co.uk</p>
      </div>
      <div class="form">
        <app-contact-form [phone]="true" type="contact"></app-contact-form>
      </div>
    </div>
  </div>
</div>
